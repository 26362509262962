/** @module Results */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * @param {number} id 
 * @return {Promise<module:types.InterviewResult[]>} Success
 */
export function Get(id) {
  const parameters = {
    path: {
      id
    }
  }
  return gateway.request(GetOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {string} [options.searchTerm] 
 * @param {number} [options.pageNumber] 
 * @param {number} [options.pageSize] 
 * @return {Promise<module:types.PagedResult[InterviewResult]>} Success
 */
export function GetPaged(options) {
  if (!options) options = {}
  const parameters = {
    query: {
      searchTerm: options.searchTerm,
      pageNumber: options.pageNumber,
      pageSize: options.pageSize
    }
  }
  return gateway.request(GetPagedOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {number[]} [options.ids] 
 * @return {Promise<module:types.InterviewResult[]>} Success
 */
export function Results(options) {
  if (!options) options = {}
  const parameters = {
    body: {
      ids: options.ids
    }
  }
  return gateway.request(ResultsOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {string} [options.searchkey] 
 * @param {number} [options.profileId] 
 * @return {Promise<module:types.InterviewInfo[]>} Success
 */
export function Find(options) {
  if (!options) options = {}
  const parameters = {
    body: {
      searchkey: options.searchkey,
      profileId: options.profileId
    }
  }
  return gateway.request(FindOperation, parameters)
}

/**
 * @param {number} id 
 * @return {Promise<module:types.ApiResponseViewModel[OperationResult[Boolean]]>} Success
 */
export function Delete(id) {
  const parameters = {
    path: {
      id
    }
  }
  return gateway.request(DeleteOperation, parameters)
}

const GetOperation = {
  path: '/api/Results/Get/{id}',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const GetPagedOperation = {
  path: '/api/Results/GetPaged',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const ResultsOperation = {
  path: '/api/Results',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const FindOperation = {
  path: '/find',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json','application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const DeleteOperation = {
  path: '/api/Results/Delete/{id}',
  method: 'delete',
  security: [
    {
      id: 'oauth2'
    }
  ]
}
