import * as validationRules from '../../utils/validationHelpers'

export const registerValidationRules = {
    'email' : [{
        rule:validationRules.isEmail,
        message:'Not valid email address'
    }, {
        rule:validationRules.notEmpty,
        message:'Email is required'
    }],
    'userName': {
        rule:validationRules.notEmpty,
        message:'User Name is required'
    },
    'password': {
        rule:validationRules.notEmpty,
        message:'Password is required'
    }
}