import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';


const styles = {
	root: {
		display: 'flex',
		flexDirection: "row"
	},
		sliderValue: {
			// border: "1px solid #ccc",
			// borderRadius: "5px",
			padding: "10px 10px 0px 10px"
		}
	};
	
class SimpleSlider extends React.PureComponent {

	constructor(props){
		super(props);
	}
	
	onChange = (e,value) => {
		this.props.onChange(value)
	};
	

	render() {
		const { classes } = this.props;
	
		return (
			<div className={classes.root}>
				<Slider
					value={this.props.value}
					aria-labelledby="label"
					step={1}
					min={-1}
					onChange={this.onChange}
				/>
				<Typography 
					className={classes.sliderValue} 
					variant="subtitle2">
					{this.props.value >=0 ? this.props.value + "%" : "n/a"}
				</Typography>
			</div>
		);
	}
	}
	
	SimpleSlider.propTypes = {
		classes: PropTypes.object.isRequired,
		onChange: PropTypes.func,
		value: PropTypes.number
	};
	
	export default withStyles(styles)(SimpleSlider);