import React from 'react'

function Home() {
    return (
        <div className="jumbotron">
            React App
            <p className="lead">This is start page of future interview application</p>
        </div>
    );
}

export default Home;