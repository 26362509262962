import React, { Component, Fragment } from 'react'
import * as ScoresActions from './actions'
import { Grid, Icon, Fab, Paper, Table, Button, TableHead, TableCell, TableBody, TableRow, Typography, List, ListItem, ListItemText, Highlighter, ListItemSecondaryAction } from '@mui/material'
import Loader from '../Loader'
import withStyles from '@mui/styles/withStyles';
import withTheme from '@mui/styles/withTheme';
import { withRouter } from 'react-router';
import PropTypes, { func } from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

const styles = theme => ({
	actionButton: {
		right: theme.spacing(2),
	},
	loader: {
        // minHeight: 100
    },
});


class ScoreGroupsList extends Component {
	constructor(props) {
		super(props);
		this.props.actions.getScoreGroups();
	}

	handleCreateBtnClick = () => {
		this.props.history.push('/scores/create')
	}
	handleEditGroupClick = (id) => {
		var me = this;
		return function (e) {
			me.props.history.push('/scores/' + id);
		}
	}

	handleDeleteClick = (id) => {
		var me = this;
		return function (e) {
			me.props.actions.deleteGroup(id);
		}
	}

	render() {
		const classes = this.props.classes;
		const listRows = this.props.groups.map(group => {
			return (<ListItem
					key={group.id}
                    button
                    onClick={this.handleEditGroupClick(group.id)}
                >
                    <ListItemText primary={group.name} />
					<ListItemSecondaryAction>
						<Fab
							size="small"
							onClick={this.handleDeleteClick(group.id)}
						>
							<Icon >
								delete_outline
							</Icon>
						</Fab>
					</ListItemSecondaryAction>
                    {/* <InterviewMenu id={item.interviewId} onAddToCompare={this.addSummaryToCompare} onDelete={this.deleteHandler} /> */}
                </ListItem>)
		});
		const rows = this.props.groups.map(group => {
			return (<TableRow key={group.id}>
				<TableCell onClick={this.handleEditGroupClick(group.id)}>
					<Typography variant="body1">
						{group.name}
					</Typography>
				</TableCell>
				<TableCell>
					<Fab
						className={classes.actionButton}
						size="small"
						onClick={this.handleEditGroupClick(group.id)}
					>
						<Icon>
							edit
						</Icon>
					</Fab>
					<Fab
						size="small"
						onClick={this.handleDeleteClick(group.id)}
					>
						<Icon >
							delete_outline
						</Icon>
					</Fab>
				</TableCell>
			</TableRow>)
		});

		return (
			<Fragment>
				<Grid>
					<Button variant='contained' color='primary' onClick={this.handleCreateBtnClick}>Create score</Button>
				</Grid>
				{/* <Grid>
					<Table>
						<TableBody>
							{rows}
						</TableBody>
					</Table>
				</Grid> */}
				<List className={classes.loader}>
					<Loader show={this.props.isFiltering} className={classes.loader} />
					{listRows}
				</List>
			</Fragment>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		groups: state.scoresGroups.groups
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(ScoresActions, dispatch)
	};
};

ScoreGroupsList.propTypes = {
	classes: PropTypes.object,
	groups: PropTypes.array,
	actions: PropTypes.object,
	history: PropTypes.object,
	isFiltering: PropTypes.bool
}


export default withRouter(withStyles(styles, {withTheme:true})(connect(mapStateToProps, mapDispatchToProps)(ScoreGroupsList)))