import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
    SnackbarContent, TextField, Grid, TablePagination, Icon, List, ListItem, ListItemText, Chip, ListItemAvatar,
    Avatar, FormControl, MenuItem, InputLabel, Select, Input
} from '@mui/material'
import * as ResultListActions from './actions'
import * as CompareActions from '../App/Compare/actions'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { stageMessage } from "../../utils/helpers"
import InterviewMenu from './InterviewMenu'
import { initialState } from './reducer'
import Loader from '../Loader'
import { amber } from '@mui/material/colors'
import Highlighter from "react-highlight-words";
import {InterviewStages} from '../../common/constants/InterviewStages'

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    warning: {
        backgroundColor: amber[700],
    },
    loader: {
        minHeight: 100
    },
    stageChip: {
        float: "right"
    }
});

class InterviewsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 10,
        };
        this.props.actions.getInterviewsList(false, this.state.page, this.state.rowsPerPage);
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
        this.props.actions.getInterviewsList(true, page, this.state.rowsPerPage);
    }

    handleChangeFilter = event => {
        let filteredInterviewList = this.props.interviewsList.filter(function (item) {
            return item.stage === event.target.value;
        });
        this.props.actions.setInreviewsData({
            interviewStageFilter: event.target.value, rowCount: event.target.value === ""
                ? this.props.interviewsList.length
                : filteredInterviewList.length
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
        this.props.actions.getInterviewsList(true, this.state.page, event.target.value);
    }

    itemTimeLineClickHandler = id => {
        this.props.history.push('/interviews/timeline/' + id)
    }

    itemInterviewClickHandler = (id,stage) => {
        if(stage == InterviewStages.Completed) {
            this.props.history.push('/interviews/review/' + id)
        } else {
            this.props.history.push(`/interview/${id}`);
        }
        
    }

    handleSearchChange = e => {
        this.props.actions.setInreviewsData({ searchValue: e.target.value });
        setTimeout(() => {
            this.props.actions.getInterviewsList(true, this.state.page, this.state.rowsPerPage);
        }, 200);
    }

    componentWillUnmount() {
        this.props.actions.setInreviewsData(initialState);
    }

    deleteHandler = (id) => {
        this.props.actions.deleteResult(id);
    }

    addSummaryToCompare = (id) => {
        var summary = this.props.interviewsList.find((elem) => { return elem.interviewId == id });
        if (summary) {
            this.props.compare.addSummaryToCompareList(id, summary.title, summary.profileId, summary.profileName);
        }
    }

    render() {
        const { classes } = this.props;

        if (this.props.interviewsList.length == 0 && this.props.initialLoaded) {
            return (<SnackbarContent className={classes.warning} message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon>warning</Icon>
                    No interviews found...
                </span>
            } />)
        }

        let stageFilter = this.props.interviewStageFilter;
        let filteredInterviewList = stageFilter !== "" ? this.props.interviewsList.filter(function (item) {
            return item.stage === stageFilter;
        })
            : this.props.interviewsList;

        const rows = filteredInterviewList.map(item => {
            return (
                <ListItem
                    key={item.interviewId}
                    disabled={item.stage < InterviewStages.WaitingForInterview}
                    button
                    onClick={() => { this.itemInterviewClickHandler(item.interviewId, item.stage) }}
                >
                    <ListItemAvatar>
                        <Avatar>
                            <Icon className={classes.icon} fontSize="small" size="small">assignment_ind</Icon>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={
                        <React.Fragment>
                            <Highlighter searchWords={[this.props.searchValue]} autoEscape={true} textToHighlight={item.title} />
                            <Chip label={stageMessage(item.stage)} className={classes.stageChip} />
                        </React.Fragment>
                    } secondary={item.profileName} />
                    <InterviewMenu id={item.interviewId} onAddToCompare={this.addSummaryToCompare} stage={item.stage} onDelete={this.deleteHandler} />
                </ListItem>
            )
        });
        return (
            <Fragment>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{ position: "relative", padding: "0 10px 10px" }}>
                            <TextField id="search"
                                name="Search"
                                label="Search"
                                value={this.props.searchValue}
                                onChange={this.handleSearchChange}
                                autoComplete="off"
                                margin="normal" />
                            <FormControl style={{
                                minWidth: "10%",
                                position: "absolute",
                                right: "10px",
                                transform: "translateY(50%)"
                            }}>
                                <InputLabel shrink>Filter</InputLabel>
                                <Select
                                    value={this.props.interviewStageFilter}
                                    onChange={this.handleChangeFilter}
                                    input={<Input name="interviewStageFilter" />}
                                    displayEmpty
                                    name="interviewStageFilter"
                                    className={classes.selectEmpty}
                                >
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>
                                    <MenuItem value={InterviewStages.PreInterview}>Pre Interview</MenuItem>
                                    <MenuItem value={InterviewStages.PreInterviewSubmitted}>Pre Interview Submitted</MenuItem>
                                    <MenuItem value={InterviewStages.WaitingForInterview}>Waiting for Interview</MenuItem>
                                    <MenuItem value={InterviewStages.Started}>Started</MenuItem>
                                    <MenuItem value={InterviewStages.Completed}>Completed</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <List className={classes.loader}>
                            <Loader show={this.props.isFiltering} className={classes.loader} />
                            {rows}
                        </List>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={this.props.rowCount || 0}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

InterviewsList.propTypes = {
    actions: PropTypes.object,
    compare: PropTypes.object,
    interviewsList: PropTypes.array.isRequired,
    rowCount: PropTypes.number,
    searchValue: PropTypes.string,
    interviewStageFilter: PropTypes.string,
    isFiltering: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    initialLoaded: PropTypes.bool,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    })
};

const mapStateToProps = (state) => {
    return {
        ...state.interviewsListComponent
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(ResultListActions, dispatch),
        compare: bindActionCreators(CompareActions, dispatch)
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InterviewsList)));