import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Grid, Button, FormControlLabel, Switch, Chip, Box, Fab } from '@mui/material'
// import * as InterviewActions from './actions'
import * as InterviewActions from './interviewReviewSlice'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import InterviewHeader from './InterviewHeader';
import TreeGrid from '../../common/components/TreeGrid/TreeGrid'
import ScoreValue from './Renderers/ScoreValue'
import ScoreChip from '../../common/components/Scores/ScoreChip'

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        // ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    paddings: {
        maringTop: '5px',
        marginBottom: '5px'
    },
    reviewControls: {
        display: "flex",
        // flexDirection: 'row-reverse',
        paddingBottom: theme.spacing(2),
        justifyContent: "flex-end"
    },
    actionButton: {
        marginLeft: "10px"
    },
    reviewHideNA: {

    },
    actionIcon: {
        marginLeft: "10px"
    },
    noPrint: {
        '@media print': {
            display: "none"
        }
    }
});

class InterviewReview extends React.Component {
    constructor(props) {
        super(props);

        this.props.actions.getReview(this.props.resultId);
    }

    onUpdateInterview = () => {
        this.props.history.push(`/interview/${this.props.resultId}`);
    }

    toggleShowHideNA = () => {
       this.props.actions.toggleShowHideNA();
    }

    getGridConfig() {
        const me = this;
        return {
            stateKey: 'interviewReviewSlice',
            dataKey: "filtered",
            paddingBase: 16,
            rootId: null,
            dragAndDrop: false,
            columnsConfigs: [
                {
                    prop: 'name',
                    text: 'Title',
                    visible: true,
                    width: 4,
                },
                {
                    text: 'Stats',
                    visible: true,
                    width: 1,
                    renderer: (options) =>{
                        const area = me;
                        const harvestCount = (id) => {
                            let overall = 0;
                            let count = 0;
                            
                            const response = me.props.responses.find(x=>x.id == id);

                            if(response.hasChildResponses){
                                const responses = me.props.responses.filter(x=>x.parentId == id);
                                responses.forEach(x=>{
                                    let data = harvestCount(x.id);
                                    if(data.overall > 0 ) {
                                        overall+=data.overall;
                                        count+=data.count;
                                    }
                                });
                                return { overall: overall, count: count, hasChildResponses: response.hasChildResponses }
                            } else {
                                return { overall: 1, count: response.notAsked ? 0 : 1, hasChildResponses: response.hasChildResponses };
                            }
                        }
                        const info = harvestCount(options.id)
                    return info.hasChildResponses ? (<span>{info.count}/{info.overall}</span>) : (<span></span>);
                    }
                },
                {
                    prop: 'evaluateQuestion',
                    text: 'Evaluation type',
                    visible: true,
                    width: 2,
                    renderer: (options,value) =>{
                        return <Box>{options.value}</Box>
                    }
                },
                {
                    prop: 'scoreFormatted',
                    text: 'Score',
                    visible: true,
                    width: 2,
                    renderer: (options) =>{
                        const response = me.props.responses.find(x=>x.id == options.id);
                        const title = response.scoreRange ? response.scoreRange : "Not asked";
                        return response.notAsked && response.hasChildResponses ? <Box></Box> : <ScoreChip selectedColor={response.hexColor} title={title} />;
                    }
                },
                {
                    prop: 'expertComment',
                    text: 'Expert Comment',
                    visible: true,
                    width: 3,
                    additionalProps: { multiline: true },
                    renderer: (options,value) =>{
                        return <Box>{options.value}</Box>
                    }
                }
            ]
        };
    }

    componentWillUnmount() {
        this.props.actions.reset();
    }

    render() {
        const treeConfig = this.getGridConfig();
        const { classes } = this.props;
        return (
            <Grid container className="interview-review">
                <Grid item xs={12} style={{ marginBottom: "20px" }}>
                    <InterviewHeader {...this.props} onEditSummary={ (html) => { this.props.actions.setSummary(this.props.resultId, html)}} isLoading={this.props.summarySaving} />
                </Grid>
                <Grid item xs={12} className={[classes.reviewControls, classes.noPrint].join(" ")}>
                    {/* <Fab className={classes.actionIcon} size="small" color="secondary" aria-label="Export file" href={"https://api.interviewapp.xyz/api/v1/export/text/" + this.props.resultId}>
                        <DescriptionIcon />
                    </Fab> */}
                    <FormControlLabel
                        control={
                        <Switch
                            checked={this.props.hideNA}
                            onChange={()=> { this.toggleShowHideNA() }}
                            color="primary"
                        />
                        }
                        label="Hide not asked topic"
                    />
                    <Button className={classes.actionButton} variant="contained" color="primary" size="small" onClick={this.onUpdateInterview}>Edit scores</Button>
                    <Button className={classes.actionButton} variant="contained" color="primary" size="small" href={"https://api.interviewapp.xyz/api/v1/export/text/" + this.props.resultId} >Export .TXT</Button>
                </Grid>
                <Grid item xs={12} className="interview-grid">
                    <TreeGrid {...treeConfig} />
                </Grid>
            </Grid>
        )
    }
}



const mapStateToProps = (state, ownProps) => {
    return {
        ...state.interviewReviewSlice,
        resultId: ownProps.match.params.id,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(InterviewActions, dispatch)
    };
};

InterviewReview.propTypes = {
    history: PropTypes.object,
    actions: PropTypes.object,
    profileName: PropTypes.string,
    profileVersion: PropTypes.number,
    levelName: PropTypes.string,
    title: PropTypes.string,
    completedDate: PropTypes.string,
    summary: PropTypes.string,
    responses: PropTypes.array,
    resultId: PropTypes.string,
    candidate: PropTypes.object,
    profileId: PropTypes.number,
    classes: PropTypes.object,
    hideNA: PropTypes.bool
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InterviewReview)));