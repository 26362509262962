const colorSets = [
    ["#4CAF50"],
    ["#f44336", "#4CAF50"],
    ["#f44336", "#FFEB3B", "#4CAF50"],
    ["#f44336", "#FFC107", "#FFEB3B", "#4CAF50"],
    ["#f44336","#FF9800", "#FFC107", "#FFEB3B",  "#4CAF50"],
    ["#f44336","#FF9800", "#FFC107", "#FFEB3B", "CDDC39", "#4CAF50"],
]

export const getColor = (index, count) =>{

    const colorsSet = colorSets.find(x=>x.length == count);
    if (colorsSet){
        return colorsSet[index];
    } 

    const colorValue = 512/ (count-1) * index;
    if (colorValue>= 0 && colorValue < 256){
        return rgbToHex(255, Math.round(colorValue), 0);
    } else if (colorValue >255 && colorValue <= 512){
        return rgbToHex(Math.round(512-colorValue), 255, 0);
    } else {
        return rgbToHex(255,255,255);
    }
}

function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }
  
function rgbToHex(r, g, b) {
return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}