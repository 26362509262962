/** @module Account */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * @param {object} options Optional options
 * @param {string} [options.accessToken] 
 * @return {Promise<object>} Success
 */
export function FacebookLogin(options) {
  if (!options) options = {}
  const parameters = {
    query: {
      accessToken: options.accessToken
    }
  }
  return gateway.request(FacebookLoginOperation, parameters)
}

/**
 * @param {string} email 
 * @param {string} password 
 * @return {Promise<object>} Success
 */
export function Login(email, password) {
  const parameters = {
    query: {
      email,
      password
    }
  }
  return gateway.request(LoginOperation, parameters)
}

/**
 * @param {string} userName 
 * @param {string} email 
 * @param {string} password 
 * @return {Promise<object>} Success
 */
export function Register(userName, email, password) {
  const parameters = {
    query: {
      userName,
      email,
      password
    }
  }
  return gateway.request(RegisterOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {string} [options.token] 
 * @return {Promise<object>} Success
 */
export function Info(options) {
  if (!options) options = {}
  const parameters = {
    query: {
      token: options.token
    }
  }
  return gateway.request(InfoOperation, parameters)
}

const FacebookLoginOperation = {
  path: '/api/account/facebooklogin',
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const LoginOperation = {
  path: '/api/account/login',
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const RegisterOperation = {
  path: '/api/account/register',
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const InfoOperation = {
  path: '/api/account/info',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}
