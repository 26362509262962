import React from 'react'
import PropTypes from 'prop-types'
import { Button, Menu, MenuItem, Icon } from '@mui/material'
import { withRouter } from 'react-router'
import history from '../../utils/history'

class CandidatesMenu extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
        this.onMenuClick = this.onMenuClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    onMenuClick(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ anchorEl: e.currentTarget });
    }

    onMenuItemClick(e, target) {
        e.preventDefault();
        e.stopPropagation();
        switch (target) {
            case "deleteCandidate": {
                this.props.onDelete(this.props.id);
                break;
            }
            case "editCandidate": {
                history.push(`/candidates/edit/${this.props.id}`);
                break;
            }
        }
    }

    handleClose(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ anchorEl: null });
    }

    render() {
        const { anchorEl } = this.state;
        return (

            <div>
                <Button
                    aria-owns={anchorEl ? 'simple-menu' : null}
                    aria-haspopup="true"
                    onClick={this.onMenuClick}
                >
                    <Icon>more_vert</Icon>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                >
                    <MenuItem onClick={(e) => this.onMenuItemClick(e, "editCandidate")}>Edit Candidate</MenuItem>
                     <MenuItem onClick={(e) => this.onMenuItemClick(e, "deleteCandidate")}>Delete Candidate</MenuItem>
                </Menu>
            </div>
        );
    }
}

CandidatesMenu.propTypes = {
    id: PropTypes.number,
    onDelete: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    })
}

export default withRouter(CandidatesMenu)