import React, { Fragment } from 'react';
import { Grid, Table, Button, TableCell, TableBody, TableRow, Typography, TextField,FormControl, TableHead, Fab, Icon, CircularProgress  } from '@mui/material'
import PropTypes from 'prop-types';
import * as groupsActions from './actions'
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { green } from '@mui/material/colors';

const styles = theme => ({
	screenTitle: {
        paddingRight: 30,
        display: "inline-block"
	},
	mainButton:{
		marginRigth: 10
	},
	title: {
		paddingBottom: theme.spacing(2)
	},
	declineButton: {
		marginLeft: theme.spacing(1)
	},
	wrapper: {
		position: 'relative',
	},
	buttonProgress: {
		color: theme.palette.primary.light
	}
});

class UsersInvitesComponent extends React.Component {

	constructor(props){
		super(props);

		this.props.actions.getInvites();
	}

	decline = (key) => {
		this.props.actions.declineInvite(key);
	}

	accept = (key) =>{
		this.props.actions.acceptInvite(key, this.props.onInvitationAccepted);
	}

	render(){
		const classes = this.props.classes;
		const rows = this.props.invites.map(x=>{
			const actions =  (x.loading ? 
				<CircularProgress size={26} className={classes.buttonProgress} />
			:
				<Fragment>
					<Button onClick={()=>this.accept(x.invitationKey)} disabled={x.loading} variant="contained" color="primary">
						accept
					</Button>
					<Button 
						disabled={x.loading} 
						className={classes.declineButton} 
						variant="contained" 
						color="secondary"
						onClick={()=>this.decline(x.invitationKey)}
						>
						decline
					</Button>
				</Fragment>);
			return (
			<TableRow key={x.invitationKey}>
				<TableCell>
					{x.groupName}
				</TableCell>
				<TableCell>
					{x.roleName}
				</TableCell>
				<TableCell>
					{actions}
				</TableCell>
			</TableRow>);
		});
		return <Table>
					<TableHead>
						<TableRow>
							<TableCell>
								Group
							</TableCell>
							<TableCell>
								Role
							</TableCell>
							<TableCell>
								Actions
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows}
					</TableBody>
				</Table>;
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		invites: state.usersInviteComponent.invites,
		invitesUpdated: state.usersInviteComponent.invitesUpdated
    };
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(groupsActions, dispatch)
	};
};

UsersInvitesComponent.propTypes = {
	classes: PropTypes.object,
	invites: PropTypes.array,
	actions: PropTypes.object,
	history: PropTypes.object,
	invitesUpdated: PropTypes.bool,
	onInvitationAccepted: PropTypes.func
}

export default (withStyles(styles,{ withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersInvitesComponent))))


