import React, { PureComponent } from 'react'
import { connect } from 'react-redux';
import { Typography } from '@mui/material/';
import PropTypes from 'prop-types';

class ExpertComment extends PureComponent {
    constructor (props){
        super(props);
    }

    render(){
        return (<Typography variant="subtitle1">{this.props.comment}</Typography>);
    }
}

const mapStateToProps = (state, ownProps) => {
	const area = state.interviewComponent.areas.find(area=>{
		return area.id == ownProps.id
    });
    const expertResponse = area.expertResponse || {};
	return {
		areaId: ownProps.id,
		comment: expertResponse.comment || ""
	}
}
const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpertComment);

ExpertComment.propTypes = {
	classes: PropTypes.object,
	areaId: PropTypes.string,
	comment: PropTypes.string
}