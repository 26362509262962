import {candidatesListAction} from './actions'

export const initialState = {
    candidatesList:[],
    isFiltering: false,
    filterValue: '',
    initialLoaded: false
}

export const candidatesListComponent = (state = initialState, action) => {
    switch (action.type) {
        case candidatesListAction.CANDIDATES_SET_DATA:
            return Object.assign({},state, action.data);
        case candidatesListAction.CANDIDATE_DELETED:
            return Object.assign({},state, { candidatesList: state.candidatesList.filter(({id}) => id != action.id), rowCount: state.rowCount - 1});
        default:
            return state;
    }
};