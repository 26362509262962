import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router";
const AuthRoute = props => {
    const { account } = props;
    if (!account.isLoggedIn){
      var redirectTo = "";
      if(window.location.pathname != "/login"){
          redirectTo = "?redirectTo=" + window.location.pathname;
      }
      return <Redirect to={"/login" + redirectTo } />;
    }  
    return <Route {...props} />;
  };
  const mapStateToProps = ({ account }) => ({
    account
  });
  
  export default connect(mapStateToProps)(AuthRoute);