import { loginActions } from '../actions/accountActions';

const defaultState = {
    id:"",
    name: "",
    email: "",
    isLoggedIn: false,
    profileUrl: "",
    token: ""
};
export const account = (state = defaultState, action) => {
    switch (action.type) {
        case loginActions.LOGIN_SUCCESS:
            return Object.assign({}, state, { ...action.data, isLoggedIn: true });
        case loginActions.LOGOUT:
            return Object.assign({}, state, { ...defaultState, isLoggedIn: false });
        default:
            return state;
    }
}