import React from 'react'
import ProfilesList from '../ProfilesList'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Button,Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
	actionButton: {
		left: theme.spacing(2),
	}
});

class ManageProfiles extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    handleCreateBtnClick = () => {
        this.props.history.push('/profiles/create');
    }
    handleManageScoresClick = () => {
        this.props.history.push('/scores');
    }
    render() {
        return (
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <ProfilesList />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={this.handleCreateBtnClick}>Create New Profile</Button>
                        {/* <Button className={this.props.classes.actionButton} variant="contained" color="primary" onClick={this.handleManageScoresClick}>Scores Configurations</Button> */}
                    </Grid>
                </Grid>
            </div>
        )
    }

}

ManageProfiles.propTypes = {
    history: PropTypes.object,
    classes: PropTypes.object
}

export default withStyles(styles)(withRouter(ManageProfiles));