import * as profileApi from '../../../api/interview/Profile'
import { setRequestError } from '../../../screens/App/actions'

export const profileSelectActions = {
    PROFILE_SELECT_SET_DATA: 'PROFILE_SELECT_SET_DATA'
}

export const profileSelectSetData = (data) => ({type:profileSelectActions.PROFILE_SELECT_SET_DATA, data});

export const searchProfiles = () => (dispatch,getState) => {
    const {profileSelectComponent} = getState();
    if(profileSelectComponent.isLoading) {
        return;
    }
    dispatch(profileSelectSetData({isLoading:true}));
    const searchTerm = profileSelectComponent.filterValue;
    const selected = profileSelectComponent.selected;
    profileApi.SearchReleasedProfiles({
        searchTerm
    }).then(response => {
        if (!response.error) {
            const responseBody = response.data;
            const { data } = responseBody;
            let isSelectedPresent = false;
            const options = data.map(el =>{
                if(selected !== 0 && el.id === selected.value) {
                    isSelectedPresent=true;
                } 
                return {
                    value:el.id,
                    label:el.name,
                    versionId: el.versionId
                }
            });
            dispatch(profileSelectSetData({
                options, 
                isLoading:false,
                selected: isSelectedPresent ? selected : 0
            }));

        } else {
            dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => searchProfiles(searchTerm)));
            dispatch(profileSelectSetData({isLoading:false}));
        }
    }).catch(error => {
        dispatch(profileSelectSetData({isLoading:false}));
        dispatch(setRequestError("Error occured " + error, () => searchProfiles(searchTerm)));
    })
}