import {initialState as storeInitialState} from './reducer'
import { setRequestError, setAppLoadingStarted, setAppLoadingFinished } from '../App/actions'
import * as rolesApi from '../../api/interview/Roles'

export const rolesActions = {
   ROLES_SET_DATA : "ROLES_SET_DATA",
   ROLES_UPDATE: "ROLES_UPDATE",
   ROLES_DELETE:"ROLES_DELETE",
   SET_ALL_PERMISSIONS_DATA: "SET_ALL_PERMISSIONS_DATA",
   ROLE_ADD_PERMISSION: "ROLE_ADD_PERMISSION",
   ROLE_REMOVE_PERMISSION: "ROLE_REMOVE_PERMISSION",
   SET_GROUP_NAME_IN_ROLES: "SET_GROUP_NAME_IN_ROLES"
};

export const setRolesData = (data) => (dispatch) => dispatch({ type: rolesActions.ROLES_SET_DATA , data });
export const updateRole = (data) => (dispatch) => dispatch({type: rolesActions.ROLES_UPDATE, data})

export const deleteRole = (data) => (dispatch) => {
    rolesApi.DeleteRole(data).then(response => {
        if(!response.error)
            dispatch({type: rolesActions.ROLES_DELETE, data})
        else{
            dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => deleteRole(data)));          
        }});
}

export const saveRoleChanges = (data) => (dispatch) =>{

    return rolesApi.Update(data).then(response => {
        if(!response.error){
            dispatch(getRoles(data.groupId));
        }
        else{
            dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => saveRoleChanges(data)));
            dispatch(updateRole({...data, errors: {...response.data.body.error.errors}}))    
        }
    })
}

export const initRoles = (groupId) => (dispatch) =>{
    dispatch(getPermissions()).then(dispatch(getRoles(groupId)));
}

export const getRoles = (groupId) => (dispatch) => {
    dispatch(setAppLoadingStarted())
    rolesApi.Get(groupId).then(response => {   
        if (!response.error) {
            const responseBody = response.data;
            dispatch(setRolesData(responseBody));
            dispatch(setAppLoadingFinished());
        } else {
            dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => getRoles(groupId)));
        }
    })
    .catch(error => {
        dispatch(setRequestError("Error occured:" + error, () => getRoles(groupId)));
    });
    return;
}

export const getPermissions = () => (dispatch) => {
    return rolesApi.GetPermissions()
    .then(response => {
        if (!response.error) {
            const responseBody = response.data;
            dispatch({type: rolesActions.SET_ALL_PERMISSIONS_DATA, data: responseBody})
        } else{
            dispatch(setRequestError("Error occured:" + response.data.body.error.generalErrorMessage, () => getPermissions()))
        }
    }).catch(error => {dispatch(setRequestError("Error occured:" + error, () => getPermissions()));})
}

export const addPermission = (rolePermission) => (dispatch) =>{

    return rolesApi.AddPermission(rolePermission.roleId, rolePermission.permissionId).then(() => 
        dispatch({type: rolesActions.ROLE_ADD_PERMISSION, data: rolePermission}));
}


export const removePermission = (shortPermissionInfo) => (dispatch) =>{
    return rolesApi.RemovePermission(shortPermissionInfo.roleId, shortPermissionInfo.permissionId).then(() => 
        dispatch({type: rolesActions.ROLE_REMOVE_PERMISSION, data: shortPermissionInfo}));
}