import * as groupsApi from '../../../api/interview/Groups';
import {
    setRequestError,
    setAppLoadingStarted,
    setAppLoadingFinished
} from '../../App/actions'

export const invitesActions = {
    INVITES_LOADED: "GROUP_INVITES_LOADED",
    INVITES_LOADING: "GROUP_TOGGLE_INVITE_LOADING",
    INVITES_REMOVE_INVITE: "GROUP_INVITE_REMOVE",
    INVITES_UPDATE_HANDLED: "INVITES_UPDATE_HANDLED"
}

export const getInvites = () => (dispatch) =>{
    dispatch(setAppLoadingStarted());
    groupsApi.MyInvites().then(response=>{
        if(!response.error){
            dispatch({ type: invitesActions.INVITES_LOADED, data: response.data });
            dispatch(setAppLoadingFinished());
        } else {
            dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => getInvites()));
        }
    }).catch(error => {
        dispatch(setRequestError("Error occured:" + error, () => getInvites()));
    });
}

export const invitesUpdated = () => (dispatch) => dispatch({ type: invitesActions.INVITES_UPDATE_HANDLED })

export const acceptInvite = (key, inviteAcceptedCallback) => (dispatch) =>{
    dispatch(toggleInviteInProgress(key));
    groupsApi.AcceptInvite(key).then(response=>{
        if(!response.error){
            dispatch(removeInvite(key));
            if(inviteAcceptedCallback){
                inviteAcceptedCallback();
            }
        } else {
            dispatch(toggleInviteInProgress(key, false));
            dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => declineInvite()));
        }
    }).catch(error=>{
        dispatch(toggleInviteInProgress(key, false));
        dispatch(setRequestError("Error occured:" + error, () => declineInvite()));
    });
}

export const declineInvite = (key) => (dispatch) =>{
    dispatch(toggleInviteInProgress(key, true));
    groupsApi.DeclineInvite(key).then(response=>{
        if(!response.error){
            dispatch(removeInvite(key));
        } else {
            dispatch(toggleInviteInProgress(key, false));
            dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => declineInvite()));
        }
    }).catch(error=>{
        dispatch(toggleInviteInProgress(key, false));
        dispatch(setRequestError("Error occured:" + error, () => declineInvite()));
    });
}

const toggleInviteInProgress = (key, loading) => ({ type: invitesActions.INVITES_LOADING, data: { key: key, loading: loading } })
const removeInvite = (key) => ({ type: invitesActions.INVITES_REMOVE_INVITE, data: { key: key } })

