
import {Typography} from '@mui/material'
import withStyles from '@mui/styles/withStyles';
import {compose,pure} from 'recompose'
import React from 'react'
import PropTypes from 'prop-types'

const styles = theme => ({
    typography: {
        display:'inline',
        whiteSpace: 'initial',
        wordWrap: 'break-word',
        cursor: 'default',
        fontSize: "14px"
    }
})

class TreeGridTypography extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        const {classes,value,variant} = this.props; 
        return (
            <Typography variant={variant ? "body2" : variant} className={`${classes.typography} ${this.props.className ? this.props.className : ''}`} gutterBottom>
                {value}
            </Typography>
        )

    }
}

TreeGridTypography.propTypes = {
    classes: PropTypes.object,
    value: PropTypes.any,
    variant: PropTypes.any
}

export default compose(pure,withStyles(styles))(TreeGridTypography);