import {initialState as storeInitialState} from './reducer'
import { setRequestError, setAppLoadingStarted, setAppLoadingFinished } from '../App/actions'
import * as canidatesApi from '../../api/interview/Candidates'
import history from '../../utils/history'

export const candidateViewActions = {
    CANDIDATE_VIEW_SET_DATA: 'CANDIDATE_VIEW_SET_DATA',
};

export const setCandidateData = (data) => ({ type: candidateViewActions.CANDIDATE_VIEW_SET_DATA, data });

export const getCandidate = (id) => (dispatch) => {
    if (!id) {
        dispatch(setCandidateData(storeInitialState));
        return;
    }
    dispatch(setAppLoadingStarted());
    canidatesApi.Get(id)
        .then(response => {
           
            if (!response.error) {
                const responseBody = response.data;
                const { data } = responseBody;
                dispatch(setCandidateData(data));
                dispatch(setAppLoadingFinished());
            } else {
                dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => getCandidate(id)));          
            }
        })
        .catch(error => {
            dispatch(setRequestError("Error occured:" + error, () => getCandidate()));
        });
};