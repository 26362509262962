import React, { Component, Fragment, useState, useEffect } from 'react'

import { IconButton, Tooltip } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({

})

const AddIconButton = ({ tooltip, onClick, className }) =>{
    // const [open, setOpen] = useState(false);
    const classes = useStyles({});
    return <Tooltip className={className} title={tooltip}>
        <IconButton aria-label="delete" className={classes.margin} size="small" onClick={onClick}>
            <AddCircleOutlineIcon fontSize="inherit" />
        </IconButton>
    </Tooltip>
}

export default AddIconButton;