import React from 'react'
import * as PreInterviewActions from './actions'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import withStyles from '@mui/styles/withStyles';
import ScoreSetAction from './Renderers/ScoreSetAction'
import ScoreDetails from './Renderers/ScoreDetails'
import PropTypes from 'prop-types'
import {Grid,Typography,Button} from '@mui/material'
import {initialState} from './reducer'
import TreeGrid from '../../common/components/TreeGrid/TreeGrid'

const styles = () => ({
});

class PreInterview extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            expandAll:true
        }
    }

    componentDidMount() {
        this.props.actions.getInterview(this.props.invitationKey)
    }

    componentWillUnmount() {
        this.props.actions.setInterviewData(initialState);
    }

    onSubmit = () => {
        this.props.actions.submit(this.props.invitationKey);
    }

    getGridConfig = () => {
        return {
            stateKey: 'preInterviewComponent',
            dataKey: 'areas',
            rootId: '',
            paddingBase: 16,
            dragAndDrop: false,
            expanded: this.state.expandAll,
            focus: {
                stateKey: 'preInterviewComponent',
                focusColumnProperty: 'focusColumn',
                focusRowProperty: 'focusRow',
                setFocusRow: order => {
                    this.props.actions.setFocusRow(order);
                },
                setFocusColumn: column => {
                    this.props.actions.setFocusColumn(column);
                }
            },
            propChangeHandler: (id, prop, value) => {
                const data = {};
                data[prop] = value;
                this.props.actions.editInterviewArea(id, data);
            },
            columnsConfigs: [
                {
                    prop: 'title',
                    text: 'Title',
                    visible: true,
                    width: 4
                },
                {
                    prop: 'score',
                    text: 'Score',
                    visible: true,
                    width: 2,
                    renderer: (options) => {
                        return <ScoreDetails {...options} />
                    },
                },
                {
                    text: '',
                    visible: true,
                    width: 2,
                    renderer: (options) => {
                        return <ScoreSetAction {...options} />
                    },
                }
            ]
        };
    }

    render() {
        const treeConfig = this.getGridConfig();
        const { classes } = this.props;
        const expandCollapseText = this.state.expandAll ? 'Collapse All' : 'Expand All';
        return (
            <Grid container>
                <Grid item xs={12}>
                    {/* <Typography>If you dont know what to do go ask your mom, we explained her</Typography> */}
                </Grid>
                <Grid item xs={12}>
                    <TreeGrid {...treeConfig}/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={6}>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={() => this.onSubmit()}>Submit</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = (state,ownProps) => {
    var params = new URLSearchParams(ownProps.location.search);

    return {
        areas: state.preInterviewComponent.areas,
        invitationKey:params.get('key')
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(PreInterviewActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(PreInterview)));

PreInterview.propTypes = {
    actions : PropTypes.object,
    classes: PropTypes.any,
    areas: PropTypes.arrayOf(PropTypes.object),
    invitationKey: PropTypes.string
}
