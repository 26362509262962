import React, { Fragment, useState, useEffect } from 'react';
import { Typography, Paper, Fab, Grid, Box, IconButton, CircularProgress   } from '@mui/material'
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import { stageMessage } from "../../utils/helpers"
import withStyles from '@mui/styles/withStyles';
import SummaryEditor from './Renderers/summaryEditor'

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        // ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        display: "flex"
    },
    title: {
        marginTop: '5px',
        marginBottom: '5px',
        whiteSpace: "pre"        
    },
    headerLabel: {
        verticalAlign: 'middle',
        fontWeight: 550,
        fontSize: '0.9rem',
        whiteSpace: "break-spaces"
    },
    infoPoints: {
        flexGrow: 1,
        display: "flex"
    },
    titlesSection: {
        minWidth: "200px"
    },
    summarySection:{
        flexGrow: 1,
        borderLeft: "1px solid #ccc",
        marginLeft: "1rem",
        paddingLeft: "1rem"
    },
    percentsSection: {
       
    },
    exportItems: {
        '@media print': {
            display: "none"
        }
    },
    summaryText: {
        whiteSpace: "pre-line",
        fontSize: "14px"
    },
    editActionIcon: {
        marginLeft: "10px"
    },
    loaderWrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    loader: {
        // color: green[500],
        position: 'absolute',
        top: -4,
        left: 10,
        zIndex: 1,
    }
});


const InterviewHeader = ({ classes, title, candidate, stage, profileName, completedDate, summary, levelName, onEditSummary, isLoading }) => {
    const downloadTextFile = (value) => {
        console.log("it's working: " + value);
    }
    // const fakeData = [
    //     { title: "Junior", answersCount: 24, requiredLeftCount: 4 },
    //     { title: "Intermediate", answersCount: 26, requiredLeftCount: 8, }
    // ]
//     const sampleMarkup =
//   '<b>Bold text</b>, <i>Italic text</i><br/ ><br />' +
//   '<a href="http://www.facebook.com">Example link</a>';
   
    // const raw = convertToRaw(_contentState)
    // const [contentState, setContentState] = useState(raw) // ContentState JSON

    

    return (
        <Paper className={classes.root}>
            <Box className={classes.infoPoints}>
                <Box className={classes.titlesSection}>
                    <Typography variant="subtitle1" className={classes.title}>
                        <i className="material-icons" style={{ verticalAlign: 'middle' }}>
                            label
                    </i>
                    {title && (<span className={classes.headerLabel}>
                        Title: {title}
                    </span>)}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.title}>
                        <i className="material-icons" style={{ verticalAlign: 'middle' }}>
                            person
                    </i>
                        <span className={classes.headerLabel}>
                            Candidate: {candidate ? `${candidate.name} ${candidate.lastName}` : "Unassigned"}
                        </span>
                    </Typography>
                    {stageMessage(stage) && (<Typography variant="subtitle1" className={classes.title}>
                        <i className="material-icons" style={{ verticalAlign: 'middle' }}>
                            assignment_ind
                    </i>
                        <span className={classes.headerLabel}>
                            Stage: {stageMessage(stage)}
                        </span>
                    </Typography>)}
                    {profileName && (<Typography variant="subtitle1" className={classes.title}>
                        <i className="material-icons" style={{ verticalAlign: 'middle' }}>
                            work
                    </i>
                        <span className={classes.headerLabel}>
                            Profile: {profileName}
                        </span>
                    </Typography>)}
                    {levelName && (
                        <Typography variant="subtitle1" className={classes.title}>
                            <i className="material-icons" style={{ verticalAlign: 'middle' }}>
                                assignment_turned_in
                    </i>
                            <span className={classes.headerLabel}>
                                Level: {levelName}
                            </span>
                        </Typography>
                    )}
                    {completedDate && (<Typography variant="subtitle1" className={classes.title}>
                        <i className="material-icons" style={{ verticalAlign: 'middle' }}>
                            event
                        </i>
                        <span className={classes.headerLabel}>
                            Completion Date:
                            <Moment parse="YYYY-MM-DD" format="DD/MM/YYYY">
                                    {completedDate}
                            </Moment>
                        </span>
                    </Typography>)}
                </Box>
                <Box className={classes.summarySection}>
                   <SummaryEditor html={summary} isSaving={isLoading} onEdit={onEditSummary} />
                </Box>
                {/* <Box className={classes.percentsSection}></Box> */}
            </Box>
            <div className={classes.exportItems} >
               
            </div>
        </Paper>
    )
};

InterviewHeader.propTypes = {
    resultId: PropTypes.string,
    profileName: PropTypes.string,
    profileVersion: PropTypes.number,
    levelName: PropTypes.string,
    title: PropTypes.string,
    completedDate: PropTypes.string,
    summary: PropTypes.string,
    stage: PropTypes.number,
    candidate: PropTypes.object,
    classes: PropTypes.object
};

InterviewHeader.defaultProps = {
    profileName: "",
    profileVersion: 0,
    levelName: "",
    title: "",
    completedDate: "",
    summary: "",
    classes: "",
    stage: 0,
    candidate: null
};

export default withStyles(styles)(InterviewHeader);
