import React, { useState } from 'react'

import { IconButton, Popover, Box } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    helpText: {
        maxWidth: "300px",
        padding: "0px 10px 0px 10px"
    }
})

const HelpButton = ({ title, children }) =>{
    const [anchorEl, setAnchor] = useState(null);
    const classes = useStyles();
    return (<>
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={()=> setAnchor(null)}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
        >
            <Box className={classes.helpText}>
                {children}
            </Box>
        </Popover>
        <IconButton aria-label="delete" className={classes.margin} size="small" onClick={(event)=> setAnchor(event.currentTarget)}>
            <HelpOutlineIcon fontSize="inherit" />
        </IconButton>
    </>)
}

export default HelpButton;