import { setRequestError, setAppLoadingStarted, setAppLoadingFinished } from '../App/actions'
import * as scoresApi from '../../api/interview/ScoreRange'

export const scoresActions = {
	LOADED: "SCORES_LOADED",
	EDIT_GROUP: "SCORES_EDIT_GROUP",
	DELETE_GROUP: "SCORES_DELETE_GROUP",
	SAVE_GROUP: "SCORES_SAVE_GROUP",
	NEW_GROUP: "SCORES_NEW_GROUP",
	ADD_RANGE: "SCORES_ADD_RANGE"
}

const scoreGroupsLoaded = (result) => ({ type: scoresActions.LOADED, data: result })
const removeFromRedux = (id) => ({ type: scoresActions.DELETE_GROUP, data: { id: id }});
export const addGroup = (name) => (dispatch) => dispatch({ type: scoresActions.NEW_GROUP, data: { id: 0, name: name, ranges: [] }})

export const getScoreGroups = () => (dispatch) => {
	dispatch(setAppLoadingStarted());
	
	scoresApi.Get().then(response => {
		if(!response.error){
			dispatch(scoreGroupsLoaded(response.data));
			dispatch(setAppLoadingFinished());
		} else {
			dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => getScoreGroups()));
		}
	}).catch(error => {
		dispatch(setRequestError("Error occured:" + error, () => getScoreGroups()));
	});
}

export const deleteGroup = (id) => (dispatch) => {
	dispatch(setAppLoadingStarted());
	scoresApi.DeleteGroup(id).then(
		response =>{
			if(!response.error){
				dispatch(setAppLoadingFinished());
				dispatch(removeFromRedux(id));
			} else {
				dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => deleteGroup(id)));
			}
		}
	).catch(error => {
		dispatch(setRequestError("Error occured:" + error, () => deleteGroup(id)));
	});
}

export const editScoreGroup = (id) => (dispatch, getState) =>{
}

export const saveScoreGroup = (dispatch) =>{
	
}