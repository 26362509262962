import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { AppBar, Button, Toolbar, Typography, Hidden, Drawer,List, ListItem, ListItemText, IconButton,Icon,Divider } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import SecuredComponent from "../../common/components/SecuredComponent";
import CompareBucketButton from "./Compare/index"
import logo from '../../images/app_logo_white.png'

import AccountButton from "./AccountButton";

const styles = theme => ({
    topography: {
        cursor: 'pointer',
        display: 'inline-block'
    },
    root: {
        flexGrow: 1,
    },
    flex: {
        flex: 1,
    },
    toolbar: theme.mixins.toolbar,
    appBar: {
        // position: 'relative',
        // zIndex: theme.zIndex.drawer + 1,
        '@media print': {
            display: "none"
        }
    },
    noPrint: {
        
    }
});

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            mobileOpen: false
        }
    }

    handleLink(path) {
        this.props.history.push(path);
    }
    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };
    render() {
        const { classes } = this.props;
        const loginButton = this.props.isLoggedIn ?
            <AccountButton /> : <Button color="inherit" onClick={() => this.handleLink("/login")}>Login</Button>;

        return (
            <header className={classes.root}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Hidden smDown >
                            <div className={classes.flex}>
                                <Typography className={this.props.classes.topography} variant="h6" color="inherit" onClick={() => this.handleLink("/")}>
                                    <div className="app-logo-container">
                                        <img className="app-logo" src={logo} />
                                    </div>
                                </Typography>

                                <SecuredComponent isLoggedIn={this.props.isLoggedIn}>
                                    <Fragment>
                                        <Button key="btn-profiles" color="inherit" onClick={() => this.handleLink("/profiles")}>Profiles</Button>
                                        <Button key="interviews" color="inherit" onClick={() => this.handleLink("/interviews")}>Interviews</Button>
                                        <Button key="scores" color="inherit" onClick={() => this.handleLink("/scoresdetails")}>Scores details</Button>
                                        {/* <Button key="candidates" color="inherit" onClick={() => this.handleLink("/candidates")}>Candidates</Button> */}
                                        {/* <Button key="groups" color="inherit" onClick={() => this.handleLink("/groups")}>Groups</Button> */}
                                    </Fragment>
                                </SecuredComponent>
                            </div>

                        </Hidden>
                        <Hidden smUp >
                            <div className={classes.flex}>
                                <SecuredComponent isLoggedIn={this.props.isLoggedIn}>
                                    <Fragment>
                                        <IconButton
                                            color="inherit"
                                            aria-label="Open drawer"
                                            onClick={this.handleDrawerToggle}
                                            className={classes.menuButton}
                                            size="large">
                                            <Icon>menu</Icon>
                                        </IconButton>
                                        <Drawer
                                            variant="temporary"
                                            open={this.state.mobileOpen}
                                            onClose={this.handleDrawerToggle}>
                                            <div className={classes.toolbar} />
                                            <Divider />
                                            <List>
                                                <ListItem button key="lst-profiles" onClick={() => this.handleLink("/profiles")}>
                                                    <ListItemText primary={"Profiles"}/>
                                                </ListItem>
                                                <ListItem button key="interviews" onClick={() => this.handleLink("/interviews")}>
                                                    <ListItemText primary={"Interviews"}/>
                                                </ListItem>
                                                <ListItem button key="scores" onClick={() => this.handleLink("/scoresdetails")}>
                                                    <ListItemText primary={"Scores details"}/>
                                                </ListItem>
                                                {/* <ListItem button key="candidates" onClick={() => this.handleLink("/candidates")}>
                                                    <ListItemText primary={"Candidates"}/>
                                                </ListItem> */}
                                                {/* <ListItem button key="groups" onClick={() => this.handleLink("/groups")}>
                                                    <ListItemText primary={"Groups"}/>
                                                </ListItem> */}
                                                <Divider/>
                                            </List>
                                        </Drawer>
                                    </Fragment>
                                </SecuredComponent>
                                <Typography className={this.props.classes.topography} variant="h6" color="inherit" onClick={() => this.handleLink("/")}>
                                    <div className="app-logo-container">
                                        <img className="app-logo" src={logo} />
                                    </div>
                                </Typography>
                            </div>
                        </Hidden>
                        { this.props.isLoggedIn && <CompareBucketButton /> }
                        {loginButton}
                    </Toolbar>
                </AppBar>
            </header>
        );
    }
}

Header.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }),
    isLoggedIn: PropTypes.bool,
    classes: PropTypes.any,
    name: PropTypes.string,
    email: PropTypes.string,
    profileUrl: PropTypes.string
}

const mapStateToProps = ({ account }) => {
    return {
        isLoggedIn: account.isLoggedIn,
        name: account.name,
        email: account.email,
        profileUrl: account.profileUrl
    };
};

export default withRouter(connect(mapStateToProps, null)(withStyles(styles)(Header)));