
import {profileSelectActions} from './actions'

export const initialState = {
    options: [],
    filterValue: '',
    isLoading: false,
    selected: 0
}

export const profileSelectComponent = (state = initialState, action) => {
    switch (action.type) {
        case profileSelectActions.PROFILE_SELECT_SET_DATA:
            return Object.assign({}, state, action.data);

        default:
            return state;
    }
};