import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as ProfilesListActions from './actions'
import { bindActionCreators } from 'redux'
import { SnackbarContent, TablePagination, Icon, List, ListItem, ListItemText, ListItemAvatar, Avatar, Grid, TextField,Divider } from '@mui/material'
import { amber } from '@mui/material/colors'
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router'
import {initialState} from './reducer'
import Highlighter from "react-highlight-words"
import Loader from '../Loader'

import ProfileMenu from "./ProfileMenu";

const styles = () => ({
    warning: {
        backgroundColor: amber[700],
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    loader: {
        minHeight: 75
    }
});

class ProfilesList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 20
        }
        this.props.actions.getProfilesList(false,this.state.page, this.state.rowsPerPage);
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
        this.props.actions.getProfilesList(true,page, this.state.rowsPerPage);
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
        this.props.actions.getProfilesList(true,this.state.page, event.target.value);
    }

    itemClickHandler = (id) => {
        this.props.history.push('/profiles/edit/' + id)
    }
    deleteHandler = (id) => {
        this.props.actions.deleteProfile(id);
    }

    cloneHandler = (id) => {
        this.props.history.push('/profiles/clone/' + id)
    }
    handleFilterChange = (e) => {
        this.props.actions.setProfilesListData({ filterValue: e.target.value });
        this.setState({page:0});
        setTimeout(() => {
            this.props.actions.getProfilesList(true, 0, this.state.rowsPerPage);
        }, 200);
    }
    componentWillUnmount() {
        this.props.actions.setProfilesListData(initialState);
    }
    render() {
        const { classes } = this.props;
        if (this.props.list.length == 0 && this.props.initialLoaded) {
            return (<SnackbarContent className={classes.warning} message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon>warning</Icon>
                    No profiles found...
                </span>
            } />)
        }

        const rows = this.props.list.map(item => {
            return (
                <ListItem
                    key={item.id}
                    dense
                    button
                    onClick={() => { this.itemClickHandler(item.id) }}
                >
                    <ListItemAvatar>
                        <Avatar>
                            <Icon style={{ fontSize: 20 }}>assignment</Icon>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                        <Highlighter searchWords={[this.props.filterValue]} autoEscape={true} textToHighlight={item.name} />
                    </ListItemText>
                    <ProfileMenu 
                        id={item.id} 
                        showStart={item.hasReleasedVersion} 
                        releasedVersionId={item.releasedVersionId} 
                        onDelete={this.deleteHandler}
                        onClone={this.cloneHandler}
                        onStart = {(profileId, releasedVersionId) => this.props.actions.startInterview(profileId,releasedVersionId)} />
                </ListItem>
            )
        });
        return (
            <Fragment>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField id="filter"
                            name="filter"
                            label="Search"
                            value={this.props.filterValue}
                            onChange={this.handleFilterChange}
                            autoComplete='off'
                            margin="normal" />
                        <Divider/>
                    </Grid>
                    <Grid item xs={12}>
                        <List>
                            <Loader show={this.props.isFiltering} className={classes.loader} />
                            {rows}
                        </List>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={this.props.rowCount}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

ProfilesList.propTypes = {
    actions: PropTypes.object,
    list: PropTypes.array.isRequired,
    filterValue: PropTypes.string,
    isFiltering: PropTypes.bool,
    initialLoaded: PropTypes.bool,
    rowCount: PropTypes.number,
    classes: PropTypes.object.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    })
}

const mapStateToProps = (state) => {
    return {
        list: state.profilesListComponent.profilesList,
        rowCount: state.profilesListComponent.rowCount,
        filterValue: state.profilesListComponent.filterValue,
        isFiltering: state.profilesListComponent.isFiltering,
        initialLoaded: state.profilesListComponent.initialLoaded
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(ProfilesListActions, dispatch)
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProfilesList)));