import { configureStore as initStore, combineReducers } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'
import { app } from '../screens/App/reducer'
import { interviewsCompareList } from '../screens/App/Compare/reducer'
import { profilesListComponent } from '../screens/ProfilesList/reducer'
import { InterviewsCompareComponent } from '../screens/InterviewsCompare/reducer'
import { profileEditor } from '../screens/ProfileEditor/reducer'
import { interviewComponent } from '../screens/Interview/reducer'

import { account } from "../common/reducers/accountReducer";
import { interviewTimeLineComponent } from "../screens/InterviewTimeLine/reducer"
import { interviewsListComponent } from "../screens/InterviewsList/reducer"
import { candidatesListComponent } from "../screens/CandidatesList/reducer"
import { candidateEditor } from "../screens/CandidateEditor/reducer"
import { scoresGroups } from "../screens/Scores/reducer"
import { scoreGroup } from "../screens/ScoresEditor/reducer"
import { registerComponent } from '../screens/Register/reducer'
import { candidateView } from '../screens/CandidateView/reducer'
import { profileSelectComponent } from '../common/components/ProfileSelect/reducer'
import { candidatesInterviewsComponent } from '../screens/CandidateInterviewsComponent/reducer'
import { assignInterviewComponent } from '../screens/CandidateInterviewsComponent/AssignInterview/reducer'
import { scoresSelector } from '../screens/ProfileEditor/ScoresSelect/reducer'
import { candidateSelectComponent } from '../common/components/CandidateSelect/reducer'
import { userGroupsList } from '../screens/Groups/Main/reducer'
import { groupMembersComponent } from '../screens/Groups/Members/reducer'
import { preInterviewComponent } from '../screens/PreInterview/reducer'
import { usersInviteComponent } from '../screens/Groups/Invite/reducer'
import { rolesComponent} from '../screens/Roles/reducer'
import scoresEditor from '../screens/ScoresEditor/scoresEditorSlice'
import scoresGroupsSlice from '../screens/ScoresGroups/scoresGroupsSlice'
import interviewReviewSlice from '../screens/InterviewReview/interviewReviewSlice'

const rootReducer = combineReducers({
    app,
    interviewsCompareList,
    profilesListComponent,
    InterviewsCompareComponent,
    profileEditor,
    account,
    interviewTimeLineComponent,
    interviewsListComponent,
    interviewComponent,
    candidatesListComponent,
    scoresGroups,
    scoreGroup,
    candidateEditor,
    registerComponent,
    candidateView,
    profileSelectComponent,
    candidatesInterviewsComponent,
    assignInterviewComponent,
    scoresSelector,
    candidateSelectComponent,
    userGroupsList,
    groupMembersComponent,
    preInterviewComponent,
    usersInviteComponent,
    rolesComponent,
    scoresEditor,
    interviewReviewSlice,
    scoresGroupsSlice
});

// export default function configureStore() {
//     const composeEnhancers =
//         typeof window === 'object' &&
//             window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
//             window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//                 // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
//             }) : compose;
//     const store = createStore(rootReducer, {}, composeEnhancers(
//         applyMiddleware(thunk)
//     ));
//     return store;
// }

export default function configureStore() {
    const store = initStore({
        reducer: rootReducer
    })
    return store;
}
