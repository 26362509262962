import * as profileApi from '../../api/interview/Profile';
import * as interviewApi from '../../api/interview/Interview'
import { setRequestError, setAppLoadingStarted, setAppLoadingFinished } from '../App/actions'
import history from '../../utils/history'

export const profileListActions = {
    PROFILES_LIST_SET_DATA: 'PROFILES_LIST_SET_DATA',
    DELETE_PROFILE_SUCCEEDED: 'DELETE_PROFILE_SUCCEEDED'
}

export const setProfilesListData = (data) => ({ type: profileListActions.PROFILES_LIST_SET_DATA, data: data });
export const deleteProfileSucceeded = (id) => ({type: profileListActions.DELETE_PROFILE_SUCCEEDED, id});

export const startInterview = (profileId, releasedVersionId) => (dispatch) => {
    dispatch(setAppLoadingStarted());
    interviewApi.StartInterview({profileId,releasedVersionId}).then((response) => {
        const responseBody = response.data;
        if(!response.error) {
            dispatch(setAppLoadingFinished());
            const { data } = responseBody;
            history.push(`/interview/${data.result}`);
        } else {
            dispatch(setRequestError(responseBody.body.error.generalErrorMessage));
        }
     }).catch((error) => {
        dispatch(setRequestError("Error occured " + error, () => startInterview(profileId,releasedVersionId)));
    });
}

export const getProfilesList = (filtering = false,pageNumber = 0, pageSize = 5,ownerId = null) => (dispatch, getState) => {
    const {profilesListComponent} = getState();
    if(filtering && profilesListComponent.isFiltering) {
        return;
    }
    else if(filtering) {
        dispatch(setProfilesListData({isFiltering:true}));
    } else if(!filtering) {
        dispatch(setAppLoadingStarted());
    }

    const searchTerm = filtering ? profilesListComponent.filterValue : '';

    profileApi.GetPaged({pageNumber,pageSize,ownerId,searchTerm}).then((response) => {
            const responseBody = response.data;
            if (!response.error) {
                const { data } = responseBody;
                dispatch(setAppLoadingFinished());

                dispatch(setProfilesListData({
                    profilesList: data.results || [],
                    rowCount: data.rowCount,
                    initialLoaded: !searchTerm
                }));
            } else {
                dispatch(setRequestError(responseBody.body.error.generalErrorMessage));
            }

            if(filtering) {
                dispatch(setProfilesListData({isFiltering:false}));
            }
        }).catch((error) => {
            dispatch(setRequestError("Error occured " + error, () => getProfilesList(ownerId)));

            if(filtering) {
                dispatch(setProfilesListData({isFiltering:false}));
            }
        });
}

export const deleteProfile = (id) => (dispatch) => {
    dispatch(setAppLoadingStarted());
    profileApi.Delete(id)
    .then(response => {
        const responseBody = response.data;
        if (!response.error) {
            dispatch(setAppLoadingFinished());
            dispatch(deleteProfileSucceeded(id));
        } else {
            dispatch(setRequestError(responseBody.body.error.generalErrorMessage));
        }
    }).catch(error =>{
        dispatch(setRequestError("Error occured " + error, () => deleteProfile(id)));
    });
}