import React from 'react'
import ProfilesList from '../CandidatesList'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Button,Grid } from '@mui/material';

class ManageCandidates extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    handleCreateBtnClick = () => {
        this.props.history.push('/candidates/create');
    }
    render() {
        return (
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <ProfilesList />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={this.handleCreateBtnClick}>Register Candidate</Button>
                    </Grid>
                </Grid>
            </div>
        )
    }

}

ManageCandidates.propTypes = {
    history: PropTypes.object
}

export default withRouter(ManageCandidates);