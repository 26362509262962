import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button, Menu, MenuItem, Icon } from '@mui/material'
import history from "../../utils/history";
import {InterviewStages} from '../../common/constants/InterviewStages'

const menuActions = {
    Timeline:0,
    Compare:1,
    Delete:2,
    Edit:3
}

export default class InterviewMenu extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
        this.onAddToCompare = props.onAddToCompare;
    }

    onMenuClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ anchorEl: e.currentTarget });
    }

    onMenuItemClick = (e, target) => {
        e.preventDefault();
        e.stopPropagation();
        switch (target) {
            case menuActions.Timeline: {
                history.push(`/interviews/timeline/${this.props.id}`);
                break;
            }
            case menuActions.Compare: {
                this.onAddToCompare(this.props.id);
                this.setState({ anchorEl: null });
                break;
            }
            case menuActions.Delete: {
                this.props.onDelete(this.props.id);
                break;
            }
            case menuActions.Edit: {
                history.push(`/interview/${this.props.id}`);
                break;
            }
        }
    }

    handleClose = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ anchorEl: null });
    }

    render() {
        const { anchorEl } = this.state;
        return (

            <Fragment>
                <Button
                    aria-owns={anchorEl ? 'simple-menu' : null}
                    aria-haspopup="true"
                    onClick={this.onMenuClick}
                >
                    <Icon>more_vert</Icon>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                >
                    <MenuItem key={menuActions.Timeline} onClick={(e) => this.onMenuItemClick(e, menuActions.Timeline)}>Interview timeline</MenuItem>
                    <MenuItem key={menuActions.Compar} onClick={(e) => this.onMenuItemClick(e, menuActions.Compare)}>Add to compare</MenuItem>
                    <MenuItem key={menuActions.Delete} onClick={(e) => this.onMenuItemClick(e, menuActions.Delete)}>Delete Interview</MenuItem>

                    {this.props.stage == InterviewStages.Completed &&
                    <MenuItem key={menuActions.Edit} onClick={(e) => this.onMenuItemClick(e, menuActions.Edit)}>Edit Interview</MenuItem>
                    }
                </Menu>
            </Fragment>
        );
    }
}

InterviewMenu.propTypes = {
    id: PropTypes.number,
    onAddToCompare: PropTypes.func,
    onDelete: PropTypes.func,
    stage: PropTypes.number
}