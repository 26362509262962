/** @module Groups */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 */
export function Fetch() {
  return gateway.request(FetchOperation)
}

/**
 * @param {number} groupId 
 * @return {Promise<module:types.ApiResponseViewModel[GroupBasicInfo]>} Success
 */
export function FetchById(groupId) {
  const parameters = {
    path: {
      groupId
    }
  }
  return gateway.request(FetchByIdOperation, parameters)
}

/**
 * @param {number} groupId 
 * @return {Promise<module:types.ApiResponseViewModel[GroupMembersDetails]>} Success
 */
export function Members(groupId) {
  const parameters = {
    path: {
      groupId
    }
  }
  return gateway.request(MembersOperation, parameters)
}

/**
 */
export function MyInvites() {
  return gateway.request(MyInvitesOperation)
}

/**
 * @param {string} email 
 * @param {number} roleId 
 * @param {number} groupId 
 * @return {Promise<module:types.ApiResponseViewModel[InviteToGroupResult]>} Success
 */
export function Invite(email, roleId, groupId) {
  const parameters = {
    query: {
      email,
      roleId,
      groupId
    }
  }
  return gateway.request(InviteOperation, parameters)
}

export function UserRoleChange(data){
  const parameters = {
    path :{
      groupId: data.groupId
    },
    query:{
      userId: data.userId,
    },
    body:{
      roleIds: [...data.roleIds],
    }
  }
  return gateway.request(UserRoleChangeOperation, parameters)
}

/**
 * @param {string} key 
 * @return {Promise<module:types.ApiResponseViewModel[InviteAcceptResult]>} Success
 */
export function AcceptInvite(key) {
  const parameters = {
    path: {
      key
    }
  }
  return gateway.request(AcceptInviteOperation, parameters)
}

/**
 * @param {string} key 
 * @return {Promise<module:types.ApiResponseViewModel[InviteAcceptResult]>} Success
 */
export function DeclineInvite(key) {
  const parameters = {
    path: {
      key
    }
  }
  return gateway.request(DeclineInviteOperation, parameters)
}

/**
 * @param {number} groupId 
 * @return {Promise<module:types.ApiResponseViewModel[Object]>} Success
 */
export function LeaveGroup(groupId) {
  const parameters = {
    path: {
      groupId
    }
  }
  return gateway.request(LeaveGroupOperation, parameters)
}

/**
 * @param {number} groupId 
 * @param {number} userId 
 * @return {Promise<module:types.ApiResponseViewModel[Object]>} Success
 */
export function DeleteUser(groupId, userId) {
  const parameters = {
    path: {
      groupId,
      userId
    }
  }
  return gateway.request(DeleteUserOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {module:types.AddGroup} [options.group] 
 * @return {Promise<module:types.AddGroupResult>} Success
 */
export function Create(options) {
  if (!options) options = {}
  const parameters = {
    body: {
      group: options.group
    }
  }
  return gateway.request(CreateOperation, parameters)
}

const FetchOperation = {
  path: '/api/v1/Groups/fetch',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}


const UserRoleChangeOperation = {
  path: '/api/v1/Groups/{groupId}/user/roles',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const FetchByIdOperation = {
  path: '/api/v1/Groups/fetch/{groupId}',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const MembersOperation = {
  path: '/api/v1/Groups/members/{groupId}',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const MyInvitesOperation = {
  path: '/api/v1/Groups/invites',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const InviteOperation = {
  path: '/api/v1/Groups/invite',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const AcceptInviteOperation = {
  path: '/api/v1/Groups/acceptinvite/{key}',
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const DeclineInviteOperation = {
  path: '/api/v1/Groups/declineinvite/{key}',
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const LeaveGroupOperation = {
  path: '/api/v1/Groups/leave/{groupId}',
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const DeleteUserOperation = {
  path: '/api/v1/Groups/delete/{groupId}/{userId}',
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const CreateOperation = {
  path: '/api/v1/Groups',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}
