import React, { Component, Fragment, useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getScoresGroups } from './scoresGroupsSlice'
import { bindActionCreators } from 'redux'
import { Typography, Box, Fab, Divider } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import ScoreChip from '../../common/components/Scores/ScoreChip'
import { getColor } from '../../utils/colorPicker'

const useStyles = makeStyles({
    bold: {
        fontWeight: "bold"
    },
    mark: {
        display: "flex",
        paddingBottom: "0.7rem"
    },
    markWrapper: {
        display: "flex",
        minWidth: "6rem"
    }, 
    markDetails: {
        display: "flex"
    }
});

const ScoresGroups = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const groups = useSelector(state => state.scoresGroupsSlice.groups);
    const actions = bindActionCreators({ getScoresGroups }, dispatch);
    let isLoaded = false;

    useEffect(()=> {
        actions.getScoresGroups();
    }, [isLoaded]);
    
    return <Box>
        <Typography>Scores details</Typography>
        {groups.map(g=> <Box key={g.id}>
            <Box><span className={classes.bold}>Name:</span> {g.name}</Box>
            <Box><span className={classes.bold}>Description:</span> {g.description}</Box>
            <Box><span className={classes.bold}>Evaluation question:</span> {g.evaluationQuestion}</Box>
            <Box className={classes.bold}>Marks:</Box>
            <Box className={classes.mark}>
                <Box className={classes.markWrapper}>
                    <ScoreChip title={"N/A"} selectedColor={''} />
                </Box>
                <Box className={classes.markDetails}> - Not asked. Set when this area or topic wasn't checked</Box>
            </Box>
            {g.ranges.map( (r, index)=> 
                <Box className={classes.mark}>
                    <Box className={classes.markWrapper}>
                        <ScoreChip title={r.title} selectedColor={getColor(index, g.ranges.length)}></ScoreChip>
                    </Box>
                    <Box className={classes.markDetails}> - {r.description}</Box>
                </Box>
            )}
            <Divider />    
        </Box>)}
    </Box>
}

export default ScoresGroups;