const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isEmail = (value) => {
    return !value || emailRegex.test(String(value).toLowerCase());
}

export const notEmpty = (value) => {
    return value && value.trim().length != 0;
}

const phoneRegex = /\d/g;
export const isPhone = (value) => {
    return !value || (value.match(phoneRegex) || "").length === 12;
}