import React from 'react'
import PropTypes from 'prop-types'
import * as CandidateSelectActions from './actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { initialState } from './reducer'
import MaterialUiSelect from '../MaterialUiSelect'

class CandidateSelect extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    handleChange = (selectedOption) => {
        this.props.actions.candidateSelectSetData({ selected: selectedOption });
    }

    handleClick = () => {
        this.props.actions.searchCandidates();
    }

    handleInputChange = (filterValue) => {
        if(filterValue == this.props.filterValue) {
            return;
        }
        this.props.actions.candidateSelectSetData({ filterValue });
        setTimeout(() => {
            this.props.actions.searchCandidates();
        }, 200);
    }

    componentWillUnmount() {
        this.props.actions.candidateSelectSetData(initialState);
    }

    render() {
        return (
            <MaterialUiSelect
                onMenuOpen={this.handleClick}
                onInputChange={this.handleInputChange}
                options={this.props.options}
                value={this.props.selected}
                inputValue={this.props.filterValue}
                isLoading={this.props.isLoading}
                onChange={this.handleChange}
                isSearchable={true}
                textFieldProps={{
                    label: 'Candidate',
                    InputLabelProps: {
                        shrink: true,
                    },
                    error: this.props.error
                }}
                placeholder={"Select candidate..."}
            />
        )
    }
}

CandidateSelect.propTypes = {
    actions: PropTypes.object,
    options: PropTypes.array,
    filterValue: PropTypes.string,
    isLoading: PropTypes.bool,
    selected: PropTypes.any,
    error: PropTypes.any
}

const mapStateToProps = (state) => {
    return {
        ...state.candidateSelectComponent
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(CandidateSelectActions, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateSelect);