import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
	progres_container:{
		backgroundColor: theme.palette.grey[200],
	},
	progress_value:{
		backgroundColor: theme.palette.primary.main,
		height: "0.5rem"
	}
});

 class LinearProgress extends PureComponent{
	constructor(props){
		super(props);
	}

	render(){
		var barColor = this.props.color ? this.props.color : this.props.theme.palette.primary.main;
		return (<div className={this.props.classes.progres_container}>
			<div className={this.props.classes.progress_value} style={{width: this.props.value + "%", backgroundColor: barColor}}></div>
		</div>);
	}
}
export default withStyles(styles, {withTheme:true})(LinearProgress);

LinearProgress.propTypes = {
	classes: PropTypes.object,
	value: PropTypes.number,
	theme: PropTypes.object,
	color: PropTypes.string
}