import { setRequestError, setAppLoadingStarted, setAppLoadingFinished } from '../App/actions'
import * as profileApi from '../../api/interview/Profile';
import * as scoresApi from '../../api/interview/ScoreRange';
import history from '../../utils/history'
import {ProfileVersionStates} from '../../utils/globalConstants';
import getApiConfig from '../../utils/api/apiConfigurator';
import { ProfileApi } from "@interviewapp/interview-app-rest-client"
import { initialState } from '../ProfileEditor/reducer';


export const profileEditorActions = {
    PROFILE_SELECT_AREA: 'PROFILE_SELECT_AREA',
    PROFILE_SET: 'PROFILE_SET',
    PROFILE_ADD_AREA: 'PROFILE_ADD_AREA',
    PROFILE_REMOVE_AREAS: 'PROFILE_REMOVE_AREAS',
    PROFILE_EDIT_AREA: 'PROFILE_EDIT_AREA',
    PROFILE_EDITOR_SET_FOCUS_ROW: 'PROFILE_EDITOR_SET_FOCUS_ROW',
    PROFILE_EDITOR_SET_FOCUS_COLUMN: 'PROFILE_EDITOR_SET_FOCUS_COLUMN',
    PROFILE_EDITOR_CHANGE_POSITION_AREA: 'PROFILE_EDITOR_CHANGE_POSITION_AREA',
    PROFILE_EDITOR_GROUPS_LOADED: 'PROFILE_EDITOR_GROUPS_LOADED',
    PROFILE_EDITOR_GROUP_ASSIGNED: 'PROFILE_EDITOR_GROUP_ASSIGNED',
    PROFILE_AREA_SET_SCORE_CONFIG: 'PROFILE_AREA_SET_SCORE_CONFIG',
    PROFILE_AREA_ADD_TARGET: 'PROFILE_AREA_ADD_TARGET',
    PROFILE_AREA_CHANGE_TARGET: 'PROFILE_AREA_CHANGE_TARGET',
    PROFILE_AREA_DELETE_TARGET: 'PROFILE_AREA_DELETE_TARGET',
    PROFILE_RESET: 'PROFILE_RESET'
}
export const cleanup = () => (dispatch) => dispatch({ type: profileEditorActions.PROFILE_RESET })
export const addTarget = (areaId) => (dispatch) => dispatch({ type: profileEditorActions.PROFILE_AREA_ADD_TARGET, payload: { areaId } })
export const changeTarget = (areaId, index, levelId, minRangeId, isRequired) => (dispatch) => dispatch({ type: profileEditorActions.PROFILE_AREA_CHANGE_TARGET, payload: {
    areaId, levelId, minRangeId, isRequired, index
}})
export const deleteTarget = (areaId, index) => (dispatch) => dispatch({ type: profileEditorActions.PROFILE_AREA_DELETE_TARGET, payload: { areaId, index }})

const getDirectChildren = (areas, id) => {
    return areas.filter(el => el.parentId == id);
}

const getChildrenAreas = (areas, id) => {
    const directChildren = getDirectChildren(areas, id);
    let allChildren = [...directChildren];

    directChildren.forEach(el => allChildren = [...allChildren, ...getChildrenAreas(areas, el.id)])

    return allChildren;
}

export const getProfileScoresInfo = (id) => (dispatch) => {
	dispatch(setAppLoadingStarted());
	
	scoresApi.ProfileScoresInfo(id).then(response => {
		if(!response.error){
			dispatch({ type: profileEditorActions.PROFILE_EDITOR_GROUPS_LOADED, data: response.data });
			dispatch(setAppLoadingFinished());
		} else {
			dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => getProfileScoresInfo()));
		}
	}).catch(error => {
		dispatch(setRequestError("Error occured:" + error, () => getProfileScoresInfo()));
	});
}

export const selectArea = (id) => (dispatch) => dispatch({ type: profileEditorActions.PROFILE_SELECT_AREA, payload: { id } });

export const assignScoreGroup = (profileId, groupId) => (dispatch) =>{
    // dispatch({ type: profileEditorActions.PROFILE_EDITOR_GROUP_ASSIGNED, data: { assignedId: groupId }})
    dispatch(setAppLoadingStarted());
    scoresApi.AssignToProfile(profileId, groupId).then(response=>{
        if(!response.error){
            dispatch(setAppLoadingFinished());
            dispatch({ type: profileEditorActions.PROFILE_EDITOR_GROUP_ASSIGNED, data: { assignedId: groupId }})
        } else {
            dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => assignScoreGroup()));
        }
    }).catch(error => {
		dispatch(setRequestError("Error occured:" + error, () => assignScoreGroup()));
	});
}

export const setFocusRow = (rowOrder) => (dispatch,getState) => {
    if(rowOrder>=0 && rowOrder<getState().profileEditor.areas.length) {
        dispatch({ type: profileEditorActions.PROFILE_EDITOR_SET_FOCUS_ROW, order: rowOrder });
    } else if(rowOrder == null) {
        dispatch({ type: profileEditorActions.PROFILE_EDITOR_SET_FOCUS_ROW, order: rowOrder });
    }
}

export const setFocusColumn = (column) => (dispatch) => {
    const columns = ['title','description'];
    if(column>=0 && column<columns.length) {
        dispatch({ type: profileEditorActions.PROFILE_EDITOR_SET_FOCUS_COLUMN, column });
    } else if(column == null) {
        dispatch({ type: profileEditorActions.PROFILE_EDITOR_SET_FOCUS_COLUMN, column });
    }
}

export const setProfileData = (data) => ({ type: profileEditorActions.PROFILE_SET, data });

export const editArea = (id, data) => (dispatch) => {
    dispatch({ type: profileEditorActions.PROFILE_EDIT_AREA, id, data })
};

export const addArea = (data, parentId) => (dispatch, getState) => {
    if (!parentId) {
        parentId = '';
    }
    let newArea = { ...data, parentId: parentId, id: (new Date()).getTime().toString() };

    const areas = getState().profileEditor.areas;
    const scores = getState().profileEditor.scoresConfigs;
    const defaultScoreTitle = parentId ? "Topic score" : "Area scores";
    newArea.scoreConfigId =  scores.find(x=>x.name == defaultScoreTitle).id;
    if (!data.order) {
        if (parentId == '') {
            newArea.order = areas.length > 0 ? Math.max(...(areas.map(el => el.order))) +1 : 0;
        } else {
            const children = getChildrenAreas(areas, parentId);
            const orders = children.map(el => el.order);
            newArea.order = orders.length > 0 ? Math.max(...orders) + 1 : areas.find(ar => ar.id == parentId).order + 1;
        }
    }
    dispatch({ type: profileEditorActions.PROFILE_ADD_AREA, area: newArea });

};

export const removeArea = (id) => (dispatch, getState) => {
    if (id) {
        const { areas } = getState().profileEditor;
        const areaToRemove = areas.find(a => a.id == id);
        const childrenIds = getChildrenAreas(areas, id).map(ar => ar.id);
        const ids = [...[id], ...childrenIds];
        const targetOrder = areaToRemove.order + childrenIds.length;
        const orderMap = {};
        for(let i =0;i<areas.length;++i) {
            const area = areas[i];
            if(area.order > targetOrder) {
                orderMap[area.id] = area.order-childrenIds.length-1;
            }
        }
        dispatch({ type: profileEditorActions.PROFILE_REMOVE_AREAS, ids, orderMap })
    }
};

export const changeAreaPosition = (id, newOrder, newParent,oldOrder,oldParent) => (dispatch,getState) => {
    const { areas } = getState().profileEditor;

    const allChildren = getChildrenAreas(areas,id);
    const childIds = allChildren.map(child => child.id);
    const orderMap = {};
    for(let i =0;i<areas.length;++i) {
        let area = areas[i];
        if(childIds.indexOf(area.id) != -1) {
            continue;
        }
        if(newOrder<oldOrder) {
            if(area.order >= newOrder && area.order < oldOrder) {
                orderMap[area.id] = area.order + allChildren.length + 1;
            }
        } else {
            if(area.order <= newOrder && area.order > oldOrder) {
                orderMap[area.id] = area.order - allChildren.length - 1;
            }
        }

    }
    if(newOrder > oldOrder && childIds.length >0) {
        newOrder = newOrder - childIds.length;
    }
    orderMap[id] = newOrder;
    for(let i =0;i<allChildren.length;++i) {
        let child = allChildren[i];
        orderMap[child.id]=child.order + (newOrder - oldOrder);
    }


    dispatch({type: profileEditorActions.PROFILE_EDITOR_CHANGE_POSITION_AREA, id, newOrder, orderMap, newParent});
};

export const postProfile = (release = false) => (dispatch, getState) => {
    dispatch(setAppLoadingStarted());
    var profileData = getState().profileEditor;
    profileApi.Upsert({
        profile:{
            areas: profileData.areas,
            name: profileData.profileName,
            id: profileData.profileId ? profileData.profileId : 0,
            versionId: profileData.versionId ? profileData.versionId : 0,
            state: release ? ProfileVersionStates.Released : ProfileVersionStates.Draft,
            clonedFromId: profileData.clonedFromId
        }
    }).then((response) => {
            const responseBody = response.data;
            if (!response.error) {
                dispatch(setAppLoadingFinished());
                dispatch(setProfileData(initialState));
                history.push('/profiles/edit/' + response.data.data.id);
                history.go();
            } else {
                dispatch(setRequestError(responseBody.body.error.generalErrorMessage, () => postProfile()));
            }
        })
        .catch((error) => {
            dispatch(setRequestError("Error occured:" + error, () => postProfile()));
        })
};

export const getProfile = (id, isClone) => (dispatch) => {
    if (!id && !isClone) {
        dispatch(setProfileData({ profileName: "New Profile", profileId: 0, areas: [] }));
        return;
    }
    dispatch(setAppLoadingStarted());
    var client = new ProfileApi(getApiConfig());
    var promise = isClone ? client.clone(id) : client.get(id);

    promise
        .then(response => {
            const responseBody = response.data;
            if (!response.error) {
                const { data } = responseBody;
                dispatch(setProfileData({ 
                    profileName: data.name, 
                    profileId: data.id, 
                    areas: data.areas.map(x=>{
                            x.selected = false;
                        return x;
                    }), 
                    clonedFromId: data.clonedFromId,
                    versionId:data.versionId, 
                    versionState:data.state, 
                    scoresConfigs: data.scoresConfigs,
                    levelsGroups: data.levelsGroups,
                    targetLevel: data.targetLevelId ? data.levelsGroups.find(x=>x.id == data.targetLevelId) : data.levelsGroups[0]
                }));
                dispatch(setAppLoadingFinished());
            } else {
                dispatch(setRequestError(responseBody.body.error.generalErrorMessage, () => getProfile(id)));
            }
        })
        .catch(error => {
            dispatch(setRequestError("Error occured:" + error, () => getProfile()));
        });
};

export const setScoreConfig = (areaId, scoreConfigId) => (dispatch) => {
    dispatch({ type: profileEditorActions.PROFILE_AREA_SET_SCORE_CONFIG, data: { areaId, scoreConfigId }})
}