import './style.scss'
import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types'
import { Grid, Button, TextField, Fab, Tooltip, Icon, Drawer, Paper, Box, Typography, CircularProgress, IconButton } from '@mui/material'
import TreeGrid from '../../common/components/TreeGrid/TreeGrid'
import { bindActionCreators } from 'redux';
import * as interviewActions from './actions';
import AreaTitle from "./Renderers/AreaTitle";
import ScoreSetAction from './Renderers/ScoreSetAction';
import ScoreDetails from './Renderers/ScoreDetails'
import ExpertComment from './Renderers/ExpertComment'
import SummarySection from "./SummarySection";
import CandidateSelect from '../../common/components/CandidateSelect'
import withStyles from '@mui/styles/withStyles';
import {initialState} from './reducer'
import AreaResponse from '../../common/components/Cards/AreaResponse'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import LiveLevelsPercents from './LiveLevelsPercents'
import SimpleWidget from '../../common/components/Cards/SimpleWIdget';
import CloseIcon from '@mui/icons-material/Close';

const styles = () => ({
    selectWrapper: {
        paddingTop:30
    },
    expandAllIcon: {
        position:'relative',
        left:72,
        width:35,
        height:35
    },
    interviewName: {
        width:300,
        marginBottom: "20px"
    },
    selectedCandidate:{
        marginBottom: "20px"
    },
    sidePanel: {
        width: "30%"
    },
    sideActions: {
        margin: "0px 0px 10px 10px"
    },
    levelPercentageWrapper: {
        margin: "10px 0px 10px 10px",
        padding: "10px"
    },
    responsesTable:{
        display: "flex"
    },
    tree: {
        flexGrow: 1
    },
    rightPanelBox: {
        cursor: "pointer",
        display: "flex",
        justifyContent: "flex-end",
        paddingBottom: "15px",
        paddingTop: "5px"
    },
    rightPanelLoader: {
        display: "flex",
        
        flexGrow: 1,
        justifyContent: "flex-end"
    },
    generalSection: {
        display: "flex"
    },
    generalSection:{
        display: "flex",
        flexDirection: "column"
    },
    generalButtons: {
        display: "flex",
        justifyContent: "space-between"
    },
    scoreInfo:{
        display: "flex",
        alignItems: "center"
    },
    deselectButton: {

    },
    responseActions: {
        transition: "ease .5s"
    }

})

class Interview extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            levelIdError: false,
            expandAll: true,
            saveProgress: false,
            selectedAreaId: null
        }

        this.props.actions.getInterview(this.props.id);
    }

    componentDidMount = () => {
        // set height
        this.setTreeHeight();
    }

    setTreeHeight = () =>{
        var treeContainer = document.getElementsByClassName('tree-grid-data-container')[0];
        var wrapper = document.getElementById("responsesTree");
        // treeContainer.style.maxHeight = wrapper.
    }

    resetLevelError = () => {
        this.setState({ levelIdError: false });
    }

    onSubmit = (complete) => {
        if (this.state.levelId === "") {
            this.setState({ levelIdError: true });
        }
        else {
            this.props.actions.updateInterview(complete);
        }
    }

    getAreaTitleAndDescripton = (areaId) =>{
        var area = this.props.areas.find(x=>x.id == areaId);
        return {
            title: area.title,
            description: area.description,
            id: area.id
        }
    }

    handleSummarySectionChange = (levelId, summary) => {
        this.props.actions.setInterviewData({levelId,interviewSummary:summary})
    }

    getGridConfig = (classes) => {
        var me = this;
        return {
            stateKey: 'interviewComponent',
            dataKey: 'areas',
            rootId: '',
            paddingBase: 16,
            dragAndDrop: false,
            onRowClick: (event, target, rowData) => {
               me.setState({ selectedAreaId: rowData.id })
               me.props.actions.selectArea(rowData.id);
            },
            selectable: true,
            expanded: this.state.expandAll,
            focus: {
                stateKey: 'interviewComponent',
                focusColumnProperty: 'focusColumn',
                focusRowProperty: 'focusRow',
                setFocusRow: order => {
                    this.props.actions.setFocusRow(order);
                },
                setFocusColumn: column => {
                    this.props.actions.setFocusColumn(column);
                }
            },
            propChangeHandler: (id, prop, value) => {
                const data = {};
                data[prop] = value;
                this.props.actions.editInterviewArea(id, data);
            },
            columnsConfigs: [
                {
                    prop: 'title',
                    text: 'Title',
                    visible: true,
                    width: 4,
                    renderer: (options) =>{
                        var areaDetails = me.getAreaTitleAndDescripton(options.id);
                        return <AreaTitle {...areaDetails} />
                    }
                },
                {
                    text: 'Evaluation score',
                    visible: true,
                    width: 2,
                    className: "",
                    renderer: (options) => {
                        return <ScoreSetAction className={classes.scoreInfo} {...options} />
                    },
                },
                {
                    text: "Target levels",
                    prop:'targetConfigs',
                    visible: true,
                    width: 3,
                    renderer: (options) => {
                        const values = options?.value;
                        const area = me.props.areas.find(x=>x.id == options.id);
                        if(values) {
                            const datas = values.map(x=> {
                                const level = me.props.levels.find(l=>l.id == x.levelId);
                                const scoreConfig = me.props.scoresConfigs.find(a => area.scoreConfigId == a.id);
                                if(level) return <span>
                                            <span className={`levelIndicator ${x.isRequired ? 'levelIndicatorRequired' : '' }`}>
                                                {level.name},
                                            </span>
                                        </span>
                                else return null;
                            })
                            return datas;
                        }
                        return <span>..</span>
                    },
                },
                {
                    prop: 'comment',
                    text: 'Comment',
                    visible: true,
                    width: 2,
                    renderer: (options) => {
                        return <ExpertComment {...options} />
                    }
                },
            ]
        };
    }

    componentWillUnmount() {
        this.props.actions.setInterviewData(initialState);
    }

    onChange = (e) => { 
        this.props.actions.setInterviewData({[e.target.name]: e.target.value})
    }

    render() {
        const { classes } = this.props;
        var treeConfig = this.getGridConfig(classes);
        
        const expandCollapseText = this.state.expandAll ? 'Collapse All' : 'Expand All';

        var scoresConfig = null;
        const selectedArea = this.props.areas.find(x=>x.selected);
        if (selectedArea && this.props.scoresConfigs && this.props.scoresConfigs.length > 0){
            scoresConfig = this.props.scoresConfigs.find(x=>x.id == selectedArea.scoreConfigId);
            if(!scoresConfig){
                scoresConfig = this.props.scoresConfigs[0]
            }
        }
        {/* <Box className={classes.rightPanelBox} >
                <Box className={classes.rightPanelLoader}>
                    <Button variant="outlined" size="small" className={classes.deselectButton} onClick={()=> this.props.actions.selectArea(null)} >Deselect area</Button>
                    {selectedArea.isSaving && <CircularProgress size={30} color="secondary" />}
                </Box>
            </Box> */}
            
        const responseActions = (selectedArea &&  <SimpleWidget title={selectedArea.title} className={`${classes.sideActions} ${classes.responseActions} `} actions={
            <>
                <IconButton
                    size="small"
                    // className={clsx(classes.expand, {
                    //     [classes.expandOpen]: expanded,
                    // })}
                    onClick={()=> this.props.actions.selectArea(null)}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </>
        }>
            <AreaResponse isSaving={selectedArea.isSaving} {...selectedArea} scoresConfig={scoresConfig} 
            onScoreSet={(value, title) => {
                this.props.actions.setScore(this.props.id, selectedArea.id, {
                    id: selectedArea.id,
                    comment: selectedArea.expertResponse?.comment ?? "",
                    score: value,
                    scoreTitle: title,
                    notAsked: value == -1
                })
            }}

            onCommentChange={(text) => {
                this.props.actions.setScore(this.props.id, selectedArea.id, {
                    id: selectedArea.id,
                    comment: text,
                    score: selectedArea?.expertResponse?.score,
                    scoreTitle: selectedArea?.expertResponse?.scoreTitle,
                    notAsked: (selectedArea?.expertResponse?.score ?? -1) == -1 
                })
            }}
            ></AreaResponse>
        </SimpleWidget>
        );
        const summarySection = <Box className={classes.generalSection}>
                {/* <TextField variant="outlined" id='interviewName' name="interviewName" label="Interview Name" value={this.props.interviewName}
                        onChange={this.onChange} autoComplete='off' margin="normal" className={classes.interviewName} /> */}
                {/* <Box className={classes.selectedCandidate}>
                    <CandidateSelect />
                </Box> */}
                <SummarySection
                    levels={this.props.levels}
                    title={this.props.interviewName}
                    levelId={this.props.levelId}
                    onChangeLevel={(levelId) => this.props.actions.setCandidateLevel(this.props.id, levelId)}
                    onChangeTitle={(title) => this.props.actions.setInterviewTitle(this.props.id, title)}
                    isLevelSaving={this.props.isLevelSaving}
                    isTitleSaving={this.props.isTitleSaving}
                    // resetLevelError={this.resetLevelError}
                    // levelError={this.state.levelIdError}
                />
            </Box>

        const sidePanel = (
            <Box className={classes.sidePanel}>
                {/* <Paper className={classes.levelPercentageWrapper}>
                    
                </Paper> */}
                <SimpleWidget title="Auto-score" className={classes.sideActions}>
                    <LiveLevelsPercents {...this.props} />
                </SimpleWidget>
                {responseActions}
                <SimpleWidget title={"General"} className={classes.sideActions}>
                    {summarySection}
                </SimpleWidget>
                
                {/* <Paper className={classes.sideActions}> */}
                    <Box className={`${classes.generalButtons} ${classes.sideActions}`}>
                        <Button variant="contained" color="primary" disabled={this.props.completedDate != null} onClick={() => this.onSubmit(true)}>Complete</Button>
                        <Button variant="contained" color="secondary" onClick={this.props.history.goBack}>Back</Button>
                        <Button
                            variant="contained" color="secondary"                         
                            onClick={() => this.setState({expandAll:!this.state.expandAll})} >
                                { this.state.expandAll ? 'Collapse tree' : 'Expand tree' }
                        </Button>
                    </Box>
                {/* </Paper> */}
            </Box>);
        return (
            <Grid container>
                {/* <Grid container direction='row-reverse'>
                    <Grid item xs={1}>
                        <Tooltip title={expandCollapseText} placement="top">
                                <Fab
                                    size="small"
                                    className={classes.expandAllIcon}                          
                                    onClick={() => this.setState({expandAll:!this.state.expandAll})}
                                    color="primary">
                                    <Icon>{this.state.expandAll ? 'layers' : 'layers_clear'}</Icon>
                                </Fab>
                        </Tooltip>
                    </Grid>
                </Grid> */}

                <Grid id="responsesTree" className={classes.responsesTable} item xs={12}>
                    <TreeGrid {...treeConfig} />
                    {sidePanel}
                </Grid>
            </Grid>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        ...state.interviewComponent,
        id: parseInt(ownProps.match.params.id),
        accountId: state.account.id,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(interviewActions, dispatch),
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Interview)));

Interview.propTypes = {
    actions: PropTypes.object,
    areas: PropTypes.array,
    profileId: PropTypes.number,
    versionId:PropTypes.number,
    levelId: PropTypes.number,
    errors: PropTypes.object,
    classes: PropTypes.any,
    interviewName: PropTypes.string,
    interviewSummary: PropTypes.string,
    id: PropTypes.number,
    levels: PropTypes.array,
    accountId: PropTypes.number,
    history: PropTypes.object,
    selectedCandidate: PropTypes.any,
    completedDate: PropTypes.any
}

