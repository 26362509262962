import * as validationRules from '../../../utils/validationHelpers'

export const assignInterviewRules = {
    'interviewName': {
        rule:validationRules.notEmpty,
        message:'Interview Name is required'
    },
    'selectedProfile': {
        rule: (selectedProfile) => {
            if(!selectedProfile || selectedProfile == 0 || !selectedProfile.value 
                || !selectedProfile.versionId) {
                return false;
            }
            return true;
        },
        message: 'Profile is required'
    }
}