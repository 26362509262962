import React, { Fragment } from 'react'
import { withRouter } from 'react-router'
import { Grid } from '@mui/material';
import InterviewsList from '../InterviewsList';

class ManageInterviews extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Fragment>
                <Grid container>
                    <Grid item xs={12}>
                        <InterviewsList />
                    </Grid>
                </Grid>
            </Fragment>
        )
    }

}

export default withRouter(ManageInterviews);