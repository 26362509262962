import authService from "./authService";
import React from 'react';
import  * as serviceGateway  from '../api/interview/gateway/';
import {InterviewStages} from '../common/constants/InterviewStages'

export const configureApiClient = () => {
    var getAccountToken = function(){
        const token = `Bearer ${authService.getJwt()}`; // A utility function elsewhere in your application that returns a string containing your token – possibly from Redux or localStorage
        return Promise.resolve({ apiKey: token });
    }

    serviceGateway.init({
        // url: 'https://service.com/api', // set your service url explicitly. Defaults to the one generated from your OpenAPI spec
        getAuthorization: function (security) {
            switch (security.id) {
                case 'oauth2': return getAccountToken(security);
                // case 'api_key': return getApiKey(security); // Or any other securityDefinitions from your OpenAPI spec
                default: throw new Error(`Unknown security type '${security.id}'`)
            }
        },
        processError: function name(req,res) {
            throw new Error(res.res.data.message);
        }
        
      });
}

export const objectWithoutProperties = (obj, keys) => {
    var target = {};
    for (var i in obj) {
        if (keys.indexOf(i) >= 0)
            continue;
        if (!Object.prototype.hasOwnProperty.call(obj, i))
            continue;
        target[i] = obj[i];
    }
    return target;
}

export const sortBy = (field, reverse, primer) => {

    var key = primer ?
        x => { return primer(x[field]) } :
        x => { return x[field] };

    reverse = !reverse ? 1 : -1;

    return (a, b) => {
        return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    }
}

export const sortedIndex = (array, value, comparer) => {
    var low = 0,
        high = array.length;

    while (low < high) {
        var mid = (low + high) >>> 1;
        if (comparer(value, array[mid]) == 1) low = mid + 1;
        else high = mid;
    }
    return low;
};

export const sortedInsert = (array, value, comparer) => {
    array.splice(sortedIndex(array, value, comparer), 0, value);
    return array;
}

export const additionalSetup = () => {
    // window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

    // if (process.env.NODE_ENV !== 'production') {
    //     //const { whyDidYouUpdate } = require('why-did-you-update');
    //     //whyDidYouUpdate(React);
    // }

    configureApiClient();
}

export const mapTreeResponsesToFlatArray = (responses, filteredResponses) => {

    if(responses == null)
        return [];
    responses.map(item => {
        filteredResponses.push(item);
        if(item.responses != null){
            mapTreeResponsesToFlatArray(item.responses, filteredResponses)
        }
    })
}



export const stageMessage = (stageValue) => {
    let message = '';
    switch (stageValue) {
        case InterviewStages.PreInterview:
            message = "Pre Interview";
            break;
        case InterviewStages.PreInterviewSubmitted:
            message = "Pre Interview Submitted";
            break;
        case InterviewStages.WaitingForInterview:
            message = "Waiting for Interview";
            break;
        case InterviewStages.Started:
            message = "Started";
            break;
        case InterviewStages.Completed:
            message = "Completed";
            break;
        default:
            message = "Unknown";
            break;
    }
    return message;
}

export const mapFlatResponsesToAreas = (areas, responses = []) => {
        return areas.map(area => {
            let response = responses.find((response) =>
                (response.id === area.id) && response);
            if(response !== undefined)
                return Object.assign({}, area, { score: response.score, notAsked: response.notAsked, comment: response.expertComment, scoreTime: response.scoreTime });
            else 
                return area;
        }
        );
}

export const objectOrderComparer = (leftArea, rightArea) => {
    if (leftArea.order > rightArea.order) {
        return 1;
    } else if (leftArea.order == rightArea.order) {
        return 0;
    } else {
        return -1;
    }
};

export const getTreeData = (data,comparer) => {
    if(!comparer) {
        comparer = objectOrderComparer;
    }
    let root = {
        id: -1,
        title: 'Root',
        children: []
    };
    let map = {};
    let areas = [];

    for (let i = 0; i < data.length; i += 1) {
        const area = Object.assign({}, data[i]);
        map[area.id] = i;
        area.children = [];
        areas.push(area);
    }

    for (let i = 0; i < areas.length; i += 1) {
        let node = areas[i];
        if (node.parentId) {
            sortedInsert(areas[map[node.parentId]].children, node, comparer);
        } else {
            sortedInsert(root.children, node, comparer);
        }
    }
    return root;
}

export const validateObject = (rules,obj) => {
    const result = {
        isValid:true,
        errors:{}
    };

    for(var key in rules) {
        if(!rules.hasOwnProperty(key) || !obj.hasOwnProperty(key)) {
            continue;
        }
        let propRules = rules[key];
        if(!Array.isArray(propRules)) {
            propRules = [].concat(propRules);
        }
        let value = obj[key];
        for(let i =0;i<propRules.length;++i) {
            let propRule = propRules[i];
            if(!propRule.rule(value)) {
                result.isValid = false;
                result.errors[key] = propRule.message;
                break;
            }
        }
    }

    return result;
};

export const stringToHslColor = (str, s, l) => {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    var h = hash % 360;
    return 'hsl('+h+', '+s+'%, '+l+'%)';
  }


  export const copyStringToClipboard = (str) => {
    // Create new element
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
 }