import * as accountApi from '../../api/interview/Account'
import { setRequestError, setAppLoadingStarted, setAppLoadingFinished } from '../../screens/App/actions';
import authService from '../../utils/authService.js';
import history from '../../utils/history';

export const loginActions = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGOUT: 'USERS_LOGOUT',
    AUTO_LOGIN_FAILURE: "AUTO_LOGIN_FAILURE",
    REGISTER: "REGISTER"
}

export const login = (email, password) => (dispatch) => {
    dispatch(setAppLoadingStarted());
    return accountApi.Login(email,password)
        .then((response) => {
            //const responseBody = response.body;
            if (!response.error && response.data) {
                const {data} = response;
                dispatch(setAppLoadingFinished());
                dispatch({ type: loginActions.LOGIN_SUCCESS, data });
                
                //save token to localStorage
                authService.setJwt(data.token);
                
                if(window.location.search.indexOf("redirectTo") > 0){
                    // redirect to page
                    history.push(window.location.search.replace("?redirectTo=", ""));
                } else {
                    //redirect to main page
                    history.push("/");
                }
            } else {
                if(response.raw.status == 400) {
                    dispatch(setAppLoadingFinished());
                } else {
                    dispatch(setRequestError(response.data.body.error.generalErrorMessage,() => login(email,password)));
                }
            }

            return response;
        })
        .catch((error) => {
            dispatch(setRequestError("Error occured:" + error, () => login(email,password)));
        });
}

export const fbloginConfirmation = (response) => (dispatch) => {
    dispatch(setAppLoadingStarted());
   
    //const responseBody = response.body;
    if (!response.error && response.data) {
        const {data} = response;
        dispatch(setAppLoadingFinished());
        dispatch({ type: loginActions.LOGIN_SUCCESS, data });

        //save token to localStorage
        authService.setJwt(data.token);
        
        if(window.location.search.indexOf("redirectTo") > 0){
            // redirect to page
            history.push(window.location.search.replace("?redirectTo=", ""));
        } else {
            //redirect to main page
            history.push("/");
        }
    } 
    // else {
    //     if(response.raw.status == 400) {
    //         dispatch(setAppLoadingFinished());
    //     } else {
    //         dispatch(setRequestError(response.data.body.error.generalErrorMessage,() => login(email,password)));
    //     }
    // }

    return response;
}

export const register = (user) => (dispatch) => {
    dispatch(setAppLoadingStarted());
    return accountApi.Register(user.userName,user.email,user.password)
        .then(response => {
            dispatch(setAppLoadingFinished());
            if (!response.error) {
                history.push("/login");
            } else {
                dispatch(setRequestError(response.data.body.error.generalErrorMessage,() => register(user)));
            }
            return response;
        })
        .catch((error) => {
            dispatch(setRequestError("Error occured:" + error, () => register(user)));
        });
}

export const checkFbLogin = () => {
    // FB.getLoginStatus(function(response) {
    //     this.statusChangeCallback(response);
    // });
}

export const autoLogin = () => dispatch => {
    window.hh = history;
    dispatch(setAppLoadingStarted());

    const token = authService.getJwt();
    accountApi.Info({ "token": token })
        .then(response => {

            if (!response.error && !response.data.invalidToken) {
                const {data} = response;
                dispatch({ type: loginActions.LOGIN_SUCCESS, data });

                if(window.location.search.indexOf("redirectTo") > 0){
                    // redirect to page
                    history.push(window.location.search.replace("?redirectTo=", ""));
                } else {
                    //redirect to main page
                    history.push("/");
                }
            } else  {
                // check FB login
                // checkFbLogin();

                var redirectTo = "";
                if(window.location.pathname != "/login"){
                    redirectTo = "?redirectTo=" + window.location.pathname;
                }
                history.push("/login" + redirectTo);
            }

            dispatch(setAppLoadingFinished());
        })
        .catch((exc) => {
            dispatch(setAppLoadingFinished());
            var redirectTo = "";
            if(window.location.pathname != "/login"){
                redirectTo = "?redirectTo=" + window.location.pathname;
            }
            history.push("/login" + redirectTo);
        });
};

export const logout = () => (dispatch) => {
    //remove token from localStorage
    authService.removeJwt();

    dispatch({ type: loginActions.LOGOUT })
}
