import { createSlice } from '@reduxjs/toolkit'
import { setRequestError, setAppLoadingStarted, setAppLoadingFinished } from '../App/actions'
import * as scoresApi from '../../api/interview/ScoreRange'
import history from '../../utils/history'

const scoresEditorSlice = createSlice({
    name: "scoresEditor",
    initialState: {
        id: 0,
        name: "",
        ranges: []
    },
    reducers: {
        newRange: {
            reducer: (state, action) => {
                state.ranges.push(action.payload);
            },
            prepare: (title) => {
                return { payload: { id: 0, title: title, bottom: 0, top: 0 }}
            } 
        },
        newGroup: {
            reducer: (state, action) => {
                state = action.payload
            },
            prepare: (title) => {
                return { payload: { name: title, ranges: [{ id: 0, title: "beginner", bottom: 0, top: 0 }] } } 
            }
        },
        loadGroup(state, action) {
            for (const [key, value] of Object.entries(action.payload)) {
                state[key] = value;
              }
        },
        editProps(state, action) {
            for (const [key, value] of Object.entries(action.payload)) {
                state[key] = value;
                if(key=="bottom"){
                    state["top"] = value;
                }
            }
        },
        editRange(state,action){
            var data = action.payload;
            state.ranges[data.index][data.prop] = data.value;
        },
        addRange: {
            reducer: (state, action) => {
                var highestValue = parseInt((state.ranges.length > 0 ? state.ranges[state.ranges.length - 1].bottom : -1)) + 1;
                state.ranges.push({ title: action.payload.title, bottom: highestValue, top: highestValue })
            }, 
            prepare: (title) =>{
                return { payload: { title, bottom: null, top: null, index: null, description: null} }
            }
        },
        deleteRange: {
            reducer: (state, action) => {
                state.ranges = state.ranges.filter((val, index)=>{
                    return index != action.payload.index;
                })
            },
            prepare: (index) =>{
                return { payload: { index } }
            }
        }
    }
})

export const { newRange, newGroup, loadGroup, editProps, editRange, addRange, deleteRange } = scoresEditorSlice.actions

export default scoresEditorSlice.reducer

export const getGroup = (id) => (dispatch) =>{
	if(id){
		dispatch(setAppLoadingStarted());
		scoresApi.GetById(id).then(response=>{
			if(!response.error){
				dispatch(loadGroup(response.data));
				dispatch(setAppLoadingFinished());
			} else {
				dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => getGroup(id)));
			}
		}).catch(error => {
			dispatch(setRequestError("Error occured:" + error, () => getGroup(id)));
		});
	} else {
		dispatch(newGroup("Software developer skills"));
	}
}

export const saveGroup = (group, id) => (dispatch) => {
	// if(dispatch(hasError(group)))
	// 	return
	dispatch(setAppLoadingStarted());
	if (!id || id == 0){
        
		scoresApi.Create({ model: group 
		}).then((response) => {
			if(!response.error){
				dispatch(setAppLoadingFinished());
				history.push('/scores');
			} else {
				dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => saveGroup(group, id)));
			}
		}).catch(error=>{
			dispatch(setRequestError("Error occured:" + error, () => saveGroup(group, id)));
		});
	} else {
		scoresApi.Update(id, { model: group 
		}).then((response) => {
			if(!response.error){
				dispatch(setAppLoadingFinished());
				history.push('/scores');
			} else {
				dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => saveGroup(group, id)));
			}
		}).catch(error=>{
			dispatch(setRequestError("Error occured:" + error, () => saveGroup(group, id)));
		});
	}
}