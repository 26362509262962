import * as groupsApi from '../../../api/interview/Groups';
import { isEmail } from '../../../utils/validationHelpers'
import {
    setRequestError,
    setAppLoadingStarted,
    setAppLoadingFinished
} from '../../App/actions'

export const groupsActions = {
    GROUP_LOADED: "GROUP_LOAD",
    MEMBERS_INVITE_EDIT: "MEMBERS_INVITE_EDIT",
    MEMBERS_INVITATION_SENT: "MEMBERS_INVITATION_SENT",
    MEMBERS_USER_DELETED: "MEMBERS_USER_DELETED",
    MEMBERS_INVITE_REJECTED: "MEMBERS_INVITE_REJECTED",
    USER_ROLES_CHANGE: "USER_ROLES_CHANGE"
}
export const editUserRoles =(info) => (dispatch) => {
    dispatch(setAppLoadingStarted());
    groupsApi.UserRoleChange({userId: info.userId, roleIds: info.roleIds, groupId: info.groupId}).then(response =>{
        if(!response.error){
            dispatch({type: groupsActions.USER_ROLES_CHANGE, data: response.data});
            dispatch(setAppLoadingFinished());
        } else{
            dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => editUserRoles(info)));
        }
    }).catch(error => {
        dispatch(setRequestError("Error occured:" + error, () => editUserRoles(info)));
    });
}

export const getGroup = (id) => (dispatch) =>{
    dispatch(setAppLoadingStarted());
    groupsApi.Members(id).then(response=>{
        if(!response.error){
            dispatch({ type: groupsActions.GROUP_LOADED, data: response.data });
            dispatch(setAppLoadingFinished());
        } else {
            dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => getGroup(id)));
        }
    }).catch(error => {
        dispatch(setRequestError("Error occured:" + error, () => getGroup(id)));
    });
}

export const removeUser = (userId, groupId) => (dispatch) =>{
    dispatch(setAppLoadingStarted());
    groupsApi.DeleteUser(groupId, userId).then(response=>{
        if(!response.error){
            dispatch({ type: groupsActions.MEMBERS_USER_DELETED, data: userId });
            dispatch(setAppLoadingFinished());
        } else {
            dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => removeUser(userId, groupId)));
        }
    }).catch(error => {
        dispatch(setRequestError("Error occured:" + error, () => removeUser(userId, groupId)));
    });
}

export const declineInvite = (key) => (dispatch) =>{
    dispatch(setAppLoadingStarted());
    groupsApi.DeclineInvite(key).then(response=>{
        if(!response.error){
            dispatch({ type: groupsActions.MEMBERS_INVITE_REJECTED, data: key });
            dispatch(setAppLoadingFinished());
        } else {
            dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => declineInvite(key)));
        }
    }).catch(error => {
        dispatch(setRequestError("Error occured:" + error, () => declineInvite(key)));
    });
}

export const sendInvite = (email, roleId, groupId) => (dispatch) => {
    if(email != null && email.length > 0 && isEmail(email)){
        dispatch(setAppLoadingStarted());
        groupsApi.Invite(email, roleId, groupId).then(response =>{
            if(!response.error){
                dispatch(setAppLoadingFinished());
                dispatch({ type: groupsActions.MEMBERS_INVITATION_SENT, data: {
                    email: email,
                    roleId: roleId
                }})
            } else {
                dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => sendInvite(email, roleId, groupId)));
            }
        }).catch(error =>{
            dispatch(setRequestError("Error occured:" + error, () => sendInvite(email, roleId, groupId)));
        });
    } else {
        dispatch({ type: groupsActions.MEMBERS_INVITE_EDIT, data: { emailValid: false } })
    }
}
export const editInviteEmail = (text) => (dispatch) => dispatch({ type: groupsActions.MEMBERS_INVITE_EDIT, data: { inviteEmail: text} })
export const editInviteRole = (roleId) => (dispatch) => dispatch({ type: groupsActions.MEMBERS_INVITE_EDIT, data: { inviteRoleId: roleId } })