import { initialState as storeInitialState } from './reducer'
import { setRequestError, } from '../../App/actions'
import * as interviewsApi from '../../../api/interview/Interview'
import {assignInterviewRules} from './validate'
import {validateObject} from '../../../utils/helpers'
import * as candidateInteviewsListActions from '../actions'
import * as profileSelectActions from '../../../common/components/ProfileSelect/actions'

export const assignInterviewActions = {
    ASSIGN_INTERVIEW_SET_DATA: 'ASSIGN_INTERVIEW_SET_DATA',
    ASSIGN_INTERVIEW_SET_ERRORS: 'ASSIGN_INTERVIEW_SET_ERRORS'
};

export const setAssignInterviewData = (data) => ({ type: assignInterviewActions.ASSIGN_INTERVIEW_SET_DATA, data });
export const setAssignInterviewErrors = (errors) => ({ type: assignInterviewActions.ASSIGN_INTERVIEW_SET_ERRORS, errors })


export const assignInterviewSave = (candidateId) => (dispatch,getState) => {
    dispatch(setAssignInterviewData({ isLoading: true }));
    const state = getState().assignInterviewComponent;

    let validationResult = validateObject(assignInterviewRules,state);
    if(!validationResult.isValid) {
        dispatch(setAssignInterviewErrors(validationResult.errors));
        return;
    }

    interviewsApi.AssignInterview(state.selectedProfile.value,candidateId,state.selectedProfile.versionId,state.interviewName)
        .then(response => {
            if (!response.error) {
                const responseBody = response.data;
                const { data } = responseBody;
                dispatch(setAssignInterviewData(storeInitialState));
                dispatch(profileSelectActions.profileSelectSetData({
                    selected:storeInitialState.selectedProfile
                }))
                dispatch(candidateInteviewsListActions.getInterviews(candidateId))
            } else {
                if(response.raw.status == 400) {
                    const errors = {...response.data.body.error.errors };
                    if(errors.hasOwnProperty('profileId') || errors.hasOwnProperty('profileVersionId')) {
                        errors['selectedProfile'] = 'Profile is required';
                    }
                    dispatch(setAssignInterviewErrors(errors));
                } else {
                    dispatch(setRequestError(response.data.body.error.generalErrorMessage, 
                        () => assignInterviewSave(candidateId)));
                }

                dispatch(setAssignInterviewData({ isLoading: false }));
            }
        })
        .catch(error => {
            dispatch(setRequestError("Error occured:" + error, 
                    () => assignInterviewSave(candidateId)));
            dispatch(setAssignInterviewData({ isLoading: false }));
        });
}