import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { getInterview } from './actions';
// import { Timeline, TimelineEvent } from 'react-event-timeline';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import { Typography, Grid, Icon } from '@mui/material';
import { bindActionCreators } from 'redux';
import * as InterviewTimeLineActions from './actions';
import withStyles from '@mui/styles/withStyles';
import Moment from 'react-moment';
import moment from 'moment';
import { mapTreeResponsesToFlatArray } from "../../utils/helpers"
import  InterviewHeader from '../InterviewReview/InterviewHeader';


const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    paddings: {
        maringTop: '5px',
        marginBottom: '5px'
    },
    timeValueSeparator: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
});

class InterviewTimeLine extends React.Component {
    constructor(props) {
        super(props);
        this.props.getInterview(this.props.resultId);
    }
    render() {
        window.bMoment = moment;
        const { classes } = this.props;
        mapTreeResponsesToFlatArray(this.props.responses, this.props.flatSortedResponses);
        var data = this.props.flatSortedResponses.sort(function (left, right) {
            return moment.utc(left.scoreTime).diff(moment.utc(right.scoreTime))
        }).filter(x=>!x.notAsked);
        var firstAnswerTime = data.length > 0 ? data[0].scoreTime : new Date();
        const rows = data
            .map(item => {
                return (
                    <TimelineItem
                        key={item.id}
                        // title={
                        // }
                        // createdAt={
                        //     <Grid style={{display:"flex"}}>
                        //         <Typography variant="h6" gutterBottom noWrap>
                        //             {/* <Moment duration={firstAnswerTime} date={item.scoreTime}>
                        //             </Moment>
                        //             <Moment unit="minutes" diff={item.scoreTime}>{firstAnswerTime}</Moment> */}
                        //             <span>
                        //                 {timeOffset(firstAnswerTime,item.scoreTime)}
                        //             </span>
                        //             <span className={classes.timeValueSeparator}>
                        //                 -
                        //             </span>
                        //             <span>
                        //                 {item.name} - {item.scoreRange ? item.scoreRange + " (" + item.score + "%)" : item.score + "%"}
                        //             </span>
                        //         </Typography>
                        //     </Grid>
                        // }
                        // icon={eventIcon(item.notAsked)}
                        // iconColor={item.notAsked ? "#FF0000" : "#6fba1c"}
                    >
                        {/* {item.description != null &&
                            <Typography component="p">
                                Description: {item.description}
                            </Typography>
                        } */}
                        {item.expertComment != null &&
                            <Typography component="p">
                                Comment: {item.expertComment}
                            </Typography>
                        }
                    </TimelineItem>
                )
            });
        return (
            <div>
                <InterviewHeader {...this.props} />
                <br />
                <Timeline>
                    {rows}
                    <TimelineItem
                        // createdAt={
                        //     <Grid style={{display:"flex"}}>
                        //         <Typography variant="h6" gutterBottom noWrap>
                        //             End
                        //         </Typography>
                        //     </Grid>
                        // }
                        // iconColor={"#000"}
                    >
                    </TimelineItem>
                </Timeline>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.actions.setInterviewTimeLineLoaded({
            levelName: null, profileName: '', levelScore: null, summary: '', title: '', resultId: null, profileVersion: 0, responses: [], flatSortedResponses: []
        })
    }
}


const eventIcon = (eventScore) => {
    if (eventScore) {
        return (
            <i className="material-icons">
                clear
        </i>
        )
    }
    else {
        return (
            <i className="material-icons">
                done
        </i>
        )
    }
}

const timeOffset = (startTime, endTime) => {
    return msToTime(moment.utc(endTime).diff(moment.utc(startTime)));
}

const msToTime = (s) =>  {
    const formatValue = function(value){
        return value > 9 ? value.toString() : '0' + value;
    }
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;
    if(hrs > 0){
        return formatValue(hrs) + ':' + formatValue(mins) + ':' + formatValue(secs);
    } else {
        return formatValue(mins) + ':' + formatValue(secs);
    }
  }

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(InterviewTimeLineActions, dispatch),
        getInterview: id => dispatch(getInterview(id))
    };
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.interviewTimeLineComponent,
        resultId: ownProps.match.params.id || state.profileTimeLineComponent.resultId
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InterviewTimeLine)));

InterviewTimeLine.propTypes = {
    actions: PropTypes.object,
    profileName: PropTypes.string,
    completedDate: PropTypes.string,
    levelName: PropTypes.string,
    levelScore: PropTypes.number,
    profileVersion: PropTypes.number,
    summary: PropTypes.string,
    title: PropTypes.string,
    responses: PropTypes.array,
    resultId: PropTypes.string,
    getInterview: PropTypes.func,
    classes: PropTypes.any,
    flatSortedResponses: PropTypes.array
}