import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { getColor } from '../../../utils/colorPicker'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
	// style rule
	scoreButton: {
		color: "black",
		borderColor: "black"
	},
	deselected:{
		backgroundColor: "#ccc"
	}
  });

const RangeSelector = (props) => {
	
	const range = props.ranges.find(x=>x.bottom<= props.selectedScore && x.top >= props.selectedScore) ?? { id: 0 };
	const selectedIndex = props.ranges.findIndex(x=>x.id == range.id);
	const selectedColor = getColor(selectedIndex, props.ranges.length);
	const classes = useStyles({ selectedColor: selectedColor });
	const buttons = props.ranges.map((r, index)=>{
		return <Button 
					disabled={props.disabled}
					key={`rangeButton-${r.id}`} 
					sx={{
						backgroundColor: r.id == range.id ? selectedColor : "default",
						color: r.id == range.id ? "black" : "default",
						borderColor: r.id == range.id ? "black" : "default"
					}}
					// className={classes.levelItem}
					variant="outlined"
					className={classes.scoreButton}
					onClick={(event) => props.onChangeLevel(event, r.id) }
					>
					{r.title}
				</Button>;
	});
	return (
		<div>
			<Button disabled={props.disabled} className={range.id == 0 ? classes.deselected : "" } variant="outlined" onClick={(event) => props.onChangeLevel(event, null) }>N/A</Button>
			{buttons}
		</div>
	);
}

export default RangeSelector;

RangeSelector.propTypes = {
	ranges: PropTypes.array,
	selectedScore: PropTypes.number,
	onChangeLevel: PropTypes.func
};