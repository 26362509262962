import './style.scss'
import React from 'react'
import { connect } from 'react-redux'
import * as CandidateViewActions from './actions'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Grid, Avatar, Typography, Paper, Divider,Accordion, AccordionSummary,AccordionDetails,AccordionActions, Icon } from '@mui/material'
import withStyles from '@mui/styles/withStyles';
import { stringToHslColor } from '../../utils/helpers'
import { initialState } from './reducer'
import CandidateInterviewsComponent from '../CandidateInterviewsComponent'
import AssignInterview from '../CandidateInterviewsComponent/AssignInterview';


const styles = theme => ({
    avatar: {
        width: 70,
        height: 70,
        fontSize: 26
    },
    candidateName: {
        textAlign: 'center'
    },
    paperTitle: {
        fontWeight: 600
    },
    paperBlock: {
        padding: '0 10px 0 10px'
    },
    paperBlockContainer: {
        margin: '0 0 20px 0'
    },
    root: {
        padding: '10px'
    },
    icons: {
        marginRight: 10
    }
});

class CandidateView extends React.PureComponent {
    constructor(props) {
        super(props);

        this.props.actions.getCandidate(this.props.id);
    }
    getAvatarInfo = () => {
        const firstNameLetter = this.props.name[0].toUpperCase();
        const firstLastNameLetter = this.props.lastName[0].toUpperCase();
        const text = `${firstNameLetter}${firstLastNameLetter}`;
        const color = stringToHslColor(text, 80, 60);
        return {
            text,
            color
        };
    }
    preventBubbling = (e) => {
        e.stopPropagation();
        return false;
    }
    componentWillUnmount() {
        this.props.actions.setCandidateData(initialState.candidateData);
    }
    render() {
        const { classes } = this.props;
        if (!this.props.name || this.props.name.trim() === '') {
            return (
                <React.Fragment />
            )
        }
        const avatarInfo = this.getAvatarInfo();
        return (
            <Grid container className={classes.root}>
                <Grid item xs={12} sm={3}>
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                        <Avatar className={classes.avatar} style={{ backgroundColor: avatarInfo.color }}>{avatarInfo.text}</Avatar>
                        <Typography variant="h6" gutterBottom className={classes.candidateName}>
                            <i className={`fas fa-user-graduate ${classes.icons}`} />{`${this.props.name} ${this.props.lastName}`}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Grid item xs={12} className={classes.paperBlockContainer}>
                        <Paper square elevation={4} className={classes.paperBlock}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <i className={`far fa-address-card ${classes.icons}`} />
                                        <span className={classes.paperTitle}>Contact Information</span>
                                        <Divider />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" gutterBottom className={classes.details}>
                                        <i className={`fas fa-envelope ${classes.icons}`} />Email: <a href={`mailto:${this.props.email}`} onClick={this.preventBubbling}>{this.props.email}</a>
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.details}>
                                        <i className={`fab fa-skype ${classes.icons}`} />Skype: <a href={`skype:${this.props.skype}?userinfo`} onClick={this.preventBubbling}>{this.props.skype}</a>
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom className={classes.details}>
                                        <i className={`fas fa-phone ${classes.icons}`} />Phone: <a href={`callto://+${this.props.phone}`} onClick={this.preventBubbling}>{this.props.phone}</a>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} className={classes.paperBlockContainer}>
                        <Paper square elevation={4} className={classes.paperBlock}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <i className={`fas fa-info ${classes.icons}`} />
                                        <span className={classes.paperTitle}>Interviews</span>
                                        <Divider />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <CandidateInterviewsComponent candidateId={this.props.id}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Accordion>
                                        <AccordionSummary expandIcon={<Icon>check</Icon>}>
                                            <Typography className={classes.heading}>Assign Interview</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <AssignInterview candidateId={this.props.id} />
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                </Grid>
            </Grid>
        );
    }
}

CandidateView.propTypes = {
    name: PropTypes.string,
    id: PropTypes.number,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    skype: PropTypes.string,
    classes: PropTypes.any,
    actions: PropTypes.any
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.candidateView,
        id: parseInt(ownProps.match.params.id || state.candidateEditor.id)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(CandidateViewActions, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CandidateView));

