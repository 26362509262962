import { profileEditorActions } from './actions';
import {ProfileVersionStates} from '../../utils/globalConstants';
import TargetLevel from './AreaConfigEditors/TargetLevel';

export const initialState = {
    areas: [],
    profileName: "",
    profileId: 0,
    versionId: 0,
    versionState:ProfileVersionStates.Draft,
    focusRow: null,
    focusColumn: 0,
    profileScoreInfo: { availableGroups: [] },
    targetLevel: { levels: [] }
};


export const profileEditor = (state = initialState, action) => {
    switch (action.type) {
        case profileEditorActions.PROFILE_RESET:
            return Object.assign({}, initialState);
        case profileEditorActions.PROFILE_AREA_CHANGE_TARGET:
            return Object.assign({}, state, {
                areas: [...state.areas.map(function (ar) {
                    if (ar.id == action.payload.areaId) {
                        var tc = action.payload;

                        var item = Object.assign({}, {
                            levelId: tc.levelId,
                            minRangeId: parseInt(tc.minRangeId),
                            isRequired: tc.isRequired
                        });
                        if((ar.targetConfigs ?? []).find(x=>x.levelId == tc.levelId)){
                            if(tc.minRangeId == null){
                                return Object.assign({}, {...ar}, { targetConfigs: [...ar.targetConfigs.filter(x=> x.levelId != tc.levelId).map(x=> Object.assign({}, {...x}))] });
                            } else {
                                return Object.assign({}, {...ar}, { targetConfigs: [...ar.targetConfigs.map(x=> {
                                    if (x.levelId == tc.levelId){
                                        return Object.assign({}, {
                                            levelId: tc.levelId,
                                            minRangeId: parseInt(tc.minRangeId),
                                            isRequired: tc.isRequired
                                        });
                                    } else {
                                        return Object.assign({}, {...x})
                                    }
                                })] });
                            }                            
                        } else {
                            var item = Object.assign({}, {
                                levelId: tc.levelId,
                                minRangeId: parseInt(tc.minRangeId),
                                isRequired: tc.isRequired
                            });
                            return Object.assign({}, {...ar}, { targetConfigs: [...(ar.targetConfigs ?? []).map(x=> Object.assign({}, {...x})), item] });
                        }
                        
                        // var targetsNew = 
                        // var result = null;
                        // if (tc.minRangeId != null){
                        //     var item = 
                        //     result = [...targetsNew, item];
                        // } else {
                        //     result = [...targetsNew];
                        // }
                        // var result = result.map(x=> Object.assign({}, {...x}))
                        // return Object.assign({}, {...ar}, { targetConfigs: [...result] });
                    } else {
                        return ar;
                    }
                })]
            });
        case profileEditorActions.PROFILE_SELECT_AREA:
            return Object.assign({}, state, {
                areas: [...state.areas.map(function (ar) {
                    if (ar.id == action.payload.id) {
                        
                        return Object.assign({}, ar, { selected: true });
                    } else {
                        return Object.assign({}, ar, { selected: false });
                    }
                    
                })]
            });
        case profileEditorActions.PROFILE_AREA_SET_SCORE_CONFIG:
            return Object.assign({}, state, {
                areas: [...state.areas.map(function (ar) {
                    if (ar.id == action.data.areaId) {
                       
                        return Object.assign({}, ar, { scoreConfigId: action.data.scoreConfigId });
                    }
                    return ar;
                })]
            });
        case profileEditorActions.PROFILE_ADD_AREA:
            return Object.assign({}, state, {
                areas: [...state.areas.map(ar => {
                    if (ar.order >= action.area.order) {
                        return Object.assign({}, ar, { order: ar.order + 1 });
                    }
                    return ar;
                }), action.area], focusRow: action.area.order, focusColumn:0
            });

        case profileEditorActions.PROFILE_SET:
            return Object.assign({}, state, action.data);

        case profileEditorActions.PROFILE_REMOVE_AREAS:
            return Object.assign({}, state, { areas: state.areas.filter(el => action.ids.indexOf(el.id) == -1).map(ar =>
                {
                    if(action.orderMap[ar.id] != undefined) {
                        return Object.assign({}, ar, {order: action.orderMap[ar.id]})
                    }
                    return ar;
                }) });

        case profileEditorActions.PROFILE_EDIT_AREA:
            return Object.assign({}, state, {
                areas: state.areas.map(function (ar) {
                    if (ar.id == action.id) {
                        ar = Object.assign({}, ar, action.data);
                    }
                    return ar;
                })
            });

        case profileEditorActions.PROFILE_EDITOR_SET_FOCUS_ROW:
            return Object.assign({}, state, { focusRow: action.order });
            
        case profileEditorActions.PROFILE_EDITOR_SET_FOCUS_COLUMN:
            return Object.assign({}, state, { focusColumn: action.column });

        case profileEditorActions.PROFILE_EDITOR_CHANGE_POSITION_AREA:
            return Object.assign({}, state, {
                areas: state.areas.map(ar =>{
                    if(ar.id == action.id) {
                        return Object.assign({}, ar, { parentId: action.newParent, order: action.newOrder });
                    } else if(action.orderMap[ar.id] != undefined) {
                        return Object.assign({}, ar, {order: action.orderMap[ar.id]})
                    }
                    return ar;
                })
            });
        case profileEditorActions.PROFILE_EDITOR_GROUPS_LOADED:
            return Object.assign({}, state, { profileScoreInfo: action.data });
        case profileEditorActions.PROFILE_EDITOR_GROUP_ASSIGNED:
            var newAssignment = Object.assign({}, state.profileScoreInfo, action.data);
            return Object.assign({}, state, { profileScoreInfo: newAssignment });
        default:
            return state;
    }
};