import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { TextField } from '@mui/material';
import ScoresSlider from './ScoresSlider';
import RangeSelector from './RangeSelector';

const styles = theme => ({
	// root: {
	// 	maxWidth: 500,
	// },
	comment: {
		flexGrow: 1,
		width: "100%"
	}
});

const useStyles = makeStyles({
		// root: {
		// 	maxWidth: 500,
		// },
		comment: {
			flexGrow: 1,
			width: "100%"
		}
	})



export default  ({ ranges, isSaving, currentScore, comment, onCommentChange, onScoreChange }) =>{
	const  classes = useStyles();

	const changeLevel = (event, rangeId) => {
		var range = ranges.find(x => x.id == rangeId);
		if(range){
			onScoreChange(range.bottom, range.title);
		} else {
			onScoreChange(-1, "");
		}
	}

	const [ text, setText ] = useState(comment);
	// this is hack for comment control, I really don't understand why it's not setting text all the time
	useEffect( () => {
		setText(comment);
	}, [comment])

	
	
	

	return <>
        <RangeSelector disabled={isSaving} ranges={ranges} selectedScore={currentScore} onChangeLevel={(event, rangeId) => changeLevel(event, rangeId)} />
        <TextField
            disabled={isSaving}
            label="Comment"
            multiline
            maxRows="4"
            rows="4"
            value={text}
            onChange={(event)=>{
                setText(event.target.value);
            }}
            onKeyDown={(event) => { 
                if(event.key == "Escape"){
                    event.target.value = comment;
                }
                }}
            onBlur={(event) => { 
                if(onCommentChange && comment != event.target.value){
                    onCommentChange(event.target.value)
                }
            }}
            className={classes.comment}
            margin="normal"
            variant="outlined"
        />
    </>;
}


// class ScoreSelector extends React.Component {

// 	constructor(props) {
// 		super(props);

// 		this.state = {
// 			comment: this.props.comment
// 		}
// 	}

// 	changeLevel = (event, rangeId) => {
// 		var range = this.props.ranges.find(x => x.id == rangeId);
// 		if(range){
// 			this.props.onScoreChange(range.bottom, range.title);
// 		} else {
// 			this.props.onScoreChange(-1, "");
// 		}

// 	}

// 	scoreChange = (value, title) => {
// 		this.props.onScoreChange(value, title);
// 	};

// 	render() {
// 		const { classes } = this.props;

// 		return (
// 			<div className={classes.root}>
// 				<RangeSelector disabled={this.props.isSaving} ranges={this.props.ranges} selectedScore={this.props.currentScore} onChangeLevel={this.changeLevel} />
// 				{this.props.enableSlider ?
// 					<ScoresSlider onChange={this.scoreChange} value={this.props.currentScore ? this.props.currentScore : 0} />
// 					: <React.Fragment />}
// 				{this.props.enableComment ?
// 					<TextField
// 					    disabled={this.props.isSaving}
// 						label="Comment"
// 						multiline
// 						rowsMax="4"
// 						rows="4"
// 						defaultValue={this.props.comment}
// 						onKeyDown={(event) => { 
// 							if(event.key == "Escape"){
// 								event.target.value = this.state.comment;
// 							}
// 						 }}
// 						onBlur={(event) => { 
// 							if(this.props.onCommentChange){
// 								this.props.onCommentChange(event.target.value)
// 							}
// 						}}
// 						className={classes.comment}
// 						margin="normal"
// 						variant="outlined"
// 					/>
// 					:
// 					<React.Fragment />}

// 			</div>
// 		);
// 	}
// }

// export default withStyles(styles, { withTheme: true })(ScoreSelector);

// ScoreSelector.propTypes = {
// 	classes: PropTypes.object.isRequired,
// 	currentScore: PropTypes.number,
// 	ranges: PropTypes.array,
// 	comment: PropTypes.string,
// 	enableSlider: PropTypes.bool,
// 	onScoreChange: PropTypes.func,
// 	onCommentChange: PropTypes.func,
// 	enableComment: PropTypes.bool
// };