import React, { Component } from 'react'
// import FacebookLogin from 'react-facebook-login'
import FormLogin from './FormLogin/FormLogin.jsx'
import authService from '../../utils/authService.js'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Grid, Paper, Typography, Divider, Button, Icon } from '@mui/material'
import { withRouter } from 'react-router';
import * as accountApi from '../../api/interview/Account'
import withStyles from '@mui/styles/withStyles';
import FacebookIcon from '@mui/icons-material/Facebook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as loginActions from '../../common/actions/accountActions'
import { bindActionCreators } from 'redux'
import history from '../../utils/history'
// import {  } from '@fortawesome/free-solid-svg-icons'

const styles = theme => ({
    descriptionSection: {
        textAlign: "center",
        marginTop: theme.spacing(1),
        
    },
    pageWrapper: {
        padding: theme.spacing(2),
        maxWidth: 900,
        margin: "0 auto",
        marginTop: "7%"
    },
    descriptionSide: {
        borderRight: "1px solid #ccc"
        // borderRight: "1px solid #333"
    },
    loginSide:{
        paddingLeft: theme.spacing(2),
        textAlign: "center"
    },
    divider: {
        marginTop: theme.spacing(1)
    },
    orDivider: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    continueEmail: {
        width: 250
    },
    facebookLogin:{
        width: 250
    },
    continueWithFacebook:{
        marginTop: theme.spacing(1),
        width: 250,
        backgroundColor: "#1877f2",
        '&:hover' :{
            backgroundColor: "#1877f2",
        }
    }
})

class Login extends Component {
    constructor(props) {
        super(props);

        if(this.props.isLoggedIn && window.location.search.indexOf("redirectTo") > 0){
            history.push(window.location.search.replace("?redirectTo=", ""));
        }
    }

    responseFacebook(response) {
        var me = this;
        if (response && response.authResponse && response.authResponse.accessToken) {
            accountApi.FacebookLogin({
                "accessToken": response.authResponse.accessToken
            }).then(function (response) {
                me.props.loginActions.fbloginConfirmation(response)
                /* success callback */
                // authService.setJwt(response.data.data.token);
            }).catch(function (error) {
                /* error callback */
            });
        }
    }

    continueWithFacebookClick =() =>{
        // FB.login((fbresponse)=> { this.responseFacebook(fbresponse) }, { scope: 'public_profile,email' });
    }

    render() {
        const classes = this.props.classes;
        window.responseFacebook = this.responseFacebook;
        return (
            <React.Fragment>
                <Paper className={classes.pageWrapper}>
                    <Grid container>
                        <Grid item xs={6} className={classes.descriptionSide} >
                            <Typography variant="h5" gutterBottom className={classes.descriptionSection}>
                                Interview App
                            </Typography>
                            <ul>
                                <li><Typography variant="subtitle1">Define interview topics</Typography></li>
                                <li><Typography variant="subtitle1">Interview candidates</Typography></li>
                                <li><Typography variant="subtitle1">Analyze</Typography></li>
                                <li><Typography variant="subtitle1">Provide feedback</Typography></li>
                                <li><Typography variant="subtitle1">Decide</Typography></li>
                            </ul>
                        </Grid>
                        <Grid item xs={6} className={classes.loginSide}>
                            <FormLogin history={this.props.history} />

                            {/* <Divider className={classes.divider} /> */}
                            <Grid>
                                <Typography variant="subtitle1" className={classes.orDivider}>
                                    OR
                                </Typography>
                            </Grid>
                            <Grid>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.continueEmail}
                                    startIcon={<Icon>email</Icon>}
                                    onClick={() => this.props.history.push("/register")}
                                >
                                    Continue with Email
                                </Button>
                            </Grid>
                            {/* <Grid>
                                <Button 
                                    variant="contained"
                                    color="primary"
                                    className={classes.continueWithFacebook}
                                    startIcon={<i className="fab fa-facebook"></i>}
                                    onClick={this.continueWithFacebookClick}
                                >
                                    Continue with Facebook
                                </Button>
                            </Grid> */}
                            
                            
                            {/* <Button style={{marginLeft: "10px" }} variant="contained" color="primary" onClick={() => this.props.history.push("/register")}>
                                Continue with Email
                            </Button> */}
                            {/* <FacebookLogin
                                buttonStyle={{
                                    marginTop: 10,
                                }}
                                appId="105006186968957"
                                autoLoad={false}
                                fields="name,email,picture"
                                callback={this.responseFacebook} /> */}
                                {/* { const b =  } */}

                                <Grid className={classes.facebookLogin}>
                                    {/* <div data-onlogin="window.responseFacebook" className="fb-login-button" data-size="large" 
                                    data-button-type="continue_with" data-layout="rounded" data-auto-logout-link="false" data-use-continue-as="false" data-width=""
                                    data-scope="public_profile,email"></div> */}
                                    {/* <FacebookLogin
                                        appId="105006186968957"
                                        autoLoad={false}
                                        fields="name,email,picture"
                                        // onClick={componentClicked}
                                        callback={responseFacebook} 
                                        icon={<FacebookIcon></FacebookIcon>}
                                        /> */}
                                </Grid>
                                
                        </Grid>
                    </Grid>
                </Paper>
            </React.Fragment>
        );
    }
}

Login.propTypes = {
    history: PropTypes.object,
    classes: PropTypes.object,
    loginActions: PropTypes.object
}

const mapDispatchToProps = (dispatch) => {
	return {
		loginActions: bindActionCreators(loginActions, dispatch)
	};
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.account.isLoggedIn
    };
};

export default (withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))))