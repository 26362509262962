import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

/*
    Show/hide component in DOM dependent on roles
*/
class SecuredComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    evaluatePermissions(roles) {
        if (roles) {
            //todo: get role of real user
            let userRole = "Admin";

            return roles.indexOf(userRole) > 0;
        }
        else {
            return this.props.isLoggedIn;
        }
    }

    render() {
        if (this.evaluatePermissions(this.props.roles)) {
            return React.Children.only(this.props.children);
        }

        return null;
    }
}

SecuredComponent.propTypes = {
    children: PropTypes.node,
    roles: PropTypes.array
}

export default connect()(SecuredComponent);