import { setRequestError, setAppLoadingStarted, setAppLoadingFinished } from '../App/actions'
import * as interviewApi from '../../api/interview/Interview';
import history from '../../utils/history'

export const preInteviewActions = {
    PREINTERVIEW_DATA_SET: 'PREINTERVIEW_DATA_SET',
    PREINTERVIEW_EDIT_AREA: 'PREINTERVIEW_EDIT_AREA',
    PREINTERVIEW_SET_FOCUS_ROW : 'INTERVIEW_SET_FOCUS_ROW',
    PREINTERVIEW_SET_FOCUS_COLUMN : 'INTERVIEW_SET_FOCUS_COLUMN'
}

export const setInterviewData = (data) => ({ type: preInteviewActions.PREINTERVIEW_DATA_SET, data })


export const setFocusRow = (rowOrder) => (dispatch,getState) => {
    if(rowOrder>=0 && rowOrder<getState().preInterviewComponent.areas.length) {
        dispatch({ type: preInteviewActions.INTERVIEW_SET_FOCUS_ROW, order: rowOrder });
    } else if(rowOrder == null) {
        dispatch({ type: preInteviewActions.INTERVIEW_SET_FOCUS_ROW, order: rowOrder });
    }
}

export const setFocusColumn = (column) => (dispatch) => {
    const columns = ['title','score'];
    if(column>=0 && column<columns.length) {
        dispatch({ type: preInteviewActions.PREINTERVIEW_SET_FOCUS_COLUMN, column });
    } else if(column == null) {
        dispatch({ type: preInteviewActions.PREINTERVIEW_SET_FOCUS_COLUMN, column });
    }
}

export const editInterviewArea = (id, data) => ({ type: preInteviewActions.PREINTERVIEW_EDIT_AREA, id, data })


export const setScore = (interviewId,areaId,areaData)  => (dispatch) => {
    return interviewApi.SetPreinterviewScore({
        model: {
            interviewId:interviewId,
            score: areaData.notAsked ? null : areaData.score,
            areaId: areaId,
            comment: null,
            fromCandidate: true
        }
    }).then(response => {
        if (!response.error) {
            dispatch(editInterviewArea(areaId,areaData));
            dispatch(setAppLoadingFinished());
            return true;

        } else {
            dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => setScore(interviewId,areaId,areaData)));
            return false;
        }

    }).catch(error => {
        dispatch(setRequestError("Error occured:" + error, () => setScore(interviewId,areaId,areaData)));
        return false;
    });
}

export const submit = (invitationKey) => (dispatch) => {
    interviewApi.SubmitPreInterview({invitationKey})
        .then(response => {
            const responseBody = response.data;
            if (!response.error) {
                dispatch(setAppLoadingFinished());
                history.push('/');
            } else {
                dispatch(setRequestError(responseBody.body.error.generalErrorMessage, () => submit(invitationKey)));
            }
        })
        .catch(error => {
            dispatch(setRequestError("Error occured:" + error, () => submit(invitationKey)));
        })
}

export const getInterview = (invitationKey) => (dispatch) => {
    if (!invitationKey) {
        history.push('/');
        return;
    }
    dispatch(setAppLoadingStarted());
    interviewApi.GetPreInterview({ invitationKey })
        .then(response => {
            const responseBody = response.data;
            if (!response.error) {
                const { data } = responseBody;
                dispatch(setInterviewData({
                    id: data.id,
                    versionId: data.versionId,
                    profileId: data.id,
                    profileName: data.profileName,
                    interviewName: data.name,
                    areas: data.areas,
                    scoresGroup: data.scoresGroup,
                }));
                dispatch(setAppLoadingFinished());
            } else {
                dispatch(setRequestError(responseBody.body.error.generalErrorMessage, () => getInterview(invitationKey)));
            }
        })
        .catch(error => {
            dispatch(setRequestError("Error occured:" + error, () => getInterview(invitationKey)));
        });
};