import './App.scss'

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setAppLoadingFinished } from './actions';
import { autoLogin } from "../../common/actions/accountActions";
import {  Grid, Paper, CssBaseline} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import AppDialog from '../../common/components/AppDialog'


import Loader from '../Loader'
import Header from '../Header';
import Home from '../Home';
import ManageProfiles from '../ManageProfiles';
import ManageInterviews from '../ManageInterviews';
import NotFound from '../NotFound';
import Login from '../Login';
import ProfileEditor from '../ProfileEditor';
import Register from '../Register';
import Interview from "../Interview";
import InterviewTimeLine from "../InterviewTimeLine";
import InterviewsCompare from "../InterviewsCompare";
import InterviewReview from "../InterviewReview";
import ManageCandidates from '../ManageCandidates';
import CandidateEditor from '../CandidateEditor'
import ScoresGroup from '../Scores';
import GroupEditor from '../ScoresEditor/GroupEditor';
import ScoresEditor from '../ScoresEditor'
import CandidateView from '../CandidateView';
import ComponentsLab from '../ComponentsLab';
import GroupsList from '../Groups/Main';
import GroupMembers from '../Groups/Members'
import PreInterview from '../PreInterview';
import Roles from '../Roles';
import AuthRoute from '../../common/components/Auth/AuthRoute'
import ScoresGroups from '../ScoresGroups'
import backgroundImg from '../../images/blank-business-composition-computer-373076.png'

const styles = theme => ({
    error: {
        backgroundColor: theme.palette.error.dark,
        padding: '0'
    },
    dialog: {
        padding: '0 0 10px 0'
    },
    mainContainer: {
        // maxWidth: 1600,
        // width:'90%',
        margin: '0 auto',
        padding: '10px',
        // background: 'url(' + logo + ')'
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    styleBackground: {
        backgroundImage: 'url(' + backgroundImg + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: "100%"
    }
})

class App extends React.Component {
    constructor(props) {
        super(props);

        this.handleErrorDialogClose = this.handleErrorDialogClose.bind(this);
        this.handleRetry = this.handleRetry.bind(this);
    }

    componentDidMount() {
        //try to authorize user in system on app start 
        //using jwt that stored in local storage
        if(this.props.location != "/preinterview")
            this.props.autoLogin();
    }

    handleErrorDialogClose() {
        this.props.setLoadingFinished();
    }
    handleRetry() {
        if (this.props.retryAction && typeof (this.props.retryAction) == 'function') {
            this.props.execute(this.props.retryAction);
        } else {
            window.location.reload();
        }
    }
    render() {
        const { classes } = this.props;
        const backClassname = window.location.pathname == "/login".toLocaleLowerCase() ? classes.styleBackground : '';
        // return <Switch>
        //     <Route path="/login">
        //                             <Login />
        //                         </Route>
        //                         <Route path="/register" component={Register} />
        //                         <AuthRoute exact path='/profiles'>
        //                             <ManageProfiles />
        //                         </AuthRoute>
        // </Switch>
        return (
            <div className={backClassname}>
                <CssBaseline />
                <Header />
                <Grid container>
                    <Loader show={this.props.loading} />
                    <Grid item xs={12}>
                        <Grid className={classes.mainContainer}>

                            {/* <div className="app-logo-container">
                                <div className="app-logo">
                                </div>
                            </div> */}
                            
                            <AppDialog
                                open={this.props.showErrorDialog}
                                onClose={this.handleErrorDialogClose}
                                title={'Error occured during server request..'}
                                dialogType='error'
                                message={this.props.requestError}
                                closeText='Close'
                                actionText='Retry'
                                handleCloseClick={this.handleErrorDialogClose}
                                handleActionClick={this.handleRetry}
                            />
                            <Switch>
                                <AuthRoute exact path='/' component={Home} />
                                {/* <Route exact path='/' component={Home} /> */}
                                <Route path="/login">
                                    <Login />
                                </Route>
                                <Route path="/register" component={Register} />
                                <AuthRoute exact path='/profiles'>
                                    <ManageProfiles />
                                </AuthRoute>
                                <AuthRoute path='/profiles/create' component={ProfileEditor} />
                                <AuthRoute path='/profiles/edit/:id?' component={ProfileEditor} />
                                <AuthRoute path='/profiles/clone/:id?' component={ProfileEditor} />
                                <AuthRoute path='/interview/:id?' component={Interview} />
                                <AuthRoute path='/interviews/timeline/:id?' component={InterviewTimeLine} />
                                <AuthRoute exact path='/interviews' component={ManageInterviews} />
                                <AuthRoute path='/interviews/compare/:id?' component={InterviewsCompare} />
                                <AuthRoute path='/interviews/review/:id?' component={InterviewReview} />
                                <AuthRoute exact path='/candidates' component={ManageCandidates}/>
                                <AuthRoute path='/candidates/create' component={CandidateEditor} />
                                <AuthRoute path='/candidates/edit/:id?' component={CandidateEditor} />
                                <AuthRoute path='/candidates/view/:id?' component={CandidateView} />
                                <AuthRoute exact path='/scores' component={ScoresGroup} />
                                <AuthRoute path='/scores/create' component={ScoresEditor} />
                                <AuthRoute path='/scores/:id?' component={ScoresEditor} />
                                <AuthRoute path='/scores/edit/:id?' component={GroupEditor} />
                                <AuthRoute path='/componentslab' component={ComponentsLab} />
                                <AuthRoute path='/groups/:id/members' component={GroupMembers} />
                                <AuthRoute path='/groups' component={GroupsList} />
                                <Route path='/preinterview' component={PreInterview}/>
                                <AuthRoute path='/roles/:id?' component={Roles} />
                                <AuthRoute path='/roles' component={Roles} />
                                <AuthRoute path='/scoresdetails' component={ScoresGroups} />
                                <Route component={NotFound} />
                            </Switch>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

App.propTypes = {
    loading: PropTypes.bool,
    requestError: PropTypes.string,
    setLoadingFinished: PropTypes.func,
    retryAction: PropTypes.func,
    showErrorDialog: PropTypes.bool,
    execute: PropTypes.func,
    classes: PropTypes.any,
    autoLogin: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.string
}
const mapStateToProps = (state,ownProps) => {
    return {
        location: ownProps.location.pathname,
        loading: state.app.appLoading,
        requestError: state.app.appRequestErrorMessage,
        retryAction: state.app.appRequestErrorRetryAction,
        showErrorDialog: state.app.appRequestErrorMessage != null
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLoadingFinished: () => dispatch(setAppLoadingFinished()),
        autoLogin: () => dispatch(autoLogin(dispatch)),
        execute: (action) => dispatch(action())
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App)));