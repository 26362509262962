import React from 'react';
import {
    Grid, Checkbox, ButtonGroup,
    Chip, TextField, Fab, Icon
} from '@mui/material'
import { Autocomplete } from '@mui/material';
import PropTypes from 'prop-types';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


export default class RolesCellEditor extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            user : null,
        };
    }

    render() {
        return <Grid container>
            <Grid item xs={9}>
                {
                    this.props.editModeEnabled
                        ? <Autocomplete
                            multiple
                            id="size-small-filled-multi"
                            size="small"
                            disableClearable
                            disableCloseOnSelect
                            options={this.props.group.roles}
                            getOptionLabel={option => option.name}
                            defaultValue={[...this.props.group.roles.filter(role => this.props.user.roles.some(t => t.id == role.id))]}
                            renderTags={(value, getTagProps) => {
                                return value.map((option, index) => (
                                    <Chip
                                        key={index}
                                        variant="outlined"
                                        label={option.name}
                                        size="small"
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }

                            }

                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </React.Fragment>
                            )}
                            onChange={(e, value) => { this.setState({ user:{ ...this.props.user, roles: value } }); }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    fullWidth
                                />
                            )}
                        />
                        :
                        this.props.group.roles.filter(role => this.props.user.roles.some(t => t.id == role.id)).map(role => role.name).join(',')

                }

            </Grid>
            {
                this.props.editModeEnabled
                    ? <ButtonGroup color="primary" aria-label="outlined primary button group">
                        <Grid container>

                            <Fab
                                size="small"
                                color="primary"
                                onClick={() => {
                                    if (this.state.user)
                                        this.props.saveAction({ userId: this.props.user.userId, groupId: this.props.groupId, roleIds: this.state.user.roles.map(r => r.id) })
                                    this.props.toggleEditMode();
                                }}><Icon>check_outlined</Icon></Fab>


                            <Fab
                                size="small"
                                color="secondary"
                                onClick={() => {
                                    this.setState({user : null});
                                    this.props.toggleEditMode();
                                }}><Icon>close_outlined</Icon></Fab>

                        </Grid>
                    </ButtonGroup>
                    : ''
            }

        </Grid>
    }
}

RolesCellEditor.propTypes = {
    editModeEnabled: PropTypes.bool,
    group: PropTypes.object,
    groupId: PropTypes.number,
    saveAction: PropTypes.func,
    toggleEditMode: PropTypes.func,
    user: PropTypes.object
};