import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as RolesListActions from './actions'
import { bindActionCreators } from 'redux'
import { LinearProgress, Fab, AccordionDetails, AccordionSummary, Accordion, FormControlLabel, Switch, Grid } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router'
import LoadingButton from './LoadingButton/LoadingButton'
import TextFieldWithThrow from './TextFieldWithError/TextFieldWithError'
import { initialState } from './reducer'


const styles = () => ({
    noBorder: {
        boder: 0,
        "box-shadow": 0
    },
    defaultButtonCollor: {
        color: "#3f51b5",
        backgroundColor: "white"
    }
});

class RolesList extends React.PureComponent {
    constructor(props) {
        super(props);
        if(this.props.groupName)
            localStorage.setItem('groupName',this.props.groupName)
        this.state = {
            errorProps: {},
            changedPermission:{},
            groupName: localStorage.getItem('groupName')
        }
        this.props.actions.initRoles(this.props.groupId);
    }

    componentWillUnmount() {
        this.props.actions.setRolesData(initialState.rolesList);
    }
    startLoading(item) {
        this.setState({ loading: true, loadingId: item.id })
    }
    stopLoading() {
        this.setState({ loading: false, loaded: true })
        setTimeout(() => {
            this.setDefault()
        }, 1500);
    }
    setDefault() {
        this.setState({ loading: false, loaded: false })
    }


    handleAddPermission(shortPermissionInfo) {
        this.props.actions.addPermission(shortPermissionInfo).then(() => this.setState(state => state.changedPermission = {id: null, roleId:null}));
    }
    handleRemovePermission(shortPermissionInfo) {
        this.props.actions.removePermission(shortPermissionInfo).then(() => this.setState(state => state.changedPermission = {id: null, roleId:null}));
    }

    permissions = (role) => {
        let distinctGroup = [...new Set(role.permissions.map(t => t.groupName))];
        let result = [];
        var self = this;
        distinctGroup.forEach(function (group) {
            result.push(
                <Grid item key={group} xs={12}>
                    <Accordion key={group} elevation={0} defaultExpanded>
                        <AccordionSummary aria-controls="panel1c-content"
                            id="panel1c-header" key={group}
                            expandIcon={<ExpandMoreIcon />}>
                            <h4>{group}:</h4>
                        </AccordionSummary>
                        <AccordionDetails key={group}>
                            <Grid container key={group}>
                                {role.permissions.filter(t => t.groupName == group).sort((a, b) => a.id - b.id).map(permission => {
                                    return <Grid key={permission.id} item xs={6} lg={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch checked={permission.enabled} onChange={() => {
                                                    self.setState(state => state.changedPermission = {id: permission.id, roleId: role.id})
                                                    
                                                    if (!permission.enabled)
                                                        self.handleAddPermission({ roleId: role.id, permissionId: permission.id });
                                                    else if (permission.enabled)
                                                        self.handleRemovePermission({ roleId: role.id, permissionId: permission.id })
                                                }} value="" />
                                            }
                                            label={permission.name}
                                        />
                                        <Grid container>
                                            <Grid item xs={1}>
                                                <LinearProgress style={{ display: (self.state.changedPermission.id == permission.id && self.state.changedPermission.roleId == role.id) ? 'block': 'none'}} color='secondary' />
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                })}</Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            );
        });
        return result
    }
    validate = (role) => {
        if (!role.name) {
            this.setState(state => state.errorProps = {
                error: true,
                id: "filled-error-helper-text",
                label: "Error",
                helperText: "This field is required",
                variant: "filled",
                roleId: role.id
            });
            return false;
        }
        return true;
    }

    items = () => {
        const classes = this.props.classes;
        return (this.props.rolesList.map(item => {
            const loading = this.state.loadingId == item.id ? this.state.loading : null;
            const loaded = this.state.loadingId == item.id ? this.state.loaded : null;
            return (
                <form key={item.id}>
                    <Accordion key={item.id}>
                        <AccordionSummary key={item.id}
                            expandIcon={item.id
                                ? <ExpandMoreIcon />
                                : <Fab
                                    onClick={(e) => {
                                        e.stopPropagation(); if (!this.validate(item)) return;
                                        this.props.actions.saveRoleChanges({ ...item, groupId: this.props.groupId })
                                    }}
                                    className={classes.defaultButtonCollor}><AddIcon />
                                </Fab>
                            }
                            aria-controls="panel1c-content"
                            id="panel1c-header"
                        >
                            <Grid container key={item.id}>
                                <React.Fragment key={item.id}>
                                    <Grid item xs={5}><TextFieldWithThrow
                                        label="Name"
                                        errors={item.errors}
                                        required
                                        {...(this.state.errorProps.roleId == item.id ? this.state.errorProps : {})}
                                        name="name"
                                        onClick={e => { e.stopPropagation(); }}
                                        variant="outlined"
                                        onChange={(e) => { this.props.actions.updateRole({ ...item, name: e.target.value }); }}
                                        value={item.name}></TextFieldWithThrow></Grid>
                                    <Grid item xs={5}><TextFieldWithThrow
                                        label="Description"
                                        onClick={e => { e.stopPropagation(); }}
                                        errors={item.errors}
                                        name="description"
                                        multiline
                                        variant="outlined"
                                        onChange={(e) => { this.props.actions.updateRole({ ...item, description: e.target.value }); }}
                                        value={item.description}></TextFieldWithThrow></Grid>
                                    <Grid item xs={2}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                {!item.id
                                                    ? ''
                                                    : <LoadingButton
                                                        loading={loading}
                                                        loaded={loaded}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (!this.validate(item)) return
                                                            this.startLoading(item)
                                                            this.props.actions.saveRoleChanges({ ...item, groupId: this.props.groupId }).then(() => this.stopLoading());
                                                        }}
                                                        classes={{ button: classes.defaultButtonCollor }} />}
                                            </Grid>
                                            <Grid item xs={6}>{!item.id ? '' : <Fab className={classes.defaultButtonCollor} onClick={(e) => { e.stopPropagation(); this.props.actions.deleteRole(item.id) }}><DeleteIcon /></Fab>}</Grid>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails key={item.id}>
                            <Grid container key={item.id}>
                                {this.permissions(item)}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </form>
            );
        }))
    }

    render() {
        return (
            <React.Fragment>
                <h3>Group <i> &quot; {this.state.groupName} &quot; </i> Roles:</h3>
                {this.items()}  
            </React.Fragment>
        )
    }
}                            
            
RolesList.propTypes = {
        groupId: PropTypes.number,
        groupName: PropTypes.string,
        actions: PropTypes.object,
        rolesList: PropTypes.array,
        roleInEdit: PropTypes.object,
        initialLoaded: PropTypes.bool,
        permissions: PropTypes.array,
        classes: PropTypes.object,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired
        })
    }

const mapStateToProps = (state, ownProps) => {
    return {
        rolesList: state.rolesComponent.rolesList,
        groupName: state.rolesComponent.groupName,
        roleInEdit: state.rolesComponent.roleInEdit,
        permissions: state.rolesComponent.permissions,
        groupId: parseInt(ownProps.match.params.id)
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(RolesListActions, dispatch)
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RolesList)));