import {
	compareGlobalActions
} from './actions'

export const interviewsCompareList = (state = {
	list: []
}, action) => {
	switch (action.type) {
		case compareGlobalActions.ADD_SUMMARY_TO_COMPARE_LIST:
			var summary = state.list.find(el => el.Id == action.data.Id)
			if (summary) {
				return Object.assign({}, state);
			} else {
				return {
					...state,
					list: state.list.concat(action.data)
				}
			}
		case compareGlobalActions.REMOVE_SUMMARY_FROM_COMPARE_LIST:
			return Object.assign({}, state, {
				list: state.list.filter(el => el.Id != action.Id)
			});
		case compareGlobalActions.REMOVE_PROFILE_FROM_COMPARE_LIST:
			return Object.assign({}, state, {
				list: state.list.filter(el => el.profileId != action.Id)
			});
		default:
			return state;
	}
}