import React, { Component, Fragment, useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { Grid, Fab, TextField, Table, Button, TableHead, TableCell, TableBody,TableRow, Icon, FormControl, Box, Typography } from '@mui/material'
import ScoreSelector from "../ScoreSelector/ScoreSelector"



export default ({ className, id, title, description, expertResponse, onCommentChange, onScoreSet, scoresConfig, isSaving, hideTitle = true }) =>{

	const theme = useTheme();
	const useStyles = makeStyles({
		descriptor: {
			display: "flex",
			paddingBottom: theme.spacing(1)
		},
		textValue: {
			fontWeight: "400"
		}

	})
	const comment = expertResponse && expertResponse.comment ? expertResponse.comment : "";
	const classes = useStyles({});
    return <>
        {!hideTitle && <Box className={classes.descriptor}>
			<Typography variant="subtitle2">
				Title:
			</Typography>
			<Typography className={classes.textValue} variant="subtitle2">{title}</Typography>
		</Box>}
		<Box className={classes.descriptor}>
			<Typography variant="subtitle2">
				Description:
			</Typography>
			<Typography className={classes.textValue} variant="subtitle2">{description}</Typography>
		</Box>
		<Box className={classes.descriptor}>
			<Typography variant="subtitle2">
				Score:
			</Typography>
		</Box>
        <ScoreSelector
							currentScore={expertResponse?.score} 
							enableSlider={false}
							enableComment={true}
							ranges={scoresConfig.ranges}
							comment={comment}
							onCommentChange={onCommentChange}
							onScoreChange={onScoreSet}
							isSaving={isSaving}
						/>
    </>
}