import React, { Component, PureComponent } from 'react'
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Button, Popover, Typography, Box  } from '@mui/material/';
import { setScore } from "../actions";
import ScoreSelector from "../../../common/components/ScoreSelector/ScoreSelector"
import Loader from '../../Loader/'
import { getColor } from '../../../utils/colorPicker'
import makeStyles from '@mui/styles/makeStyles';
import ScoreButton from './ScoreButton'


const styles= (theme) => ({
	button: {
		fontSize: "0.7rem"
	},
	scoreWindow: {
		padding: "10px",
		position:'relative'
	},
	scoreWindowCloseButton: {
		margin: "10px 2px 0px 2px"
	},
	loader: {
		left:0,
		top:0
	},
	setScoreTitle: {
		margin: "5px 0"
	},
	setScoreQuestion: {
		margin: "5px 0"
	},
	scoreButton: {
		height: "auto"
	},
	scoreContainer: {
		display: "flex",
		flexDirection: "column"
	}
})

class ScoreSetAction extends PureComponent {
	constructor(props){
		super(props);
		this.state = {
			anchorEl: null,
			score: this.props.score,
			comment: this.props.comment,
			loading: false
		}
	}

	// shouldComponentUpdate = (nextProps, nextState) => {
	// 	console.log("should update called");
	// 	return nextProps != this.props.score || this.props.comment != nextProps.comment
	// }

	scoreChange = (value) =>{
		var range = this.props.ranges.find(x=>x.top == value);
		this.setState({score:value,scoreTitle: range ? range.title : "" })
	}

	commentChange = (event) => {
		this.setState({comment:event.target.value})
	}

	openEditDialog = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	}

	cancel = () => {
		this.setState({ anchorEl: null});
	}

	saveData = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if(!this.state.loading) {
			this.setState({loading:true},() => {
				const currentState = {...this.state};
				const notAsked = currentState.value == -1;

				this.props.setScore(this.props.interviewId,this.props.areaId, { 
					id: this.props.areaId, 
					comment: currentState.comment == null ? null : currentState.comment.trim(), 
					score: currentState.score, 
					scoreTitle: currentState.scoreTitle,
					notAsked 
				})
				.then(result => {
					if(result) {
						this.setState({loading:false,anchorEl:null});
					} else {
						this.setState({loading:false});
					}
				}, () => {
					this.setState({loading:false});
				})
			});
		}

		return false;
	}

	render(){
		const { classes } = this.props;

		// const cs = useStyles({ selectedColor: "red" })
		const dialog = this.state.anchorEl ? 
			<Popover
					id="compare-popper"
					// open={open}
					anchorEl={this.state.anchorEl}
					onClose={this.saveData}
					anchorOrigin={{
						vertical: 'center',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'center',
						horizontal: 'left',
					}}
				>
					<div className={classes.scoreWindow}>
						<Loader show={this.state.loading} className={classes.loader}/>
						<div>
							<Typography className={classes.setScoreTitle} variant="h6">{this.props.areaTitle}</Typography>
						</div>
						<div>
							<Typography className={classes.setScoreQuestion} variant="body2">{this.props.scoreQuestion}</Typography>
						</div>

						<ScoreSelector 
							currentScore={this.state.score} 
							enableSlider={false}
							enableComment={true}
							ranges={this.props.ranges}
							comment={this.state.comment}
							onCommentChange={this.commentChange}
							onScoreChange={this.scoreChange}
						/>
						<div>
							<Button 
									variant="outlined" 
									size="small" 
									color="primary" 
									className={classes.scoreWindowCloseButton}
									onClick={this.saveData}
								>
								Ok
							</Button>
							<Button 
									variant="outlined" 
									size="small" 
									color="secondary" 
									className={classes.scoreWindowCloseButton}
									onClick={this.cancel}
								>
								Cancel
							</Button>
						</div>
					</div>
				</Popover>
				:
				""
				;
		const selectedIndex = this.props.ranges.findIndex(x=>x.title == this.props.scoreTitle);
		const color = selectedIndex >=0 ? getColor(selectedIndex, this.props.ranges.length) : "white";
		const suggestionColor = this.props.suggestions ? getColor(this.props.suggestions[0], this.props.ranges.length) : "";
		const suggestTitle = this.props.suggestions ? this.props.ranges[this.props.suggestions[0]].title : "";
		return (
					<Box className={classes.scoreContainer}>
					{/* {level} */}
					<ScoreButton 
						className={classes.scoreButton}
						title={this.props.scoreTitle ? this.props.scoreTitle : "-" }
						selectedColor={color}
						hasAnswer={selectedIndex >=0 }
					/>{this.props.suggestions &&
						<Box>
							<ScoreButton 
								className={classes.scoreButton}
								isSuggestion={true}
								title={suggestTitle}
								selectedColor={suggestionColor}
								hasAnswer={true}
							/>
						</Box>
					}
					</Box>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	const area = state.interviewComponent.areas.find(area=>{
		return area.id == ownProps.id
	});
	const expertResponse = area.expertResponse || {};
	var scoresConfig = state.interviewComponent.scoresConfigs.find(x=>x.id == area.scoreConfigId);
	if(!scoresConfig){
		scoresConfig = state.interviewComponent.scoresConfigs[0];
	}
	return {
		interviewId: state.interviewComponent.id,
		areaTitle: area.title,
		areaId: ownProps.id,
		score: expertResponse.notAsked ? -1 : expertResponse.score,
		scoreTitle: expertResponse.notAsked ? null : expertResponse.scoreTitle,
		ranges: scoresConfig.ranges,
		scoreQuestion: scoresConfig.evaluationQuestion,
		comment: expertResponse.comment,
		suggestions: area.suggestions
	}
}
const mapDispatchToProps = (dispatch) => ({
	setScore: (interviewId, id, data) => dispatch(setScore(interviewId,id, data))
});

export default withStyles(styles, {withTheme:true})(connect(mapStateToProps, mapDispatchToProps)(ScoreSetAction));

ScoreSetAction.propTypes = {
	classes: PropTypes.object,
	setScore: PropTypes.func,
	areaId: PropTypes.string,
	comment: PropTypes.string,
	ranges: PropTypes.array,
	score: PropTypes.number,
	notAsked: PropTypes.bool,
	interviewId: PropTypes.number
}