import * as InterviewsCompareActions from './actions'

const initialState = {
	infos: [],
	rows: [],
	scoresInfo: null
}

export const InterviewsCompareComponent = (state = initialState, action) =>{
	switch(action.type){
		case InterviewsCompareActions.ActionTypes.INTERVIEWS_REQUEST_SUCCESS:
			return Object.assign({},state, { infos: action.data.infos, rows: action.data.rows, scoresInfo: action.data.scoreRangeGroup });
		default:
			return state;
	}
}