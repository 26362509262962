import { scoresActions } from './actions'

export const scoresGroups = (state = { groups: [] }, action) =>{
	switch (action.type){
		case scoresActions.LOADED:
			return Object.assign({}, state, { groups: [...action.data] });
		case scoresActions.DELETE_GROUP:
			return Object.assign({}, state, { groups: state.groups.filter(x=>{ return x.id != action.data.id }) });
		default:
			return state;
	}
}