import {appActions} from './actions'

export const app = (state = {appLoading:false,appRequestErrorMessage:null,appRequestErrorRetryAction:null}, action) => {
    switch (action.type) {
        case appActions.APP_LOADING_STARTED:
            return Object.assign({}, state, { appLoading: true, appRequestErrorMessage:null,appRequestErrorRetryAction:null});

        case appActions.APP_LOADING_FINISHED:
            return Object.assign({},state, {appLoading:false, appRequestErrorMessage:null,appRequestErrorRetryAction:null});

        case appActions.APP_REQUEST_ERROR:
            return Object.assign({},state,{appLoading:false, appRequestErrorMessage:action.error, appRequestErrorRetryAction:action.retryAction});
            
        default:
            return state;
    }
};