import React, { Fragment, useState, useEffect } from 'react';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import { Typography, Box, IconButton, CircularProgress   } from '@mui/material'
import { convertToHTML } from 'draft-convert';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles({
    title: {
        marginTop: '5px',
        marginBottom: '5px',
        whiteSpace: "pre"        
    },
    headerLabel: {
        verticalAlign: 'middle',
        fontWeight: 550,
        fontSize: '0.9rem',
        whiteSpace: "break-spaces"
    },
    summaryText: {
        whiteSpace: "pre-line",
        fontSize: "14px"
    },
    editActionIcon: {
        '@media print': {
            display: "none"
        },
        marginLeft: "10px"
    },
    loaderWrapper: {
        // margin: theme.spacing(1),
        position: 'relative',
    },
    loader: {
        // color: green[500],
        position: 'absolute',
        top: -4,
        left: 10,
        zIndex: 1,
    }
});


const SummaryEditor = ({ html, isSaving, onEdit }) => {
    const classes = useStyles({})
    const [ isEdit, setEditMode ] = useState(false);
    const [currentHtml, setCurrentHtml] = useState(html)
    
    const stub = "General: Candidate looks like experienced desktop developer and has nearly no experience in web development. From web/integration development side he is junior strong (no even basic skills with in UI, and only basic in web backend/api). As for some common areas he provided good answers about C#, Entity framework, .net platform in general. It seems he like to dive into details and do complex tasks even for thing that already have solution :) . Despite more or less good answers for Entity frameowrk he shows basic skills in SQL and can do only basic tasks in this area. He isn't familiar with common development best practices like Gitflow, unit testing, Continious integration and deployment. For unit testing has very basic skills and for GIt experienced only a little bit with github. Genearlly he is quite experienced and potentially after some rampup period can work on intermidiate level work on Web based solutions. Might be ok for monolith projects, but for sure it will be hard for him to work on SOA or especially microservices. For me he looks like candidate that just require some time to cover main gaps and get well with common enterprise level development practices Strong sides: C#, .net, Entity framework, OOD"
    let _contentState = ContentState.createFromText('Sample content state \n ANother content');
    const blocksFromHTML = convertFromHTML(html ?? "");
    const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
    );
    const [editorState, setEditorState] = useState(EditorState.createWithContent(state));
    useEffect(()=>{
        setCurrentHtml(html);
        setEditorState(EditorState.createWithContent(state));
    }, [html])

    const onEditorStateChange = (editorState) => {
      setEditorState(editorState)
    }

    return  <Box>
                <Typography variant="subtitle1" className={classes.title}>
                                    {/* <i className="material-icons" style={{ verticalAlign: 'middle' }}>
                                        feedback
                                    </i> */}
                                    <span className={classes.headerLabel}>
                                        Expert Summary
                                    </span>
                                    {isEdit || isSaving ? 
                                        <span className={classes.loaderWrapper}>
                                            <IconButton disabled={isSaving} className={classes.editActionIcon} size="small" color="primary" onClick={()=>{ 
                                                    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
                                                    setEditMode(false); 
                                                    setCurrentHtml(currentContentAsHTML);
                                                    onEdit(currentContentAsHTML);
                                                }}>
                                                <CheckIcon />
                                            </IconButton>
                                            {isSaving && <CircularProgress className={classes.loader} size={30} /> }
                                            <IconButton disabled={isSaving} className={classes.editActionIcon} size="small" color="secondary" onClick={()=>{
                                                    setEditMode(false)
                                                }}>
                                                <ClearIcon />
                                            </IconButton>
                                        </span>
                                        :
                                        <IconButton className={classes.editActionIcon} size="small" onClick={()=> setEditMode(!isEdit)}>
                                            <EditIcon />
                                        </IconButton>
                                    }
                                </Typography>
                                <div>
                                    {isEdit ? 
                                        <Editor 
                                            toolbar={{
                                                fontFamily: {
                                                    options: ['Roboto','Calibri', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana']
                                                },
                                            }}
                                            editorState={editorState}
                                            onEditorStateChange={onEditorStateChange} 
                                        />
                                        :
                                        <Box dangerouslySetInnerHTML={{ __html: currentHtml }}></Box>
                                    }
                                </div>
            </Box>
}

export default SummaryEditor