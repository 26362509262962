import React from 'react';
import {
	Grid, Paper, Select, FormHelperText, MenuItem, Table, Button, TableCell, TableBody, TableRow, Typography, TextField, FormControl, TableHead, Fab, Icon
} from '@mui/material'
import PropTypes from 'prop-types';
import * as groupsActions from './actions'
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import RolesCellEditor from './RolesCellEditor/RolesCellEditor'
import { bindActionCreators } from 'redux';
import Moment from 'react-moment';

const styles = theme => ({
	screenTitle: {
		paddingRight: 30,
		display: "inline-block"
	},
	mainButton: {
		marginRigth: 10
	},
	title: {
		paddingBottom: theme.spacing(2)
	},
	usersTitle: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(1)
	},
	inviteLabel: {

	},
	paperContainer: {
		paddingTop: theme.spacing(1),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	roleSelect: {
		marginLeft: theme.spacing(2),
		minWidth: 100
	},
	inviteButton: {
		marginLeft: theme.spacing(2)
	},
	inviteIcon: {
		marginRight: theme.spacing(1)
	}
});

class GroupMembers extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
		};
		this.props.actions.getGroup(this.props.groupId);
	}

	editEmail = (text) => {
		this.props.actions.editInviteEmail(text);
	}

	selectRole = (roleId) => {
		this.props.actions.editInviteRole(roleId);
	}

	editUserRoles = (info) => {
		this.props.actions.editUserRoles(info);
	}

	sendInvite = () => {
		this.props.actions.sendInvite(this.props.inviteEmail, this.props.inviteRoleId, this.props.groupId);
	}

	deleteUser= (userId, groupId) => {
		this.props.actions.removeUser(userId, groupId);
	}
	toggleEditMode = (id) => {
		this.setState({editingUserId:id});
	}


	render() {
		const classes = this.props.classes;
		const roles = this.props.group.roles.map(item => {
			return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
		});
		const inviteRows = this.props.group.invites.map(item => {
			var inviteRole = this.props.group.roles.find(x => x.id == item.roleId)
			return <TableRow key={item.email}>
				<TableCell>{item.email}</TableCell>
				<TableCell>{inviteRole ? inviteRole.name : ''}</TableCell>
				<TableCell>
					<Moment format="YYYY-MM-DD HH:mm">
						{item.inviteSentDate}
					</Moment>
				</TableCell>
				<TableCell>
					<Button 
					onClick={()=> this.props.actions.declineInvite(item.key)}
					variant="contained">Reject</Button>
				</TableCell>
			</TableRow>
		});
		const usersRows = this.props.group.users.map(u => {
			const editModeEnabled = this.state.editingUserId == u.userId
			return <TableRow key={u.userId}>
				<TableCell>{u.displayName}</TableCell>
				{<TableCell>
					<RolesCellEditor 
						group = {this.props.group}
						groupId = {this.props.groupId}
						toggleEditMode = {(u) => this.toggleEditMode(u)} 
						saveAction = {(u) => this.editUserRoles(u)} 
						editModeEnabled = {editModeEnabled} 
						user = {u}>
					</RolesCellEditor>
				</TableCell>}
				<TableCell>
					<Fab size="small" onClick={() => this.toggleEditMode(editModeEnabled ? undefined : u.userId)} style ={{ margin: "5px"}}>
						<Icon>
							edit_outline
						</Icon>
					</Fab>
					<Fab size="small" style ={{ margin: "5px"}} onClick={()=>this.deleteUser(u.userId, this.props.groupId)}>
						<Icon>
							delete_outline
						</Icon>
					</Fab>
				</TableCell>
			</TableRow>
		});
		// const validationProps = this.props.emailValid ? { label: ""}
		return <Grid>
				<Typography className={classes.title} variant="h6">Invite people to group: {this.props.group.name}</Typography>
				<Paper className={classes.paperContainer}>
					{/* <Typography className={classes.inviteLabel}>Invite user with email: </Typography> */}
					<FormControl>
						<TextField
							id="email"
							error={!this.props.emailValid}
							placeholder="user@email.com"
							onChange={(event)=> this.editEmail(event.target.value)}
							type="email"
							aria-describedby="email-helper-text"
							value={this.props.inviteEmail}
							onKeyPress={(e)=>{
								if (e.which == 13) { //enter
									this.sendInvite()
									e.preventDefault();
									e.stopPropagation();
								}
							}}
						/>
						<FormHelperText error={!this.props.emailValid} id="email-helper-text"> { this.props.emailValid ? "invite email" : "invalid invite email" }</FormHelperText>
					</FormControl>
					<FormControl className={classes.roleSelect}>
						<Select
						id="role-select"
						value={parseInt(this.props.inviteRoleId)}
						aria-describedby="role-helper-text"
						onChange={(event) => this.selectRole(event.target.value)}
						autoWidth={true}
					>
						{roles}
					</Select>
					<FormHelperText id="role-helper-text">invite with role</FormHelperText>
				</FormControl>
				<Button onClick={() => this.sendInvite()} className={classes.inviteButton} variant="contained" size="medium" color="primary">
					<Icon className={classes.inviteIcon} fontSize="small">person_add</Icon>
					Invite
					</Button>
			</Paper>
			<Typography className={classes.usersTitle} variant="h6">Users</Typography>
			<Paper>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Roles</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{usersRows}
					</TableBody>
				</Table>
			</Paper>
			<Typography className={classes.usersTitle} variant="h6">Invited users</Typography>
			<Paper>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Email</TableCell>
							<TableCell>Invite role</TableCell>
							<TableCell>Invite date</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{inviteRows}
					</TableBody>
				</Table>
			</Paper>
		</Grid>

	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		groupId: parseInt(ownProps.match.params.id),
		group: state.groupMembersComponent,
		inviteEmail: state.groupMembersComponent.inviteEmail,
		inviteRoleId: state.groupMembersComponent.inviteRoleId,
		emailValid: state.groupMembersComponent.emailValid
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(groupsActions, dispatch)
	};
};

GroupMembers.propTypes = {
	classes: PropTypes.object,
	group: PropTypes.object,
	inviteEmail: PropTypes.string,
	inviteRoleId: PropTypes.number,
	emailValid: PropTypes.bool,
	groupId: PropTypes.number,
	actions: PropTypes.object,
	history: PropTypes.object,
	state: PropTypes.object
}

export default (withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupMembers))))


