import {interviewTimeLineActions} from './actions'

const initialState = {
    profileName: "",
    completedDate: "",
    levelName: "",
    levelScore: null,
    profileVersion: 0,
    summary: "",
    title: "",
    responses: [],
    flatSortedResponses: []
};

export const interviewTimeLineComponent = (state = initialState, action) => {
    switch (action.type) {
        case interviewTimeLineActions.INTERVIEW_TIMELINE_REQUEST_SUCCEEDED:
            return Object.assign({}, state, action.data);
            
        default:
            return state;
    }
};