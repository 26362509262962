import React from 'react'
import { connect } from 'react-redux'
import { SnackbarContent, TablePagination, Icon, List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, Divider, Grid, TextField } from '@mui/material'
import withStyles from '@mui/styles/withStyles';
import ScoreSelector from '../../common/components/ScoreSelector/ScoreSelector'

class ComponentsLab extends React.PureComponent {
	constructor(props){
		super(props);
		this.state = {
			score: 25,
			comment: "Expert comment"
		}
		this.scoreChange = this.scoreChange.bind(this);
		this.commentChange = this.commentChange.bind(this);
	}

	scoreChange = (value) =>{
		this.setState({score: value});
	}

	commentChange = (event) => {
		this.setState({comment: event.target.value});
	}

	render(){
		var ranges = [
			{
				id: -1,
				name: "Not asked",
				bottom: -1,
				top: -1
			},
			{
				id: 0,
				name: "None",
				bottom: 0,
				top: 0
			},
			{
				id: 1,
				name: "Beginner",
				bottom: 1,
				top: 33
			},
			{
				id: 2,
				name: "Good",
				bottom: 34,
				top: 66
			},
			{
				id: 3,
				name: "Strong",
				bottom: 67,
				top: 100
			}
		];

		return(
			<div>
				<h3>Components lab</h3>
				<ScoreSelector 
					ranges={ranges} 
					currentScore={this.state.score} 
					comment={this.state.comment} 
					enableSlider={true} 
					onScoreChange={this.scoreChange}
					onCommentChange={this.commentChange}
				/>
			</div>
		);
	}
}

export default ComponentsLab;