
// Auto-generated, edits will be overwritten
const spec = {
  // 'host': 'localhost:5000',
  // 'schemes': [
  //   'http'
  // ],
  'host': 'api.interviewapp.xyz',
  'schemes': [
    'https'
  ],
  'basePath': '',
  'contentTypes': [],
  'accepts': [
    'application/json'
  ],
  'securityDefinitions': {
    'oauth2': {
      'name': 'Authorization',
      'in': 'header',
      'type': 'apiKey',
      'description': 'JWT Authorization header using the Bearer scheme. Example: \'Authorization: Bearer {token}\''
    }
  }
}
export default spec
