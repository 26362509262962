import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { IconButton, Icon, Menu, MenuItem, Avatar } from '@mui/material';
import { logout } from "../../common/actions/accountActions";

class AccountButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null
        }

        this.handleMenu = this.handleMenu.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onLogout = this.onLogout.bind(this);
    }

    handleMenu(event) {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleClose() {
        this.setState({ anchorEl: null });
    }

    handleLink(path) {
        this.props.history.push(path);
    }

    onLogout() {
        this.props.logout();
        this.props.history.push("/login");
    }

    render() {
        const open = Boolean(this.state.anchorEl);
        const userBtn = 
            this.props.profileUrl == null || this.props.profileUrl == "" ? <IconButton
                aria-owns={open ? 'login-menu-appbar' : null}
                aria-haspopup="true"
                onClick={this.handleMenu}
                color="inherit"
                size="large">
                                        <Icon>account_circle</Icon>
                                    </IconButton> 
                                    : 
                                    <Avatar src={this.props.profileUrl} onClick={this.handleMenu} />;
        return (
            <div>
                {userBtn}
                <Menu
                    id="login-menu-appbar"
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={this.handleClose}
                >
                    <MenuItem onClick={this.handleClose}>{this.props.name}</MenuItem>
                    <MenuItem onClick={this.onLogout}>Logout</MenuItem>
                </Menu>
            </div>
        );
    }
}

AccountButton.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }),
    logout: PropTypes.func,
    name: PropTypes.string
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.account.isLoggedIn,
        name: state.account.name,
        email: state.account.email,
        profileUrl: state.account.profileUrl
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountButton));