import * as compareApi from '../../api/interview/Compare'
import { setRequestError, setAppLoadingStarted, setAppLoadingFinished } from '../App/actions'

export const ActionTypes ={
	INTERVIEWS_REQUEST_SUCCESS: "COMPARE_REQUEST_SUCCESS"
}

export const setCompareResultsLoaded = (result) => ({ type: ActionTypes.INTERVIEWS_REQUEST_SUCCESS, data: result });

export const getInterviewsResults = (ids = []) => (dispatch) =>{
	dispatch(setAppLoadingStarted());
	compareApi.Compare({
		request: 
		{
			interviewIds : ids
		}
	})
	.then((response) => {
		
		if (!response.error) {
			const responseBody = response.data;
			const { data } = responseBody;
			dispatch(setAppLoadingFinished());
			dispatch(setCompareResultsLoaded(data));
		} else {
			dispatch(setRequestError(response.data.body.error.generalErrorMessag,() => getInterviewsResults(ids)));
		}
	}).catch(error => {
		dispatch(setRequestError("Error occured:" + error, () => getInterviewsResults(ids)));
	});
}