import * as validationRules from '../../utils/validationHelpers'

export const candidateValidationRules = {
    'email' : [{
        rule:validationRules.isEmail,
        message:'Not valid email address'
    }, {
        rule:validationRules.notEmpty,
        message:'Email is required'
    }],
    'name': {
        rule:validationRules.notEmpty,
        message:'Name is required'
    },
    'lastName': {
        rule:validationRules.notEmpty,
        message:'LastName is required'
    },
    'phone': {
        rule:validationRules.isPhone,
        message:'Not valid phone number'
    }
}