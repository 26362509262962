import * as CandidatesApi from '../../api/interview/Candidates'
import { setRequestError, setAppLoadingStarted, setAppLoadingFinished } from '../App/actions'

export const candidatesListAction = {
    CANDIDATES_SET_DATA: 'CANDIDATES_SET_DATA',
    CANDIDATE_DELETED: 'CANDIDATE_DELETED'
}

export const setCandidatesData = (data) => ({type:candidatesListAction.CANDIDATES_SET_DATA,data})
export const setCandidateDeleted = (id) => ({type: candidatesListAction.CANDIDATE_DELETED, id})


export const getCandidates = (filtering = false, pageNumber = null,pageSize = null) => (dispatch,getState) => {
    const {candidatesListComponent} = getState();
    if(filtering && candidatesListComponent.isFiltering) {
        return;
    }
    else if(filtering) {
        dispatch(setCandidatesData({isFiltering:true}));
    } else if(!filtering) {
        dispatch(setAppLoadingStarted());
    }

    const searchTerm = filtering ? candidatesListComponent.filterValue : '';
    
    CandidatesApi.Search({searchTerm,pageNumber,pageSize})
        .then((response) => {
            if (!response.error) {
                const responseBody = response.data;
                const { data } = responseBody;
                dispatch(setAppLoadingFinished());
                dispatch(setCandidatesData({candidatesList:data.results,rowCount:data.rowCount,initialLoaded:!searchTerm}));
            } else {
                dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => getCandidates(filtering))); 
            }
            if(filtering) {
                dispatch(setCandidatesData({isFiltering:false}));
            }
        }).catch((error) => {
            if(filtering) {
                dispatch(setCandidatesData({isFiltering:false}));
            }
            dispatch(setRequestError("Error occured " + error, () => getCandidates(filtering)));
        });
}

export const deleteCandidate = (id) => (dispatch) => {
    dispatch(setAppLoadingStarted());
    CandidatesApi.Delete(id)
    .then(response => {
        if (!response.error) {
            dispatch(setAppLoadingFinished());
            dispatch(setCandidateDeleted(id));
        } else {
            dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => getCandidates(deleteCandidate))); 
        }
    }).catch(error =>{
        dispatch(setRequestError("Error occured " + error, () => deleteCandidate(id)));
    });
}