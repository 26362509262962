import { interviewsListActions } from './actions'

export const initialState = {
    interviewsList: [],
    searchValue: "",
    interviewStageFilter: "",
    initialLoaded:false
};

export const interviewsListComponent = (state = initialState, action) => {
    switch (action.type) {
        case interviewsListActions.INTERVIEWS_SET_DATA:
            return Object.assign({},state, action.data);
        case interviewsListActions.DELETE_INTERVIEW_SUCCEEDED:
            return Object.assign({},state, { interviewsList: state.interviewsList.filter(({interviewId}) => interviewId != action.id), rowCount: state.rowCount - 1});       
        default:
            return state;
    }
};