import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button, Menu, MenuItem, Icon } from '@mui/material'

export default class ProfilesMenu extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
        this.onMenuClick = this.onMenuClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    onMenuClick(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ anchorEl: e.currentTarget });
    }

    onMenuItemClick(e, target) {
        e.preventDefault();
        e.stopPropagation();
        switch (target) {
            case "startInterview": {
                this.props.onStart(this.props.id,this.props.releasedVersionId);
                break;
            }
            case "history": {
                //go to history page
                this.handleClose(e);
                break;
            }
            case "deleteProfile": {
                //delete by id
                this.props.onDelete(this.props.id);
                break;
            }
            case "cloneProfile": {
                //delete by id
                this.props.onClone(this.props.id);
                break;
            }
        }
    }

    handleClose(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ anchorEl: null });
    }

    render() {
        const { anchorEl } = this.state;
        return (

            <Fragment>
                <Button
                    aria-owns={anchorEl ? 'simple-menu' : null}
                    aria-haspopup="true"
                    onClick={this.onMenuClick}
                >
                    <Icon>more_vert</Icon>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                >
                    {this.props.showStart && <MenuItem onClick={(e) => this.onMenuItemClick(e, "startInterview")}>Start Interview</MenuItem>}
                    <MenuItem onClick={(e) => this.onMenuItemClick(e, "history")}>History</MenuItem>
                    <MenuItem onClick={(e) => this.onMenuItemClick(e, "deleteProfile")}>Delete Profile</MenuItem>
                    <MenuItem onClick={(e) => this.onMenuItemClick(e, "cloneProfile")}>Clone Profile</MenuItem>
                </Menu>
            </Fragment>
        );
    }
}

ProfilesMenu.propTypes = {
    id: PropTypes.number,
    onDelete: PropTypes.func.isRequired,
    onClone: PropTypes.func.isRequired,
    showStart: PropTypes.bool,
    releasedVersionId: PropTypes.number,
    onStart: PropTypes.func.isRequired
}