import React from 'react'
import PropTypes from 'prop-types'
import { Divider, TablePagination, Table, TableRow, TableHead, TableCell, TableBody,
    Button, Snackbar, IconButton, Icon, TableFooter, Grid, SnackbarContent, } from '@mui/material'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as CandidateInterviewsActions from './actions'
import Loader from '../Loader'
import withStyles from '@mui/styles/withStyles';
import { copyStringToClipboard } from '../../utils/helpers'

const styles = theme => ({
    tableRoot: {
        position: 'relative'
    },
    loader: {
        minHeight: 100
    },
    close: {
        padding: theme.spacing(0.5),
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
        margin: theme.spacing(1),
    },
    icon: {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        backgroundColor: theme.palette.primary.dark,
        display: 'flex',
        alignItems: 'center'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
});

class CandidateInterviewsComponent extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            rowsPerPage: 5,
            snackBarMessage: '',
            snackBarOpen: false
        }
        this.props.actions.getInterviews(this.props.candidateId, this.state.page, this.state.rowsPerPage);
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
        this.props.actions.getInterviews(this.props.candidateId, page, this.state.rowsPerPage);
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
        this.props.actions.getInterviews(false, this.state.page, event.target.value);
    }

    handleCreateInvitationClick = (event, interview) => {
        this.props.actions.generateInvitation(interview.id);
    }

    handleInvitationClick = (event, key) => {
        event.stopPropagation();
        event.preventDefault();
        const url = (new URL(`/preinterview?key=${key}`, window.location.origin)).href;
        copyStringToClipboard(url);
        this.setState({
            snackBarOpen: true,
            snackBarMessage: 'Invitation copied to clipboard!'
        });
        return false;
    }

    preventBubbling = (e) => {
        e.stopPropagation();
        e.preventDefault();
        return false;
    }

    handleSnackBarClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackBarOpen: false });
    }

    invitation = (interview) => {
        if (!interview.invitationKey) {
            return (
                <Button variant="contained" size="small" color="primary" onClick={(e) => this.handleCreateInvitationClick(e, interview)}>
                    Generate Invitation
                </Button>
            )
        }
        return (
            <React.Fragment>
                <a href={'/'} onClick={(e) => this.handleInvitationClick(e, interview.invitationKey)}>{interview.invitationKey}</a>
            </React.Fragment>
        )
    }

    interviewer = (interview) => {
        if (!interview.interviewerInfo) {
            return (
                <React.Fragment>
                    Not Assigned
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                {interview.interviewerInfo.displayName}
                (<a href={`mailto:${interview.interviewerInfo.email}`} onClick={this.preventBubbling}>
                    {interview.interviewerInfo.email}
                </a>)
            </React.Fragment>
        )
    }

    stage = (interview) => {
        let message = '';
        switch (interview.stage) {
            case 0:
                message = "Pre Interview";
                break;
            case 1:
            case 2:
                message = "Waiting for Interview";
                break;
            case 3:
                message = "Started";
                break;
            case 4:
                message = "Completed";
                break;
            default:
                message = "Unknown";
                break;
        }
        return (
            <React.Fragment>{message}</React.Fragment>
        )
    }

    render() {
        const { classes } = this.props;
        let rows = (<React.Fragment />)
        if (this.props.list) {
            rows = this.props.list.map(el => {
                return (
                    <TableRow key={el.id}>
                        <TableCell>
                            <React.Fragment>{el.name}</React.Fragment>
                        </TableCell>
                        <TableCell>
                            {this.interviewer(el)}
                        </TableCell>
                        <TableCell>
                            {this.invitation(el)}
                        </TableCell>
                        <TableCell>
                            {this.stage(el)}
                        </TableCell>
                    </TableRow>
                )
            });
        }

        return (
            <Grid container>
                <Grid item xs={12} className={classes.tableRoot}>
                    <Loader show={this.props.isLoading} className={classes.loader} />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Interviewer</TableCell>
                                <TableCell>Invitation</TableCell>
                                <TableCell>Stage</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows}
                        </TableBody>
                        <TableFooter>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={this.props.rowCount}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />
                        </TableFooter>
                    </Table>
                    <Divider />
                </Grid>


                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.snackBarOpen}
                    autoHideDuration={5000}
                    onClose={this.handleSnackBarClose}

                >
                    <SnackbarContent
                        className={classes.info}
                        aria-describedby="client-snackbar"
                        message={
                            <span id="client-snackbar" className={classes.message}>
                                <Icon className={classes.icon}>info</Icon>
                                {this.state.snackBarMessage}
                            </span>
                        }
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={this.handleSnackBarClose}
                                size="large">
                                <Icon className={classes.icon}>close</Icon>
                            </IconButton>
                        ]}
                    />
                </Snackbar>
            </Grid>
        );
    }
}

CandidateInterviewsComponent.propTypes = {
    actions: PropTypes.object,
    list: PropTypes.array,
    candidateId: PropTypes.number,
    rowCount: PropTypes.number,
    isLoading: PropTypes.bool,
    classes: PropTypes.any
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.candidatesInterviewsComponent,
        candidateId: ownProps.candidateId
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(CandidateInterviewsActions, dispatch)
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CandidateInterviewsComponent));