import { createSlice } from '@reduxjs/toolkit'
import { setRequestError, setAppLoadingStarted, setAppLoadingFinished } from '../App/actions'
import { mapTreeResponsesToFlatArray } from "../../utils/helpers"
import getApiConfig from '../../utils/api/apiConfigurator';
import { ResultsApi, InterviewApi } from "@interviewapp/interview-app-rest-client"

export const initialState = {
    profileName: "",
    profileVersion: 0,
    levelName: "",
    summary: "",
    title: "",
    candidate: null,
    responses: [],
    hideNA: true,
    filtered: []
};

const applyFilter = (responses, hide) => {
    return hide ? responses.filter(x=> {
        return !x.notAsked || hasChildrenWithAnswers(x.id, responses) 
    }) : responses;
}

function hasChildrenWithAnswers(id, responses){
    let children = responses.filter(x=>x.parentId == id);
    if(children && children.length > 0){
        for (var i=0; i<children.length; i++){
            var ch = children[i];
            if(!ch.notAsked || hasChildrenWithAnswers(ch.id, responses) || ch.expertComment){
                return true;
            } 
        }
    }
    return false;
}

const interviewReviewSlice = createSlice({
    name: "interviewReview",
    initialState: {...initialState },
    reducers: {
        load(state, action) {
            for (var key in action.payload) {
                state[key] = action.payload[key];
            }
            state.filtered = applyFilter(state.responses, state.hideNA);
        },
        toggleSummarySaving(state, action) {
            state.summarySaving = action.payload;
        },
        toggleShowHideNA(state, action) {
            state.hideNA = !state.hideNA;
            state.filtered = applyFilter(state.responses, state.hideNA);
        },
        reset: () => initialState,
    }
})

export default interviewReviewSlice.reducer;
export const { load, toggleShowHideNA, reset, toggleSummarySaving } = interviewReviewSlice.actions;

export const getReview = (id) => (dispatch) => {
    dispatch(setAppLoadingStarted());
    new ResultsApi(getApiConfig()).get(id).then((response) => {
        if (!response.error) {
            const responseBody = response.data;
            const { data } = responseBody;
            dispatch(setAppLoadingFinished());
            let flatResponses  = [];
            mapTreeResponsesToFlatArray(data[0].responses, flatResponses)
            data[0].responses = flatResponses;
            dispatch(load(data[0]));
        } else {
            dispatch(setRequestError(response.data.body.error.generalErrorMessage,() => getReview(id)));
        }
    }).catch((error) => {
        dispatch(setRequestError("Error occured " + error, () => getReview()));
    });
}

export const setSummary = (id, summaryHTML) => (dispatch) => {
    dispatch(toggleSummarySaving(true));
    new InterviewApi(getApiConfig()).setSummary({ interviewId: id, summary: summaryHTML}).then((response) => {
        if (!response.error) {
            dispatch(toggleSummarySaving(false));
        } else {
            dispatch(toggleSummarySaving(false));
            dispatch(setRequestError("Error occured " + response.error, () => setSummary(id, summaryHTML)));
        }
    }).catch((error) => {
        dispatch(setRequestError("Error occured " + error, () => setSummary(id, summaryHTML)));
    });
}
