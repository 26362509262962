import {registerComponentActions} from './actions'

export const initialState = {
    userName:'',
    email:'',
    password:'',
    errors:{}
}

export const registerComponent = (state = initialState, action) => {
    switch (action.type) {
        case registerComponentActions.REGISTER_SET_DATA:
            return Object.assign({},state,action.data);
        case registerComponentActions.REGISTER_SET_ERRORS:
            return Object.assign({},state,{errors:action.errors})
        default:
            return state;
    }
}