import React from 'react';
import PropTypes from 'prop-types';
import { Input, TextField } from '@mui/material';
import TreeGridCell from './TreeGridCell'
import { connect } from 'react-redux'

class TreeGridCellEditable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value:props.value
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }
    shouldComponentUpdate(nextProps,nextState) {
        return this.props.focus != nextProps.focus || this.state.value != nextState.value
    }
    handleChange(e) {
        this.setState({ value: e.target.value })
    }
    handleBlur() {
        this.props.focusConfig.setFocusRow(null);
        this.props.focusConfig.setFocusColumn(null);
        this.saveEditorAndClose();
    }
    saveEditorAndClose() {
        this.setState({ value: this.state.value.trim() }, () => {
            this.props.propChangeHandler(this.props.id,this.props.prop,this.state.value);
        });
    }
    handleClick() {
        if(!this.props.focus) {
            this.props.focusConfig.setFocusRow(this.props.rowOrder);
            this.props.focusConfig.setFocusColumn(this.props.columnOrder);
        }
        
    }
    onDragStart  (e) {
        e.stopPropagation();
        e.preventDefault();
    }
    onKeyDown(e) {
        if (!e.ctrlKey) return;
        if(e.which == 107 || e.which == 109) { //+ || -
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    }
    onKeyUp(e) {
        if (!e.ctrlKey) return;
        if (e.which == 13) { //enter
            this.handleBlur();
            return;
        }

        if (e.which == 38 || e.which == 40 || e.which == 37 || e.which == 39 || e.which == 107 || e.which == 109) { //up || down || left || right || + || -
            this.saveEditorAndClose();
            switch (e.which) {
                case 38: //up arrow
                    this.props.focusConfig.setFocusRow(this.props.rowOrder - 1);
                    break;
                case 40: //down arrow
                    this.props.focusConfig.setFocusRow(this.props.rowOrder + 1);
                    break;
                case 37: //left arrow
                    this.props.focusConfig.setFocusColumn(this.props.columnOrder - 1);
                    break;
                case 39: //right arrow
                    this.props.focusConfig.setFocusColumn(this.props.columnOrder + 1);
                    break;
                case 107: //plus
                    e.preventDefault();
                    e.stopPropagation();
                    if(e.shiftKey) {
                        this.props.actions.addChild(this.props.id);
                    } else {
                        this.props.actions.addChild(this.props.parentId);
                    }

                    break;
                case 109: //minus
                    e.preventDefault();
                    e.stopPropagation();
                    this.props.actions.remove(this.props.id);
                    break;

            }
        }

    }
    render() {
        if (this.props.focus) {
            return (
                <TextField value={this.state.value || ''}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        draggable={true}
                        onDragStart={this.onDragStart}
                        onKeyUp={this.onKeyUp}
                        onKeyDown={this.onKeyDown}
                        rows={2} 
                        maxRows={4}
                        autoFocus
                        // variant="outlined"
                        {...this.props.additionalInputProps} />
            );
        } else {
            return (
                <div onClick={this.handleClick} {...this.props.additionalLabelProps} className="tree-grid-editable-cell-wrapper">
                    <TreeGridCell value={this.state.value} />
                </div>
            );
        }

    }
}

TreeGridCellEditable.propTypes = {
    value:PropTypes.any,
    prop:PropTypes.string,
    propChangeHandler:PropTypes.func,
    id:PropTypes.any,
    columnOrder: PropTypes.number,
    rowOrder: PropTypes.number,
    focus:PropTypes.bool,
    focusConfig: PropTypes.object,
    additionalInputProps:PropTypes.object,
    additionalLabelProps: PropTypes.object,
    actions: PropTypes.object,
    parentId:PropTypes.any
}

const mapStateToProps = (state, ownProps) => {
    let focusColumn = state[ownProps.focusConfig.stateKey][ownProps.focusConfig.focusColumnProperty];
    let focusRow = state[ownProps.focusConfig.stateKey][ownProps.focusConfig.focusRowProperty];
    let focus = focusColumn == ownProps.columnOrder && focusRow == ownProps.rowOrder;
    return {
        focus,
        ...ownProps
    }
};

export default connect(mapStateToProps)(TreeGridCellEditable);