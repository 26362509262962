import React from 'react'
import { Paper, Fade, LinearProgress, Fab, AccordionDetails, AccordionSummary, Accordion, FormControlLabel, Switch, TextField, Grid } from '@mui/material'

class TextFieldWithThrow extends React.PureComponent {
    constructor(props) {
      super(props);
    }
    render(){
        if(this.props.errors){
            var errorMessage = this.props.errors[this.props.name];
            if(errorMessage){
                let errorProps=  {
                    error: true,
                    id : "filled-error-helper-text",
                    label:"Error",
                    helperText: errorMessage,
                    variant:"filled"
                }
                return <TextField  {...this.props}{...errorProps} ></TextField>
            }
                
        }
        return(
            <TextField {...this.props}></TextField>
        )
    }
}
export default (TextFieldWithThrow)