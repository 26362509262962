import { getSelectedRangeIndex } from './scoreRangesUtil'

const calculateLevelScores = (levels, scoresConfigs, areas) => {
    
}

export const getAreasToLevelMap = (levels, scoresConfigs, areas) => {
    var result = [];
    // only areas that have level mapping calculated
    var areasToCheck = areas.filter(x=>x.targetConfigs && x.scoreConfigId);
    levels.forEach(level => {
        var levelInfo = { title: level.name, missingAreas: [], answers: []  }
        areasToCheck.forEach(a => {
            const targetLevel = a.targetConfigs.find(t => t.levelId == level.id);
            if(targetLevel){
                var calcData = { title: a.title, id: a.id, isRequired: targetLevel.isRequired, value: 0  };
                if (a.expertResponse && a.expertResponse.score){
                    // check if response same as expected

                    const scoreConfig = scoresConfigs.find(sc => a.scoreConfigId == sc.id);

                    if(a.expertResponse.score == 0){
                        levelInfo.answers.push(calcData);
                    } else {
                        const expectedRangeIndex = scoreConfig.ranges.findIndex(x=> x.id == targetLevel.minRangeId);

                        const currentRangeIndex = getSelectedRangeIndex(scoreConfig.ranges, a.expertResponse.score);
                        if(expectedRangeIndex == currentRangeIndex){
                            calcData.value = 1;
                        } else {
                            calcData.value = (1/scoreConfig.ranges.length)*currentRangeIndex;
                        }
                        levelInfo.answers.push(calcData);
                    }
                } else {
                    // missing response for level
                    levelInfo.missingAreas.push(calcData)
                }
            } 
        })
        result.push(levelInfo);
    });
    return result;
}