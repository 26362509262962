import * as groupsApi from '../../../api/interview/Groups';
import {
    setRequestError,
    setAppLoadingStarted,
    setAppLoadingFinished
} from '../../App/actions'

export const groupsListActions = {
    USER_GROUPS_LOAD: "USER_GROUPS_LOAD",
    SET_GROUP_NAME_IN_ROLES: "SET_GROUP_NAME_IN_ROLES"
}
export const setRolesGroupName = (groupName) => (dispatch) => {
    dispatch({type: groupsListActions.SET_GROUP_NAME_IN_ROLES, data: groupName})
}
export const loadGroups = () => (dispatch) => {
    dispatch(setAppLoadingStarted());
    groupsApi.Fetch().then(response => {
        if (!response.error) {
            dispatch({
                type: groupsListActions.USER_GROUPS_LOAD,
                data: response.data
            });
            dispatch(setAppLoadingFinished());
        } else {
            dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => loadGroups()));
        }
    }).catch(error => {
        dispatch(setRequestError("Error occured:" + error, () => loadGroups()));
    });
}

export const createGroup = (name) => (dispatch) => {
    dispatch(setAppLoadingStarted());
    groupsApi.Create({
            group: {
                Name: name
            }
        }).then(response => {
            if (!response.error) {
                dispatch(setAppLoadingFinished());
                loadGroups();
            } else {
                dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => createGroup()));
            }
        })
        .catch(error => {
            dispatch(setRequestError("Error occured:" + error, () => createGroup(name)));
        });
}