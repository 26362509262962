import React, { PureComponent } from 'react'
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { Typography } from '@mui/material/';
import PropTypes from 'prop-types';
import { getSelectedRange } from "../../../utils/scoreRangesUtil";

const styles= (theme) => ({
	notAked: {
		color: theme.palette.grey[400]
	},
	definedScore: {
	}
})

class ScoreDetails extends PureComponent {
	constructor(props){
		super(props)
	}

	render(){
		const { classes } = this.props;
		const selectedRegion = getSelectedRange(this.props.ranges, this.props.score);
		const className = this.props.notAsked ? classes.notAked : classes.definedScore;
		const scoreInfo =  <Typography 
								variant="subtitle1"
								className={className}
							>
								{selectedRegion ? selectedRegion.title : this.props.score}
							</Typography>;
		return (scoreInfo);
	}
}

const mapStateToProps = (state, ownProps) => {
	const area = state.preInterviewComponent.areas.find(area=>{
		return area.id == ownProps.id
	});
	const candidateResponse = area.candidateResponse || {};
	return {
		areaId: ownProps.id,
		score: candidateResponse.notAsked ? -1 : candidateResponse.score,
		ranges: state.preInterviewComponent.scoresGroup ? 
				[{ title: "Not asked", bottom: -1, top: -1 },...state.preInterviewComponent.scoresGroup.ranges] : [],
		notAsked: candidateResponse.notAsked,
		candidateScore: 0
	}
}
const mapDispatchToProps = () => ({
});

export default withStyles(styles, {withTheme:true})(connect(mapStateToProps, mapDispatchToProps)(ScoreDetails));

ScoreDetails.propTypes = {
	classes: PropTypes.object,
	areaId: PropTypes.string,
	ranges: PropTypes.array,
	score: PropTypes.number,
	candidateScore: PropTypes.number,
	notAsked: PropTypes.bool
}