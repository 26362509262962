import {initialState as storeInitialState} from './reducer'
import { setRequestError, setAppLoadingStarted, setAppLoadingFinished } from '../App/actions'
import * as canidatesApi from '../../api/interview/Candidates'
import history from '../../utils/history'
import {candidateValidationRules} from './validate'
import {validateObject} from '../../utils/helpers'

export const candidateEditorActions = {
    CANDIDATE_SET_DATA: 'CANDIDATE_SET_DATA',
    CANDIDATE_SET_ERRORS: 'CANDIDATE_SET_ERRORS'
};

export const setCandidateData = (data) => ({ type: candidateEditorActions.CANDIDATE_SET_DATA, data });
export const setErrors = (errors) => ({type:candidateEditorActions.CANDIDATE_SET_ERRORS,errors});


export const getCandidate = (id) => (dispatch) => {
    if (!id) {
        dispatch(setCandidateData(storeInitialState));
        return;
    }
    dispatch(setAppLoadingStarted());
    canidatesApi.Get(id)
        .then(response => {
           
            if (!response.error) {
                const responseBody = response.data;
                const { data } = responseBody;
                dispatch(setCandidateData(data));
                dispatch(setAppLoadingFinished());
            } else {
                dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => getCandidate(id)));          
            }
        })
        .catch(error => {
            dispatch(setRequestError("Error occured:" + error, () => getCandidate()));
        });
};

export const saveCandidate = () =>  (dispatch,getState) => {
    
    let candidateData = getState().candidateEditor;
    let validationResult = validateObject(candidateValidationRules,candidateData);
    if(!validationResult.isValid) {
        dispatch(setErrors(validationResult.errors));
        return;
    }

    dispatch(setAppLoadingStarted());
    canidatesApi.Upsert({
        candidate: candidateData
    }).then((response) => {
            if (!response.error) {
                dispatch(setAppLoadingFinished());
                history.push('/candidates');
            } else {
                if(response.raw.status == 400) {
                    dispatch(setErrors(response.data.body.error.errors));
                    dispatch(setAppLoadingFinished());
                } else {
                    dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => saveCandidate()));
                }
            }
        })
        .catch((error) => {
            dispatch(setRequestError("Error occured:" + error, () => saveCandidate()));
        })
};