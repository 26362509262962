import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TreeGridMaterialGrid from './Renderers/TreeGridMaterialGrid'
import { Divider } from '@mui/material/';
import isEqual from 'react-fast-compare'
import { objectWithoutProperties, sortBy } from '../../../utils/helpers'
import TreeGridCell from './Renderers/TreeGridCell';
import TreeGridActionCell from './Renderers/TreeGridActionCell';
import TreeDragMonitor from './TreeGridDragMonitor';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



class TreeRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: (props.expanded == null || typeof props.expanded == 'undefined') ? true : props.expanded,
            isDraggingOver: false,
            draggingOverLevel: 0,
            dragging: false
        };

        this.handleExpanderClick = this.handleExpanderClick.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onRowClick = this.onRowClick.bind(this);      
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(this.props.expanded != nextProps.expanded) {
            this.setState({
                expanded:nextProps.expanded
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextWithoutConfig = objectWithoutProperties(nextProps, ['config']);
        const prevWithoutConfig = objectWithoutProperties(this.props, ['config']);
        var result = !isEqual(this.state, nextState) || !isEqual(nextWithoutConfig, prevWithoutConfig);
        return result;
    }

    handleExpanderClick(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState((prevState) => {
            return {
                expanded: !prevState.expanded
            }
        });
    }


    children() {
        if (!this.props.childRows || this.props.childRows.length == 0 || (!this.state.expanded && !this.props.root)) {
            return (
                <React.Fragment />
            );
        }

        let draggingParents = this.props.draggingParents;

        if(this.props.lastChild || (!this.props.root && this.props.data.parentId==this.props.config.rootId)) {
            draggingParents = this.props.draggingParents + '_' + this.props.data.id;
        } else {
            draggingParents = '';
        }

        let nextLevel = this.props.level + 1;
        const rows = this.props.childRows.map((row,index) => {
            return (
                <ConnectedTreeRow root={false} 
                                level={nextLevel} 
                                key={`row-${row.id}`} 
                                data={row}
                                expanded = {this.props.expanded}
                                draggingParents={draggingParents}
                                lastChild={index==this.props.childRows.length-1}
                                config={this.props.config} />
            );
        });
        return rows;
    }

    expander() {
        let expanderClass = this.state.expanded ? 'expanded' : 'collapsed';
        expanderClass = ((this.props.childRows && this.props.childRows.length > 0) || (this.state.draggingOverLevel - this.props.level == 1)) ? expanderClass : 'leaf';

        return (
            <div className={`tree-grid-expander ${expanderClass}`} onClick={this.handleExpanderClick}>
                {
                    this.state.expanded ?
                    <FontAwesomeIcon icon={faAngleDown} size="sm" /> : <FontAwesomeIcon icon={faAngleRight} size="sm" />
                }
            </div>
        )
    }

    renderer(prop, columnOrder, Renderer, additionalInputProps, additionalLabelProps) {
        const value = this.props.data[prop];

        const rendererProps = {
            value: value,
            prop: prop,
            columnOrder: columnOrder,
            propChangeHandler: this.props.config.propChangeHandler,
            id: this.props.data.id,
            rowOrder: this.props.data.order,
            focusConfig: this.props.config.focus,
            additionalInputProps: additionalInputProps,
            additionalLabelProps: additionalLabelProps,
            actions: this.props.config.actions,
            parentId: this.props.data.parentId
        };

        if (!Renderer) {
            return (
                <TreeGridCell {...rendererProps} />
            );
        } else {
            return (
                <Renderer {...rendererProps} />
            )
        }
    }

    actionRenderer(Renderer) {
        if (!Renderer) {
            return (
                <TreeGridActionCell key={`tree-grid-action-cell-${this.props.data.id}`} actions={this.props.config.actions} id={this.props.data.id} />
            );
        } else {
            return (
                <Renderer actions={this.props.config.actions} id={this.props.data.id} />
            );
        }
    }

    data() {
        let columnConfigs = this.props.config.columnsConfigs;

        const columns = columnConfigs.map((config, index) => {
            const key = `column-${config.prop}-row-${this.props.data.id}`;
            if (!config.visible) {
                return (
                    <React.Fragment key={key} />
                );
            }

            if (config.actionColumn) {
                const actionKey = `column-action-row-asd-${this.props.data.id}`;
                return (
                    <TreeGridMaterialGrid style={{
                        display: "flex",
                        alignItems: "center"
                    }} xs={config.width} item key={actionKey}>
                        {this.actionRenderer(config.renderer)}
                    </TreeGridMaterialGrid>
                );
            }

            return (
                <TreeGridMaterialGrid style={{
                    display: "flex",
                    alignItems: "center"
                }} xs={config.width} item key={key}>
                    {index == 0 ? this.expander() : <React.Fragment />}
                    {this.renderer(config.prop, index, config.renderer, config.additionalInputProps, config.additionalLabelProps)}
                </TreeGridMaterialGrid>
            );
        });
        return columns;
    }

    onDragStart(e) {        
        TreeDragMonitor.setData("id", this.props.data.id);
        TreeDragMonitor.setData("x_coordinate", e.clientX);
        TreeDragMonitor.setData("order", this.props.data.order);
        TreeDragMonitor.setData("parent", this.props.data.parentId);
        TreeDragMonitor.setData("isLastChild", this.props.lastChild);
        TreeDragMonitor.setData("draggingParents", this.props.draggingParents);
        
        //hack for hiding the element being dragged
        setTimeout(() => {
            this.setState({ dragging: true });
        },0);

        e.stopPropagation();
    }
    onDragEnd() {
        this.setState({ dragging: false })
    }
    onDragOver(e) {
        const startAtX = TreeDragMonitor.getData("x_coordinate");
        const draggingId = TreeDragMonitor.getData("id");
        const oldParent = TreeDragMonitor.getData("parent");
        const draggableIsLastChild = TreeDragMonitor.getData("isLastChild");
        const draggabledraggingParents = TreeDragMonitor.getData("draggingParents");
        const lastDraggingOverLevel = TreeDragMonitor.getData("lastDraggingOverLevel");
        const lastDraggingOverId = TreeDragMonitor.getData("lastDraggingOverId");
        if(this.props.data.id == draggingId) {
            return false;
        }
        const currentX = e.clientX;

        const differenceX = currentX - startAtX;
        let draggingOverLevel = 1;
        if (differenceX >= 0) {
            let calculatedLevel = Math.floor(differenceX / this.props.config.paddingBase) + 1;
            draggingOverLevel = Math.min(this.props.level + 1, calculatedLevel);
        }
        if(draggingOverLevel == lastDraggingOverLevel && this.props.data.id == lastDraggingOverId) {
            e.stopPropagation();
            e.preventDefault();
            return true;
        }
        TreeDragMonitor.setData("lastDraggingOverLevel",draggingOverLevel);
        TreeDragMonitor.setData("lastDraggingOverId", this.props.data.id);

        const childRows = this.props.childRows.filter(ar => ar.id != draggingId);
        let newParent = oldParent;
        let isLastChild = this.props.lastChild;
        let draggingParentsStr = this.props.draggingParents;
        if(draggableIsLastChild && oldParent == this.props.data.parentId) { //if we are dragging the last child
            isLastChild = draggableIsLastChild;
            draggingParentsStr = draggabledraggingParents;
        }
        if(childRows.length>0) { //if row we dragging over has child - then we can only move current node to childs
            draggingOverLevel = this.props.level+1;
            newParent = this.props.data.id;
        } else if(draggingOverLevel<this.props.level) {
            if(isLastChild) { // we can move up in hierarchy only on last childs
                let draggingParents = draggingParentsStr.split('_');
                draggingOverLevel = Math.max(draggingOverLevel,this.props.level - draggingParents.length);
                let targetParentIndex = draggingParents.length - draggingOverLevel + 1;
                newParent = draggingParents[draggingParents.length - targetParentIndex];
            } else { // if row we dragging over is not the last child - then we can move only on same level or +1 (+1 handled directly by formula in difference above)
                draggingOverLevel = this.props.level;
                newParent = this.props.data.parentId;
            }
        } else if(draggingOverLevel == this.props.level) { //if dragging right below
            draggingOverLevel = this.props.level;
            newParent = this.props.data.parentId;
        } else if(draggingOverLevel > this.props.level) { // if dragging to the child 
            draggingOverLevel = this.props.level+1;
            newParent = this.props.data.id;
        }
        TreeDragMonitor.setData('newParent',newParent);

        if (this.props.data.id != draggingId) {
            this.setState((prevState) => {
                var newState = {};
                if (!prevState.isDraggingOver) {
                    newState.isDraggingOver = true;
                }

                if (prevState.draggingOverLevel != draggingOverLevel) {
                    newState.draggingOverLevel = draggingOverLevel;
                }
                return newState;

            });
        }
        e.stopPropagation();
        e.preventDefault();
    }
    onDragLeave() {
        this.setState((prevState) => {
            if (prevState.isDraggingOver) {
                return {
                    isDraggingOver: false,
                    draggingOverLevel: 0
                }
            }
        });
        TreeDragMonitor.setData("lastDraggingOverLevel",0);
        TreeDragMonitor.setData("lastDraggingOverId", 0);
    }
    onDrop() {
        const draggingId = TreeDragMonitor.getData("id");
        const oldOrder = TreeDragMonitor.getData("order");
        const oldParent = TreeDragMonitor.getData("parent");
        const newParent = TreeDragMonitor.getData('newParent');
        let newOrder = this.props.data.order;
        if(newOrder<oldOrder) {
            newOrder++;
        }
        this.setState((prevState) => {
            if (prevState.isDraggingOver) {
                this.props.config.changeAreaPositionHandler(draggingId, newOrder, newParent, oldOrder, oldParent);
                return {
                    isDraggingOver: false,
                    draggingOverLevel: 0
                }
            }
        });
        TreeDragMonitor.setData("lastDraggingOverLevel",0);
        TreeDragMonitor.setData("lastDraggingOverId", 0);
    }

    onRowClick(event, target) {
        if(this.props.config && this.props.config.onRowClick){
            this.props.config.onRowClick(event, target, this.props.data);
        }
    }

    getKey() {
        return `row-dragging-container-${this.props.root ? 'root' : this.props.data.id}`;
    }
    render() {
        const me = this;
        const draggingClass = this.state.dragging ? "dragging" : "";
        const key = this.getKey();
        if (this.props.root) {
            return (
                <React.Fragment>
                    {this.children()}
                </React.Fragment>
            )
        }
        const selected = this.props.data.selected;
        const selectableClass = this.props.config.selectable ? 'tree-grid-row-selectable' : "";
        const selectedClassName = selected ? 'tree-grid-row-selected' : "";
        return (
            // <React.Fragment>
                <div id={key} className={`tree-grid-dragging-container ${draggingClass} ${selectableClass}`} key={key}>
                    
                    {/*Tr */}
                    <TreeGridMaterialGrid container key={`row-container-${this.props.root ? 'root' : this.props.data.id}`} className={selectedClassName}>
                        <div className={[`tree-grid-data-row tree-grid-data-row-${this.props.config.paddingBase}-${this.props.level}`]}
                                            draggable={this.props.config.dragDrop}
                                            onDragLeave={this.onDragLeave}
                                            onDragStart={this.onDragStart}
                                            onDragOver={this.onDragOver}
                                            onDrop={this.onDrop}
                                            onDragEnd={this.onDragEnd}
                                            onClick={this.onRowClick}
                                            >
                            {this.data()}
                        </div>
                    </TreeGridMaterialGrid>
                    {
                        this.state.isDraggingOver &&
                        <TreeGridMaterialGrid container>
                            <TreeGridMaterialGrid item xs={12}>
                                <div className={`tree-grid-data-row tree-grid-data-row-${this.props.config.paddingBase}-${this.state.draggingOverLevel}`}>
                                    <div className="tree-grid-drop-area">
                                        <div></div>
                                    </div>
                                </div>
                            </TreeGridMaterialGrid>
                        </TreeGridMaterialGrid>
                    }
                    <Divider />
                    {this.children()}
                </div>
            // </React.Fragment>
        )
    }
}

TreeRow.propTypes = {
    root: PropTypes.bool,
    level: PropTypes.number,
    childRows: PropTypes.array,
    data: PropTypes.object,
    config: PropTypes.object,
    key: PropTypes.string,
    classes: PropTypes.object,
    parents: PropTypes.string,
    draggingParents: PropTypes.any,
    lastChild: PropTypes.any,
    expanded: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
    let childRows = state[ownProps.config.stateKey][ownProps.config.dataKey].filter(d => d.parentId == (ownProps.root ? ownProps.config.rootId : ownProps.data.id))
        .sort(sortBy('order', false, null));
    return {
        childRows,
        ...ownProps
    }
};

var ConnectedTreeRow = connect(mapStateToProps, null, null, {
    areStatePropsEqual: (nextProp, prevProp) => {
        const nextWithoutConfig = objectWithoutProperties(nextProp, ['config']);
        const prevWithoutConfig = objectWithoutProperties(prevProp, ['config']);
        return isEqual(nextWithoutConfig, prevWithoutConfig);
    }
})(TreeRow);

export default ConnectedTreeRow;