import {assignInterviewActions} from './actions'
import {profileSelectActions} from '../../../common/components/ProfileSelect/actions'
import {candidateViewActions} from '../../CandidateView/actions'

export const initialState = {
    isLoading: false,
    interviewName: '',
    errors: {},
    selectedProfile: 0,
    candidateDisplayName: ''
}

export const assignInterviewComponent = (state = initialState, action) => {
    switch (action.type) {
        case assignInterviewActions.ASSIGN_INTERVIEW_SET_DATA:
            return Object.assign({},state, action.data);
        case assignInterviewActions.ASSIGN_INTERVIEW_SET_ERRORS:
            return Object.assign({},state,{errors:action.errors});
        case profileSelectActions.PROFILE_SELECT_SET_DATA:
            if(action.data) {
                if(action.data.selected && action.data.selected !== 0 && (state.selectedProfile === 0 || state.selectedProfile.value != action.data.selected.value)) {
                    return Object.assign({},state, {interviewName:`${state.candidateDisplayName}-${action.data.selected.label}`, selectedProfile:action.data.selected});
                } else {
                    return Object.assign({},state, {selectedProfile:action.data.selected || 0});
                }
            }
            return state;
        case candidateViewActions.CANDIDATE_VIEW_SET_DATA:
            if(action.data && action.data.id && action.data.id !==0) {
                return Object.assign({},state, {candidateDisplayName:`${action.data.name} ${action.data.lastName}`});
            }
            return state;
        default:
            return state;
    }
};