import { profileScoresActions } from './actions'

const initialState = {
	profileId: 0,
	assignedId: 0,
	availableGroups: []
}

export const scoresSelector = (state = initialState, action) =>{
	switch (action.type){
		case profileScoresActions.PROFILE_EDITOR_GROUPS_LOADED:
            return Object.assign({}, state, action.data );
        case profileScoresActions.PROFILE_EDITOR_GROUP_ASSIGNED:
			return Object.assign({}, state, action.data);
		default:
			return state;
	}
}