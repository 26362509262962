import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

// const PREFIX = 'ScoreChip';

// const classes = {
//     scoreHighlight: `${PREFIX}-scoreHighlight`,
//     deselected: `${PREFIX}-deselected`
// };

// const StyledChip = styled(Chip)({
// 	// style rule
// 	[`& .${classes.scoreHighlight}`]: props => ({
// 	  backgroundColor: props.selectedColor,
//       height: "auto",
// 	//   color: "white"
// 	}),
// 	[`& .${classes.deselected}`]: {
// 		backgroundColor: "#ccc"
// 	}
// });

const ScoreChip = ({ title, selectedColor, className, size }) => {


    return (
        <Chip 
                    label={title}
                    variant="outlined"
					sx={{
						backgroundColor: selectedColor,
						paddingLeft: "4px",
						paddingRight: "4px"

					}}
					size={size ?? "small"}
                    className={`${className}`}
                    >
                </Chip>
    );
}

export default ScoreChip;

ScoreChip.propTypes = {
	title: PropTypes.string,
	selectedColor: PropTypes.string
};