import makeStyles from '@mui/styles/makeStyles';
import { Button, Chip, Tooltip  } from '@mui/material';
import React from 'react';


const useStyles = makeStyles({
	// style rule
	selectedButton: props => ({
	  backgroundColor: props.selectedColor,
	//   color: "white"
	}),
	deselected:{
		backgroundColor: "#ccc"
	},
	noAnswer: {
		opacity:"0.7",
		background: "none"
	},
	isSuggestion: {
		opacity:"0.7"
	},
	icon: {
		color: "black"
	},
	action: {
		cursor: "pointer"
	}
});

const ScoreButton = ({ title, hasAnswer, selectedColor, className, isSuggestion }) => {
	const classes = useStyles({ selectedColor});

	return <Tooltip title={isSuggestion ? "Suggested calculated score" : "Expert score"}>
			<Chip 
				// size="small"
				icon={isSuggestion ? <i className={`fas fa-lightbulb ${classes.icon}`} /> : undefined }
				label={title}
				sx={{
					backgroundColor: selectedColor ? selectedColor : "default",
					height: "auto"
					// paddingLeft: "4px",
					// paddingRight: "4px"

				}}
				// className={`${hasAnswer ? classes.selectedButton : classes.noAnswer} ${className} ${isSuggestion ? classes.isSuggestion : ''} ${classes.action}` } 
				// variant="outlined"
				// onClick={props.onClick}
				>
			</Chip>
		</Tooltip>
}

export default ScoreButton;