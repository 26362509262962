/** @module Candidates */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * @param {number} id 
 * @return {Promise<module:types.ApiResponseViewModel[CandidateBusinessModel]>} Success
 */
export function Get(id) {
  const parameters = {
    path: {
      id
    }
  }
  return gateway.request(GetOperation, parameters)
}

/**
 * @param {number} id 
 * @return {Promise<module:types.ApiResponseViewModel[OperationResult[Boolean]]>} Success
 */
export function Delete(id) {
  const parameters = {
    path: {
      id
    }
  }
  return gateway.request(DeleteOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {module:types.CandidateBusinessModel} [options.candidate] 
 * @return {Promise<module:types.ApiResponseViewModel[CandidateBusinessModel]>} Success
 */
export function Upsert(options) {
  if (!options) options = {}
  const parameters = {
    body: {
      candidate: options.candidate
    }
  }
  return gateway.request(UpsertOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {string} [options.searchTerm] 
 * @param {number} [options.pageNumber] 
 * @param {number} [options.pageSize] 
 * @return {Promise<module:types.ApiResponseViewModel[PagedResult[CandidateBusinessModel]]>} Success
 */
export function Search(options) {
  if (!options) options = {}
  const parameters = {
    query: {
      searchTerm: options.searchTerm,
      pageNumber: options.pageNumber,
      pageSize: options.pageSize
    }
  }
  return gateway.request(SearchOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {string} [options.searchTerm] 
 * @return {Promise<module:types.ApiResponseViewModel[PagedResult[CandidateBusinessModel]]>} Success
 */
export function SearchByName(options) {
  if (!options) options = {}
  const parameters = {
    query: {
      searchTerm: options.searchTerm
    }
  }
  return gateway.request(SearchByNameOperation, parameters)
}

const GetOperation = {
  path: '/api/Candidates/Get/{id}',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const DeleteOperation = {
  path: '/api/Candidates/Delete/{id}',
  method: 'delete',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const UpsertOperation = {
  path: '/api/Candidates',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const SearchOperation = {
  path: '/api/Candidates/Search',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const SearchByNameOperation = {
  path: '/api/Candidates/SearchByName',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}
