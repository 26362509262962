import { groupsListActions } from './actions'

export const initialState = {
	groups: []
}

export const userGroupsList = (state = initialState, action) => {
	switch (action.type){
		case groupsListActions.USER_GROUPS_ADD:
			return  Object.assign({}, state, { groups: state.groups.concat(action.data) });
		case groupsListActions.USER_GROUPS_LOAD:
			return  Object.assign({}, state, { groups: action.data });
		default:
			return state;
	}
}