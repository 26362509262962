import { rolesActions } from './actions'

export const initialState = {rolesList :[], groupId: 0};

export const rolesComponent = (state = initialState, action) => {
    switch (action.type) {
        case rolesActions.SET_GROUP_NAME_IN_ROLES:{
            return Object.assign({}, state, {groupName: action.data})
        }
        case rolesActions.ROLES_SET_DATA:
            return Object.assign({}, state, {rolesList: [...action.data.filter(role => {
                if(role.permissions)
                role.permissions = state.permissions.map(item => {
                    let hasPermission = false;
                    let permission = role.permissions.filter(t => t.id == item.id)[0];
                    if(permission){
                        hasPermission = true;
                        permission.name = item.name;
                        permission.enabled = true;
                        permission.groupName = item.groupName
                    }
                if(!hasPermission)
                    permission = {...item, enabled: false}
                    
                return permission;
                })
            return true;
            }
        ), {id:null, name:"", description:"", permissions: []}], groupId: action.data.groupId});
        case rolesActions.ROLES_UPDATE:
            return Object.assign({}, state, {rolesList: [...state.rolesList.map(role => {
                if(role.id == action.data.id){
                   return {...role, name : action.data.name, description : action.data.description, errors: action.data.errors}
                }
                return role;
            })]});
        case rolesActions.ROLES_DELETE:
            return Object.assign({},state, { rolesList: [...state.rolesList.filter(role => role.id != action.data)]});

        case rolesActions.SET_ALL_PERMISSIONS_DATA:
            return Object.assign({}, state, {permissions: action.data});
        case rolesActions.ROLE_ADD_PERMISSION:
            return Object.assign({}, state, {
                rolesList: state.rolesList.map(role => {
                    if(role.id == action.data.roleId)
                        return {...role, permissions: role.permissions.map(permission => {
                            if(permission.id == action.data.permissionId)
                                permission = {...permission, enabled : true};
                            return permission;
                        })}  
                    return role;
                })});
        case rolesActions.ROLE_REMOVE_PERMISSION:
            return Object.assign({}, state, { rolesList: state.rolesList.map(role => {
                if(role.id == action.data.roleId)
                        return {...role, permissions: role.permissions.map(permission => {
                            if(permission.id == action.data.permissionId)
                                permission = {...permission, enabled : false};
                            return permission;
                        })}  
                    return role;
            })})
        default:
            return state;
    }
}