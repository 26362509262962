import {interviewActions} from './actions'
import {candidateSelectActions} from '../../common/components/CandidateSelect/actions'

export const initialState = {
    id:0,
    profileId: 0,
    versionId: 0,
    selectedCandidate: null,
    areas: [],
    profileName: "",
    interviewName: "",
    interviewSummary: "",
    levelId:0,
    levelName:"",
    scoresGroup: null,
    levels: [],
    focusRow: null,
    focusColumn: 0,
    completedDate: null,
    isTitleSaving: false,
    isLevelSaving: false
};

export const interviewComponent = (state = initialState, action) => {
    switch (action.type) {
        case interviewActions.INTERVIEW_SET:
            return Object.assign({}, state, action.data);
        case interviewActions.INTERVIEW_AREA_EDIT:
            return Object.assign({}, state, {
                areas: state.areas.map(function (ar) {
                    if (ar.id == action.id) {
                        return Object.assign({},ar, action.data);
                    }
                    return ar;
                })
            });
        case interviewActions.INTERVIEW_AREA_EXPERT_EDIT:
            return Object.assign({}, state, {
                areas: state.areas.map(function (ar) {
                    if (ar.id == action.id) {
                        return Object.assign({},ar, {expertResponse : Object.assign({},ar.expertResponse || {},action.data)});
                    }
                    return ar;
                })
            });
        case interviewActions.INTERVEW_AREA_SELECTED:
            return Object.assign({}, state, {
                areas: state.areas.map(function (ar) {
                    if (ar.id == action.payload.id) {
                        return Object.assign({},ar, { selected: true });
                    } else {
                        return Object.assign({},ar, { selected: false });
                    }
                })
            });
        case interviewActions.INTERVIEW_SET_FOCUS_ROW:
            return Object.assign({}, state, {
                focusRow: action.order
            });

        case interviewActions.INTERVIEW_SET_FOCUS_COLUMN:
            return Object.assign({}, state, {
                focusColumn: action.column
            });
        case candidateSelectActions.CANDIDATE_SELECT_SET_DATA:
            return Object.assign({},state,{
                selectedCandidate: action.data.selected ? action.data.selected.value : null})
        default:
            return state;
    }
};