/** @module ScoreRange */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * @param {number} profileId 
 * @param {number} groupId 
 * @return {Promise<object>} Success
 */
export function AssignToProfile(profileId, groupId) {
  const parameters = {
    path: {
      profileId,
      groupId
    }
  }
  return gateway.request(AssignToProfileOperation, parameters)
}

/**
 * @param {number} profileId 
 * @return {Promise<module:types.ProfileScoreInfo>} Success
 */
export function ProfileScoresInfo(profileId) {
  const parameters = {
    path: {
      profileId
    }
  }
  return gateway.request(ProfileScoresInfoOperation, parameters)
}

/**
 */
export function Get() {
  return gateway.request(GetOperation)
}

/**
 * @param {object} options Optional options
 * @param {module:types.AddScoreGroup} [options.model] 
 * @return {Promise<module:types.ScoreRangeGroup>} Success
 */
export function Create(options) {
  if (!options) options = {}
  const parameters = {
    body: {
      model: options.model
    }
  }
  return gateway.request(CreateOperation, parameters)
}

/**
 * @param {number} id 
 * @return {Promise<module:types.ScoreRangeGroup>} Success
 */
export function GetById(id) {
  const parameters = {
    path: {
      id
    }
  }
  return gateway.request(GetByIdOperation, parameters)
}

/**
 * @param {number} id 
 * @param {object} options Optional options
 * @param {module:types.AddScoreGroup} [options.model] 
 * @return {Promise<module:types.ScoreRangeGroup>} Success
 */
export function Update(id, options) {
  if (!options) options = {}
  const parameters = {
    body: {
      model: options.model
    },
    path: {
      id
    }
  }
  return gateway.request(UpdateOperation, parameters)
}

/**
 * @param {number} id 
 * @return {Promise<object>} Success
 */
export function DeleteGroup(id) {
  const parameters = {
    path: {
      id
    }
  }
  return gateway.request(DeleteGroupOperation, parameters)
}

/**
 * @param {number} id 
 * @return {Promise<object>} Success
 */
export function DeleteRange(id) {
  const parameters = {
    path: {
      id
    }
  }
  return gateway.request(DeleteRangeOperation, parameters)
}

const AssignToProfileOperation = {
  path: '/ScoreRange/profileId/{profileId}/groupId/{groupId}',
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const ProfileScoresInfoOperation = {
  path: '/ScoreRange/profilescores/{profileId}',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const GetOperation = {
  path: '/ScoreRange',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const CreateOperation = {
  path: '/ScoreRange',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const GetByIdOperation = {
  path: '/ScoreRange/{id}',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const UpdateOperation = {
  path: '/ScoreRange/{id}',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'put',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const DeleteGroupOperation = {
  path: '/ScoreRange/{id}',
  method: 'delete',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const DeleteRangeOperation = {
  path: '/ScoreRange/range/{id}',
  method: 'delete',
  security: [
    {
      id: 'oauth2'
    }
  ]
}
