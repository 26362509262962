import React from 'react'
import PropTypes from 'prop-types'
import * as AssignInterviewActions from './actions'
import ProfileSelect from '../../../common/components/ProfileSelect'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import withStyles from '@mui/styles/withStyles';
import { initialState as storeInitialState } from './reducer'
import { Grid, Button, TextField} from '@mui/material';
import WithErrorMessage from '../../../common/components/WithErrorMessage';

const styles = () => ({
    interviewName: {
        minWidth:300
    },
    selectWrapper: {
        paddingTop:28
    },
    loader: {
        minHeight:100
    }
})

class AssignInterview extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        this.props.actions.setAssignInterviewData(storeInitialState);
    }

    handleInterviewNameChange = (e) => {
        this.props.actions.setAssignInterviewData({
            interviewName: e.target.value
        });
    }

    onAssign = (e) => {
        this.props.actions.assignInterviewSave(this.props.candidateId)
    }

    render() {
        const assignButtonEnabled = this.props.selectedProfile &&
            this.props.selectedProfile != 0 &&
            this.props.interviewName &&
            this.props.interviewName.trim() != '';
        const { classes } = this.props;
        return (
            <Grid item xs={12}>
                <Grid container spacing={0}>
                    <Grid item sm={6} xs={12}>
                        <WithErrorMessage error={this.props.errors['interviewName']}>
                            <TextField id="interview-name"
                                name="InterviewName"
                                label="Interview Name"
                                value={this.props.interviewName}
                                onChange={this.handleInterviewNameChange}
                                className= {classes.interviewName}
                                autoComplete='off'
                                margin="normal"
                                error={this.props.errors['interviewName'] && true} />
                        </WithErrorMessage>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <WithErrorMessage error={this.props.errors['selectedProfile']}>
                            <div className={classes.selectWrapper}>
                                <ProfileSelect
                                error={this.props.errors['selectedProfile'] && true}
                                />
                            </div>
                        </WithErrorMessage>
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end">
                    <Button
                        disabled={!assignButtonEnabled}
                        variant="contained"
                        color="primary"
                        onClick={this.onAssign}>
                        Assign
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

AssignInterview.propTypes = {
    interviewName: PropTypes.string,
    selectedProfile: PropTypes.any,
    candidateId: PropTypes.number,
    errors: PropTypes.object,
    classes: PropTypes.any,
    actions: PropTypes.any,
    isLoading: PropTypes.bool
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.assignInterviewComponent,
        candidateId: ownProps.candidateId
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(AssignInterviewActions, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AssignInterview));

