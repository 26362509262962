/** @module Interview */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * @param {number} id 
 * @return {Promise<module:types.ApiResponseViewModel[InterviewBusinessModel]>} Success
 */
export function Get(id) {
  const parameters = {
    path: {
      id
    }
  }
  return gateway.request(GetOperation, parameters)
}

/**
 * @param {number} profileId 
 * @param {number} candidateId 
 * @param {number} profileVersionId 
 * @param {string} interviewName 
 * @param {object} options Optional options
 * @param {number} [options.interviewerId] 
 * @return {Promise<module:types.ApiResponseViewModel[CandidateInterview]>} Success
 */
export function AssignInterview(profileId, candidateId, profileVersionId, interviewName, options) {
  if (!options) options = {}
  const parameters = {
    query: {
      profileId,
      candidateId,
      profileVersionId,
      interviewerId: options.interviewerId,
      interviewName
    }
  }
  return gateway.request(AssignInterviewOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {number} [options.interviewId] 
 * @return {Promise<module:types.ApiResponseViewModel[InvitationInfo]>} Success
 */
export function GenerateInvitation(options) {
  if (!options) options = {}
  const parameters = {
    query: {
      interviewId: options.interviewId
    }
  }
  return gateway.request(GenerateInvitationOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {number} [options.candidateId] 
 * @param {number} [options.pageNumber] 
 * @param {number} [options.pageSize] 
 * @return {Promise<module:types.ApiResponseViewModel[PagedResult[CandidateInterview]]>} Success
 */
export function GetCandidateInterviews(options) {
  if (!options) options = {}
  const parameters = {
    query: {
      candidateId: options.candidateId,
      pageNumber: options.pageNumber,
      pageSize: options.pageSize
    }
  }
  return gateway.request(GetCandidateInterviewsOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {module:types.SetScoreRequest} [options.model] 
 * @return {Promise<module:types.ApiResponseViewModel[OperationResult[Boolean]]>} Success
 */
export function SetScore(options) {
  if (!options) options = {}
  const parameters = {
    body: {
      model: options.model
    }
  }
  return gateway.request(SetScoreOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {string} [options.invitationKey] 
 * @return {Promise<module:types.ApiResponseViewModel[InterviewBusinessModel]>} Success
 */
export function GetPreInterview(options) {
  if (!options) options = {}
  const parameters = {
    query: {
      invitationKey: options.invitationKey
    }
  }
  return gateway.request(GetPreInterviewOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {module:types.UpdateInterviewRequest} [options.interviewModel] 
 * @return {Promise<module:types.ApiResponseViewModel[OperationResult[Boolean]]>} Success
 */
export function Save(options) {
  if (!options) options = {}
  const parameters = {
    body: {
      interviewModel: options.interviewModel
    }
  }
  return gateway.request(SaveOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {module:types.SetScoreRequest} [options.model] 
 * @return {Promise<module:types.ApiResponseViewModel[OperationResult[Boolean]]>} Success
 */
export function SetPreinterviewScore(options) {
  if (!options) options = {}
  const parameters = {
    body: {
      model: options.model
    }
  }
  return gateway.request(SetPreinterviewScoreOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {string} [options.invitationKey] 
 * @return {Promise<module:types.ApiResponseViewModel[OperationResult[Boolean]]>} Success
 */
export function SubmitPreInterview(options) {
  if (!options) options = {}
  const parameters = {
    query: {
      invitationKey: options.invitationKey
    }
  }
  return gateway.request(SubmitPreInterviewOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {number} [options.profileId] 
 * @param {number} [options.releasedVersionId] 
 * @return {Promise<module:types.ApiResponseViewModel[OperationResult[Int32]]>} Success
 */
export function StartInterview(options) {
  if (!options) options = {}
  const parameters = {
    query: {
      profileId: options.profileId,
      releasedVersionId: options.releasedVersionId
    }
  }
  return gateway.request(StartInterviewOperation, parameters)
}

const GetOperation = {
  path: '/api/Interview/Get/{id}',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const AssignInterviewOperation = {
  path: '/api/Interview/AssignInterview',
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const GenerateInvitationOperation = {
  path: '/api/Interview/GenerateInvitation',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const GetCandidateInterviewsOperation = {
  path: '/api/Interview/GetCandidateInterviews',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const SetScoreOperation = {
  path: '/api/Interview/SetScore',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const GetPreInterviewOperation = {
  path: '/api/Interview',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const SaveOperation = {
  path: '/api/Interview',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const SetPreinterviewScoreOperation = {
  path: '/api/Interview/SetPreinterviewScore',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const SubmitPreInterviewOperation = {
  path: '/api/Interview/SubmitPreInterview',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const StartInterviewOperation = {
  path: '/api/Interview/StartInterview',
  method: 'put',
  security: [
    {
      id: 'oauth2'
    }
  ]
}
