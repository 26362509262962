/** @module Compare */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * @param {object} options Optional options
 * @param {module:types.CompareRequest} [options.request] 
 * @return {Promise<object>} Success
 */
export function Compare(options) {
  if (!options) options = {}
  const parameters = {
    body: {
      request: options.request
    }
  }
  return gateway.request(CompareOperation, parameters)
}

const CompareOperation = {
  path: '/api/Compare',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}
