import React from 'react'
import PropTypes from 'prop-types'
import "./style.scss";

export default class WithErrorMessage extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <React.Fragment>
                {this.props.children}
                {this.props.error &&
                    <div>
                        <label className="error-message" align="left">
                            {this.props.error}
                        </label>
                    </div>
                }
            </React.Fragment>
        );
    }
}

WithErrorMessage.propTypes = {
    error: PropTypes.string,
    children: PropTypes.any
}