import * as candidateApi from '../../../api/interview/Candidates'
import { setRequestError } from '../../../screens/App/actions'

export const candidateSelectActions = {
    CANDIDATE_SELECT_SET_DATA: 'CANDIDATE_SELECT_SET_DATA'
}

export const candidateSelectSetData = (data) => ({type:candidateSelectActions.CANDIDATE_SELECT_SET_DATA, data});

export const searchCandidates = () => (dispatch,getState) => {
    const {candidateSelectComponent: candidateSelectComponent} = getState();
    if(candidateSelectComponent.isLoading) {
        return;
    }
    dispatch(candidateSelectSetData({isLoading:true}));
    const searchTerm = candidateSelectComponent.filterValue;
    const selected = candidateSelectComponent.selected;
    candidateApi.SearchByName({
        searchTerm
    }).then(response => {
        if (!response.error) {
            const responseBody = response.data;
            const { data } = responseBody;
            let isSelectedPresent = false;
            const options = data.map(el =>{
                if(selected !== 0 && el.id === selected.value) {
                    isSelectedPresent=true;
                } 
                return {
                    value:el.id,
                    label:el.fullName
                }
            });
            dispatch(candidateSelectSetData({
                options, 
                isLoading:false,
                selected: isSelectedPresent ? selected : 0
            }));

        } else {
            dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => searchCandidates(searchTerm)));
            dispatch(candidateSelectSetData({isLoading:false}));
        }
    }).catch(error => {
        dispatch(candidateSelectSetData({isLoading:false}));
        dispatch(setRequestError("Error occured " + error, () => searchCandidates(searchTerm)));
    })
}