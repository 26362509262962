import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './screens/App';
import reportWebVitals from './reportWebVitals';
import { Router } from 'react-router'
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import history from './utils/history';
import { additionalSetup } from './utils/helpers';
import { ThemeProvider, createTheme } from '@mui/material/styles';

additionalSetup();
const store = configureStore();
const theme = createTheme({
  typography: {
    // Tell Material-UI what the font-size on the html element is.
    // htmlFontSize: 16,
    // useNextVariants: true,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
