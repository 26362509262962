import React, { PureComponent } from 'react'
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Button, Popover } from '@mui/material/';
import { setScore } from "../actions";
import ScoreSelector from "../../../common/components/ScoreSelector/ScoreSelector"
import Loader from '../../Loader/'


const styles= (theme) => ({
	button: {
		margin: theme.spacing(1),
	},
	scoreWindow: {
		padding: "10px",
		position:'relative'
	},
	scoreWindowCloseButton: {
		margin: "10px 2px 0px 2px"
	},
	loader: {
		left:0,
		top:0
	}
})

class ScoreSetAction extends PureComponent{
	constructor(props){
		super(props);
		this.state = {
			anchorEl: null,
			score: this.props.score,
			comment: this.props.comment,
			loading: false
		}
	}

	scoreChange = (value) =>{
		this.setState({score:value})
	}

	commentChange = (event) => {
		this.setState({comment:event.target.value})
	}

	openEditDialog = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	}

	cancel = () => {
		this.setState({ anchorEl: null});
	}

	saveData = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if(!this.state.loading) {
			this.setState({loading:true},() => {
				const currentState = {...this.state};
				const notAsked = currentState.value == -1;

				this.props.setScore(this.props.interviewId,this.props.areaId, { 
					id: this.props.areaId, 
					comment: currentState.comment == null ? null : currentState.comment.trim(), 
					score: currentState.score, 
					notAsked 
				})
				.then(result => {
					if(result) {
						this.setState({loading:false,anchorEl:null});
					} else {
						this.setState({loading:false});
					}
				}, () => {
					this.setState({loading:false});
				})
			});
		}

		return false;
	}

	render(){
		const { classes } = this.props;
		const dialog = this.state.anchorEl ? 
			<Popover
					id="compare-popper"
					// open={open}
					anchorEl={this.state.anchorEl}
					onClose={this.saveData}
					anchorOrigin={{
						vertical: 'center',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'center',
						horizontal: 'left',
					}}
				>
					<div className={classes.scoreWindow}>
						<Loader show={this.state.loading} className={classes.loader}/>
						<ScoreSelector 
							currentScore={this.state.score} 
							enableSlider={true}
							enableComment={true}
							ranges={this.props.ranges}
							comment={this.state.comment}
							onCommentChange={this.commentChange}
							onScoreChange={this.scoreChange}
						/>
						<div>
							<Button 
									variant="outlined" 
									size="small" 
									color="primary" 
									className={classes.scoreWindowCloseButton}
									onClick={this.saveData}
								>
								Ok
							</Button>
							<Button 
									variant="outlined" 
									size="small" 
									color="secondary" 
									className={classes.scoreWindowCloseButton}
									onClick={this.cancel}
								>
								Cancel
							</Button>
						</div>
					</div>
				</Popover>
				:
				""
				;
		return (
				<React.Fragment>
					<Button 
						variant="outlined" 
						className={classes.button}
						onClick={this.openEditDialog}
					>
						Set
					</Button>
					{dialog}
				</React.Fragment>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	const area = state.preInterviewComponent.areas.find(area=>{
		return area.id == ownProps.id
	});
	const candidateResponse = area.candidateResponse || {};
	return {
		interviewId: state.preInterviewComponent.id,
		areaId: ownProps.id,
		score: candidateResponse.notAsked ? -1 : candidateResponse.score,
		ranges: state.preInterviewComponent.scoresGroup ? 
				[{ title: "Not asked", bottom: -1, top: -1 },...state.preInterviewComponent.scoresGroup.ranges] : [],
		comment: candidateResponse.comment
	}
}
const mapDispatchToProps = (dispatch) => ({
	setScore: (interviewId, id, data) => dispatch(setScore(interviewId,id, data))
});

export default withStyles(styles, {withTheme:true})(connect(mapStateToProps, mapDispatchToProps)(ScoreSetAction));

ScoreSetAction.propTypes = {
	classes: PropTypes.object,
	setScore: PropTypes.func,
	areaId: PropTypes.string,
	comment: PropTypes.string,
	ranges: PropTypes.array,
	score: PropTypes.number,
	notAsked: PropTypes.bool,
	interviewId: PropTypes.number
}