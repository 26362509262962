import React, { PureComponent, useState, useEffect } from 'react'
import { TextField, Select, InputLabel, FormControl, MenuItem, Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme) => ({
    interviewName: {
        width: "100%"
    },
    loaderWrapper: {
        position: 'relative'
    },
    titleLoader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: "-17px",
        marginLeft: "-17px"
    },
    selectLoader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: "-20px",
        marginLeft: "-20px"
    }
}))

const SummarySection = ({ levels, levelId, title, isTitleSaving, isLevelSaving, onChangeTitle, onChangeLevel }) => {
    const [titleText, setTitleText] = useState(title);

    useEffect(()=> {
        setTitleText(title);
    }, [title])
    
    const classes = useStyles({});

    return <>
        <Box className={classes.loaderWrapper}>
            <TextField variant="outlined" id='interviewName' name="interviewName" label="Interview Name" value={titleText}
                        disabled={isTitleSaving}
                        onChange={(e) => setTitleText(e.target.value)} 
                        onBlur={() => {
                            if(titleText != title)
                                onChangeTitle(titleText)
                        }} 
                        autoComplete='off' margin="normal" 
                        className={classes.interviewName} 
                        onKeyPress={(event) => {
                            if(event.key == "Enter" && titleText != title) {
                                onChangeTitle(titleText);
                            }
                        }}
                        />
            {isTitleSaving && <CircularProgress className={classes.titleLoader} />}
        </Box>
        <FormControl variant="outlined" style={{width: "100%", marginTop: "15px"}} className={classes.loaderWrapper}>
                    <InputLabel id="label-for-level" htmlFor="levelId">Candidate level</InputLabel>
                    <Select
                        disabled={isLevelSaving}
                        label="Candidate level"
                        labelId="label-for-level"
                        value={levelId == null ? "" : levelId }
                        name="levelId"
                        onChange={e =>{ 
                            var x= e;
                            onChangeLevel(e.target.value)
                        }}
                        // error={this.props.levelError}
                    >
                        {levels.map(level => {
                            return (
                                <MenuItem
                                    key={level.id}
                                    value={level.id}
                                >
                                    {level.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {isLevelSaving && <CircularProgress className={classes.selectLoader} />}
                </FormControl>
    </>
}

export default SummarySection

// class SummarySection extends PureComponent {
//     constructor(props) {
//         super(props);
//     }

//     handleChange = (event) => {
//         const data = {
//             summary: this.props.summary,
//             levelId: this.props.levelId
//         };
//         Object.assign(data,{
//             [event.target.name]: event.target.value,
//         });
//         this.props.resetLevelError();
//         this.props.onChange(data.levelId, data.summary);
//     }

//     render() {
//         return (
//             <>
//                 {/* <FormControl style={{width: "100%" }}>
//                     <TextField
//                         id="summary"
//                         label="Summary"
//                         name="summary"
//                         variant="outlined"
//                         value={this.props.summary == null ? "" : this.props.summary}
//                         fullWidth={true}
//                         multiline={true}
//                         rowsMax={3}
//                         rows={3}
//                         onChange={e => this.handleChange(e)}
//                     />
//                 </FormControl> */}

                
//             </>
//         );
//     }
// }

// export default SummarySection;

// SummarySection.propTypes = {
//     summary: PropTypes.string,
//     levelId: PropTypes.number,
//     onChange: PropTypes.func,
//     resetLevelError: PropTypes.func,
//     levelError: PropTypes.any,
//     levels: PropTypes.array
// };