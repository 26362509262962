import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { IconButton, Badge, Popover, Icon, Typography, Divider, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons'
import history from "../../../utils/history"
// import getMuiTheme from 'material-ui/core/styles/getMuiTheme';
import * as CompareActions from "../../App/Compare/actions"
import { bindActionCreators } from 'redux'

const styles = theme => ({
	comparePopupRoot: {
		flexGrow: 1,
		paddingRight: theme.spacing(2),
		marginRight: theme.spacing(2)
	},
	comparePopupList: {
	},
	compareIcon: {
		display: 'inline',
		marginLeft: theme.spacing(2)
	},
	compareItemRow: {
		// color: getMuiTheme().listItem.secondaryTextColor TODO: Can't resolve 'material-ui/core/styles/getMuiTheme' after update of material ui core
	},
	removeIcon: {
		display: 'inline',
		marginLeft: theme.spacing(2)
	},
	title: {
		marginLeft: theme.spacing(2),
		display: 'inline'
	}
})

class CompareBucketButton extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			anchorEl: null
		}
	}

	handleCompare = (event, id) => {
		history.push('/interviews/compare/' + id);
	}

	handleMenu = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	}
	handleClose = () => {
		this.setState({ anchorEl: null });
	}
	handleRemoveSummaryFromCompare = (event, id) => {
		this.props.compareActions.removeSummaryFromCompareList(id);
	}
	handleRemoveProfileFromCompare = (event, id) => {
		this.props.compareActions.removeProfileFromCompareList(id);
	}

	getNormalizedSummaries() {
		var result = [];
		for (var item of this.props.list) {
			var existingItem = result.find((x) => x.profileId == item.profileId);
			var summary = { id: item.Id, title: item.Title };
			if (existingItem) {
				existingItem.summaries.push(summary);
			} else {
				result.push({
					profileId: item.profileId,
					name: item.profileName,
					summaries: [
						summary
					]
				})
			}
		}
		return result;
	}
	getCartContent2() {
		const { classes } = this.props;
		const profiles = this.getNormalizedSummaries();
		const cartItems = profiles.map((profile) => {

			const secondaryItems = profile.summaries.map((summary) => {
				return (
                    <Fragment
                        key={summary.id}>
                        <Grid item xs={12}>
                            <IconButton
                                className={classes.removeIcon}
                                onClick={(event) => this.handleRemoveSummaryFromCompare(event, summary.id)}
                                size="large">
                                <Icon fontSize="small">clear</Icon>
                            </IconButton>
                            <Typography className={classes.title} variant="subtitle2">
                                {summary.title}
                            </Typography>
                        </Grid>
                    </Fragment>
                );
			})

			return (
                <Fragment key={profile.profileId + "fragment"}>
                    <Grid item xs={12}>
                        <IconButton
                            className={classes.removeIcon}
                            onClick={(event) => this.handleRemoveProfileFromCompare(event, profile.profileId)}
                            size="large">
                            <Icon fontSize="small">clear</Icon>
                        </IconButton>
                        <Typography className={classes.title} variant="subtitle1">
                            {profile.name}
                        </Typography>
                        <IconButton
                            className={classes.compareIcon}
                            value={profile.profileId}
                            onClick={(event) => this.handleCompare(event, profile.profileId)}
                            size="large">
                            <FontAwesomeIcon icon={faBalanceScale} size="sm" />
                        </IconButton>
                    </Grid>
                    {secondaryItems}
                    <Divider />
                </Fragment>
            );
		});

		return (<div classes={classes.comparePopupRoot}>
			{cartItems}
		</div>)
	}

	render() {
		const open = Boolean(this.state.anchorEl);
		const { classes } = this.props;
		const noCompareItems = this.props.list.length == 0;
		const cartContent = this.getCartContent2();

		return (
            <div>
				<IconButton
                    color="inherit"
                    aria-owns={open ? "compare-popper" : null}
                    aria-haspopup="true"
                    variant="contained"
                    onClick={this.handleMenu}
                    disabled={noCompareItems}
                    size="large">
					<Badge color="secondary" invisible={noCompareItems} badgeContent={this.props.list.length}>
						<FontAwesomeIcon icon={faBalanceScale} size="xs" />
					</Badge>
				</IconButton>
				<Popover
					id="compare-popper"
					open={open}
					anchorEl={this.state.anchorEl}
					onClose={this.handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
				>
					{cartContent}
				</Popover>
			</div>
        );
	}
}

const mapStateToProps = (state) => {
	return {
		list: state.interviewsCompareList.list
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		compareActions: bindActionCreators(CompareActions, dispatch)
	};
};

CompareBucketButton.propTypes = {
	classes: PropTypes.object,
	list: PropTypes.array,
	compareActions: PropTypes.object,
}

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(CompareBucketButton)));