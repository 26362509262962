import React, { Component, Fragment, useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { Grid, Fab, Divider, TextField, Select, Button, TableHead, Icon, FormControl, Box, InputLabel, Table, TableRow, TableCell, TableBody, Typography, Link } from '@mui/material'
import HelpButton from '../../common/components/IconButtons/HelpButton'
import DeleteButton from '../../common/components/IconButtons/DeleteButton'
import AddIconButton from '../../common/components/IconButtons/AddIconButton'
import TargetLevel from './AreaConfigEditors/TargetLevel'

export default ({ id, title, description, onDescriptionChange, onTitleChange, onScoreConfigChange, onAddTarget, scoreConfigs, scoreConfigId, isSaving, targetConfigs, 
	targetLevel, onTargetChange, onDeleteTarget }) =>{
    const theme = useTheme();
    const useStyles = makeStyles({
		descriptor: {
			display: "flex",
			// paddingBottom: theme.spacing(1),
			width: "100%",
			paddingTop: theme.spacing(1)
		},
		panelItem: {
			flexGrow: 1,
			fontSize: "14px"
		},
		font12: {
			fontSize: "12px"
		},
		scoreDetails:{
			flexDirection: "column",
			"& .MuiTableCell-root": {
				padding: "2px"
			},
			padding: "10px"
		},
		bold: {
			fontWeight: "bold"
		},
		configTitleArea: {
			display: "flex",
			alignItems: "middle",
		},
		configTitle: {
			fontWeight: 500,
			flexGrow: 1
		},
		select: {
			fontSize: "14px"
		},
		wrapper: {
			borderBottom: "1px dashed #ccc",
			padding: "10px",
			display: "flex",
    		justifyContent: "space-between",
			flexGrow: 1
		},
		iconWrapper: {
			alignSelf: "center"
		}
	})
	const classes = useStyles();
	const [ text, setText ] = useState(description);
	const [ hideDetails, setHideDetails] = useState(true);
	// const [ loaded, setLoaded ] = useState(false);
	// setText(description);
	useEffect(() =>{
		setText(description);
	}, [description])

	const [ titleText, setTitleText ] = useState(title)
	useEffect(() => {
		setTitleText(title);
	}, [title])
	const details = hideDetails ? "Show details" : "Hide details";
	const targetConfig = scoreConfigs.find(x=>x.id == scoreConfigId);
	const config = targetConfig ?? scoreConfigs[0];
    return <>
            <Box className={classes.descriptor}>
				<TextField
						disabled={isSaving}
						label="Title"
						multiline
						value={titleText}
						onChange={(event)=>{
							setTitleText(event.target.value);
						}}
						onKeyDown={(event) => { 
							if(event.key == "Escape"){
								event.target.value = title;
							}
							}}
						onBlur={(event) => { 
							if(onTitleChange && title != event.target.value){
								onTitleChange(event.target.value)
							}
						}}
						margin="normal"
						variant="outlined"
						className={classes.panelItem}
					/>
			</Box>
			<Box  className={classes.descriptor}>
				<TextField
					disabled={isSaving}
					label="Description"
					multiline
					maxRows="4"
					// rows="4"
					value={text}
					onChange={(event)=>{
						setText(event.target.value);
					}}
					onKeyDown={(event) => { 
						if(event.key == "Escape"){
							event.target.value = description;
						}
						}}
					onBlur={(event) => { 
						if(onDescriptionChange && description != event.target.value){
							onDescriptionChange(event.target.value)
						}
					}}
					margin="normal"
					variant="outlined"
					className={classes.panelItem}
				/>
			</Box>
			<Box  className={classes.scoreDescriptior}>
				<Box className={classes.configTitleArea}>
					<Typography className={classes.configTitle}>Evaluation score</Typography>
					<HelpButton title={"Help"}>
						<p><span className={classes.bold}>Evaluation score - </span> select group of scores that can be set to evaluate candidate answer</p>
					</HelpButton>
				</Box>
				<Divider />
				<Box className={classes.descriptor}>
					<FormControl className={classes.panelItem}>
						<Select
							label="Evaluation type"
							native
							value={scoreConfigId}
							onChange={(event) => {
								if(onScoreConfigChange){
									onScoreConfigChange(event.target.value)
								}
							}}
							className={classes.panelItem}
							>
								{scoreConfigs.map(x=> 
									<option key={"list-item" + x.id} value={x.id}>{x.name}</option>
								)}
						</Select>
					</FormControl>
					<HelpButton title={"Scores details"}>
						<Box>
								{scoreConfigs.map(x=> 
									<p key={"help-d-" + x.id} value={x.id}>
										<span className={classes.bold}>{x.name} - </span>{x.description}
									</p>
								)}
								<Link href="/scoresdetails" target="_blank" rel="noopener">More details</Link>
						</Box>
					</HelpButton>
				</Box>
			</Box>
			<Box className={[classes.descriptor, classes.scoreDetails].join(" ")}>
			{config && !hideDetails &&
					<Box>
						<Box><span className={classes.bold}>Evaluation type:</span> {config.evaluationQuestion}</Box>
						<Box>Scores:</Box>
						<Table >
							<TableBody>
								{config.ranges.map(x=>{
									return <TableRow>
										<TableCell>{x.title}</TableCell>
										<TableCell>{x.description}</TableCell>
									</TableRow>
								})}
							</TableBody>
						</Table>
					</Box>
				}
			</Box>
			{targetConfig && 
				<Box className={classes.targetLevels}>
					<Box className={classes.configTitleArea}>
						<Typography className={classes.configTitle}>Target levels</Typography>
						<HelpButton title={"Help"}>
							<p><span className={classes.bold}>Target levels and minimum score - </span> needed to be set to add suggestion for interviewer what minumum score expected for specific target level. 
							For example candidate should have good OOP understanding for Junior position. Candidate should have Common skills in building rest services for Intermidiate
							position. 
							</p>
							<p>
								All areas that have target level set will be part of <span className={classes.bold}>automatic target level calcuation</span>. For example if profile has 10 areas required for Senior level and 8 of 10 
								have enaugh score set, candidate automatic score will be ~80% of senior level.
							</p>
							<p><span className={classes.bold}>Required areas without answers count as 0% skills in it. </span> Non required areas can be skipped without penalty</p>
						</HelpButton>
					</Box>
					<Divider />
					<Box className={classes.configItems}>
						{(targetLevel.levels ?? []).map((lvl, index) => {

							var tc = (targetConfigs ?? []).find(x=>x.levelId == lvl.id);

								return <Box className={classes.wrapper}>
									<TargetLevel 
										levelName={lvl.name} 
										minRangeId={tc?.minRangeId} 
										isRequired={tc?.isRequired} 
										scores={config.ranges}
										onChange={( minRangeId, isRequired) => onTargetChange(id, index, lvl.id, minRangeId, isRequired) } />
									{/* <Box className={classes.iconWrapper}>
										<DeleteButton tooltip="Delete target" onClick={()=> onDeleteTarget(id, index)} />
									</Box> */}
								</Box>
						}
								)}
							
					</Box>
				</Box>
			}
        </>;
}