import { setRequestError, setAppLoadingStarted, setAppLoadingFinished } from '../../App/actions'
import * as scoresApi from '../../../api/interview/ScoreRange';
export const profileScoresActions = {
	PROFILE_EDITOR_GROUPS_LOADED: 'PROFILE_EDITOR_GROUPS_LOADED',
    PROFILE_EDITOR_GROUP_ASSIGNED: 'PROFILE_EDITOR_GROUP_ASSIGNED'
}

export const assignScoreGroup = (profileId, groupId) => (dispatch) =>{
    // dispatch({ type: profileEditorActions.PROFILE_EDITOR_GROUP_ASSIGNED, data: { assignedId: groupId }})
    dispatch(setAppLoadingStarted());
    scoresApi.AssignToProfile(profileId, groupId).then(response=>{
        if(!response.error){
            dispatch(setAppLoadingFinished());
            dispatch({ type: profileScoresActions.PROFILE_EDITOR_GROUP_ASSIGNED, data: { assignedId: groupId }})
        } else {
            dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => assignScoreGroup()));
        }
    }).catch(error => {
		dispatch(setRequestError("Error occured:" + error, () => assignScoreGroup()));
	});
}

export const getProfileScoresInfo = (id) => (dispatch) => {
	dispatch(setAppLoadingStarted());
	
	scoresApi.ProfileScoresInfo(id).then(response => {
		if(!response.error){
			dispatch({ type: profileScoresActions.PROFILE_EDITOR_GROUPS_LOADED, data: response.data });
			dispatch(setAppLoadingFinished());
		} else {
			dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => getProfileScoresInfo()));
		}
	}).catch(error => {
		dispatch(setRequestError("Error occured:" + error, () => getProfileScoresInfo()));
	});
}