import { Configuration } from "@interviewapp/interview-app-rest-client"
import authService from "../authService";
import spec from '../../api/interview/gateway/spec'

let config = new Configuration();
config.basePath = "";
config.baseOptions = {
    headers: {
        Authorization: ""
    }
}

export default function getApiConfig() {
    let result = Object.assign({}, config);
    result.basePath = `${spec.schemes}://${spec.host}`
    result.baseOptions.headers.Authorization = "Bearer " + authService.getJwt();
    return result;
}