import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Button,Grid, TextField} from '@mui/material'
import { connect } from 'react-redux'
import * as CandidatesEditorActions from './actions'
import { bindActionCreators } from 'redux'
import {initialState as storeInitialState} from './reducer'
import WithErrorMessage from '../../common/components/WithErrorMessage'

class CandidateEditor extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.actions.getCandidate(this.props.id);
    }
    componentWillUnmount() {
        this.props.actions.setCandidateData(storeInitialState);
    }
    handleChange = (propName) => {
        const me = this;
        return function (e) {
            let data = {};
            data[propName] = e.target.value;
            me.props.actions.setCandidateData(data);
        }
    }
    onKeyPress = (e) => {
        if (e.which == 13) { //enter
			this.onSave();
			e.preventDefault();
			e.stopPropagation();
        }
    }
    onSave = () => {
        this.props.actions.saveCandidate();
    }
    formFields = () => {
        const fields = [{
            prop:'name',
            label:'Name',
            name:'Name'
        },{
            prop:'lastName',
            label:'LastName',
            name:'LastName'
        },{
            prop:'email',
            label:'Email',
            name:'Email'
        },{
            prop:'phone',
            label:'Phone Number',
            name:'Phone'
        },{
            prop:'skype',
            label:'Skype',
            name:'Skype'
        }];
        const components = fields.map(f => {
            return (
                <Grid item xs={12} key={`candidate-form-field-${f.prop}`}>
                    <WithErrorMessage error={this.props.errors[f.prop]}>
                        <TextField id={f.prop}
                                    name={f.name}
                                    label={f.label} 
                                    value={this.props[f.prop]} 
                                    onChange={this.handleChange(f.prop)}
                                    autoComplete='off' 
                                    margin="normal" 
                                    error={this.props.errors[f.prop] && true}/>
                    </WithErrorMessage>
                </Grid>
            )
        });
        return components;
    }
    render() {
        return (
			<form onKeyPress={this.onKeyPress}>
				<Grid container>
					{this.formFields()}
                    <Grid item xs={12}>
                        <Grid container spacing={8}>
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={this.onSave}>Save</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="secondary" onClick={this.props.history.goBack}>Cancel</Button>
                            </Grid>
                        </Grid>
                    </Grid>
				</Grid>
			</form>
		)
    }
}

CandidateEditor.propTypes = {
    id:PropTypes.number,
    name:PropTypes.string,
    lastName:PropTypes.string,
    email:PropTypes.string,
    phone:PropTypes.string,
    skype:PropTypes.string,
    actions:PropTypes.object,
    history:PropTypes.object,
    errors: PropTypes.object
};

const mapStateToProps = (state,ownProps) => {
    const data = {...state.candidateEditor};
    data.id = parseInt(ownProps.match.params.id || state.candidateEditor.id)
    return data;
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(CandidatesEditorActions, dispatch)
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CandidateEditor))