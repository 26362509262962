import {initialState as storeInitialState} from './reducer'
import { setRequestError, } from '../App/actions'
import * as interviewsApi from '../../api/interview/Interview'
import history from '../../utils/history'

export const candidateInterviewsActions = {
    CANDIDATE_INTERVIEWS_SET_DATA: 'CANDIDATE_INTERVIEWS_SET_DATA',
    CANDIDATE_INTERVIEWS_SET_INVITATION_KEY: 'CANDIDATE_INTERVIEWS_SET_INVITATION_KEY'
};

export const setCandidateInterviewsData = (data) => ({ type: candidateInterviewsActions.CANDIDATE_INTERVIEWS_SET_DATA, data });
export const setInvitationKey = (id,key) => ({type:candidateInterviewsActions.CANDIDATE_INTERVIEWS_SET_INVITATION_KEY, id, key})

export const generateInvitation = (id) => (dispatch) => {
    dispatch(setCandidateInterviewsData({isLoading:true}));
    interviewsApi.GenerateInvitation({interviewId:id})
        .then(response => {
            if (!response.error) {
                const responseBody = response.data;
                const { data } = responseBody;
                dispatch(setInvitationKey(id,data.invitationKey));
                dispatch(setCandidateInterviewsData({isLoading:false})); 
            } else {
                dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => generateInvitation(id)));
                dispatch(setCandidateInterviewsData({isLoading:false}));          
            }
        })
        .catch(error => {
            dispatch(setRequestError("Error occured:" + error, () => generateInvitation(id)));
            dispatch(setCandidateInterviewsData({isLoading:false})); 
        });
}

export const getInterviews = (candidateId,pageNumber = 0, pageSize = 5) => (dispatch) => {
    if (!candidateId) {
        dispatch(setCandidateInterviewsData(storeInitialState));
        return;
    }
    dispatch(setCandidateInterviewsData({isLoading:true}));
    interviewsApi.GetCandidateInterviews({candidateId,pageNumber,pageSize})
        .then(response => {
            if (!response.error) {
                const responseBody = response.data;
                const { data } = responseBody;
                dispatch(setCandidateInterviewsData({list:data.results || [],rowCount: data.rowCount, isLoading:false}));
            } else {
                dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => getInterviews(candidateId)));
                dispatch(setCandidateInterviewsData({isLoading:false}));          
            }
        })
        .catch(error => {
            dispatch(setRequestError("Error occured:" + error, () => getInterviews(candidateId)));
            dispatch(setCandidateInterviewsData({isLoading:false})); 
        });
};