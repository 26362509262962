import * as resultsApi from '../../api/interview/Results';
import { setRequestError, setAppLoadingStarted, setAppLoadingFinished } from '../App/actions'


export const interviewTimeLineActions = {
    INTERVIEW_TIMELINE_REQUEST_SUCCEEDED: 'INTERVIEW_TIMELINE_REQUEST_SUCCEEDED'
}

export const setInterviewTimeLineLoaded = (interviewResult) => ({ type: interviewTimeLineActions.INTERVIEW_TIMELINE_REQUEST_SUCCEEDED, data: interviewResult });

export const getInterview = (id) => (dispatch) => {
    dispatch(setAppLoadingStarted());
    resultsApi.Get(id).then(response => {
            if (!response.error) {
                const responseBody = response.data;
                const { data } = responseBody;
                dispatch(setAppLoadingFinished());
                dispatch(setInterviewTimeLineLoaded(data[0]));
            } else {
                dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => getInterview(id)));
            }
        })
        .catch(error => {
            dispatch(setRequestError("Error occured:" + error, () => getInterview()));
        });
};