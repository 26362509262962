import React from 'react'
import { Grid } from '@mui/material'
import { objectWithoutProperties } from '../../../../utils/helpers'
import isEqual from 'react-fast-compare'

class TreeGridMaterialGrid extends React.Component {
    constructor(props) {
        super(props);
    }
    shouldComponentUpdate(nextProps) {
        return !isEqual(this.props,nextProps);
    }
    render() {
        return (
            <Grid {...objectWithoutProperties(this.props, ['children'])}>{this.props.children}</Grid>
        );
    }
}

export default TreeGridMaterialGrid;