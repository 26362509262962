import React from 'react';
import { connect } from 'react-redux';
import * as scoresActions from './actions';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import withStyles from '@mui/styles/withStyles';
import { bindActionCreators } from 'redux';
import { InputLabel, Select, MenuItem, FormControl, Grid, Table, TableRow, TableCell, TableHead, TableBody } from '@mui/material'

const styles = theme=>({
	root:{
		// display: 'flex',
		// flexWrap: 'wrap',
	},
	formControl: {
		// margin: theme.spacing.unit,
		minWidth: 200,
	},
	assignButton:{
		marginLeft: theme.spacing(2),
		marginTop: theme.spacing(2)
	}
})

class ScoreGroupsSelector extends React.Component {
	constructor(props) {
        super(props);
		
		this.props.actions.getProfileScoresInfo(this.props.profileId);
        this.applyScores = this.applyScores.bind(this);
	}
	
	applyScores(e){
		this.props.actions.assignScoreGroup(this.props.profileId, e.target.value)
	}

	getScoresTable(){
		const assignedGroup = this.props.availableGroups.find(group => group.id == this.props.assignedId);
		if(assignedGroup){			
			const rows = assignedGroup.ranges.map(range=>{
				return <TableRow key={range.id}>
					<TableCell>
						{range.title}
					</TableCell>
					<TableCell>
						{range.bottom}
					</TableCell>
					<TableCell>
						{range.top}
					</TableCell>
				</TableRow>
			});
			return (<Table>
				<TableHead>
					<TableRow>
						<TableCell>
							Name
						</TableCell>
						<TableCell>
							Bottom
						</TableCell>
						<TableCell>
							Top
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows}
				</TableBody>
			</Table>)
		}
		return (<p>No scores selected</p>);
	}

	render() {
		const classes = this.props.classes;
		const menuItems = this.props.availableGroups.map(group=>{
			return (
			<MenuItem 
				key={group.id}
				value={group.id}	
			>
				{group.name}
			</MenuItem>)
		});
		const assignedId = this.props.assignedId == null ? 0 : this.props.assignedId;
		return (<div className={classes.root}>
					<FormControl className={classes.formControl}>
						<InputLabel htmlFor="assigned-group">Selected Scores Group</InputLabel>
						<Select
							value={assignedId}
							inputProps={{
								name: 'group',
								id: 'assigned-group',
							}}
							onChange={this.applyScores}
						>
							<MenuItem value="0">
								<em>None</em>
							</MenuItem>
							{menuItems}
						</Select>
					</FormControl>
					<Grid>
						{this.getScoresTable()}
					</Grid>
				</div>)
	}
}

const mapStateToProps = (state, ownProps) => {
    return {
		availableGroups: state.scoresSelector.availableGroups,
		assignedId: state.scoresSelector.assignedId,
		profileId: parseInt(ownProps.match.params.id || state.profileEditor.profileId)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(scoresActions, dispatch)
    };
};

ScoreGroupsSelector.propTypes = {
	classes: PropTypes.object,
	assignedId: PropTypes.number,
	availableGroups: PropTypes.array,
	actions: PropTypes.object,
	history: PropTypes.object,
	profileId: PropTypes.number

}

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ScoreGroupsSelector)))