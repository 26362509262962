import {
	groupsActions
} from './actions'

export const initialState = {
	name: "",
	roles: [],
	users: [],
	invites: [],
	inviteEmail: "",
	inviteRoleId: null,
	emailValid: true
}

export const groupMembersComponent = (state = initialState, action) => {
	switch (action.type) {
		case groupsActions.GROUP_LOADED:
			var newData = Object.assign({}, state, {...action.data, invites: (action.data.invites ? action.data.invites : [])});
			return Object.assign({}, newData, { inviteRoleId: newData.roles[0].id });
		case groupsActions.MEMBERS_INVITE_EDIT:
			return Object.assign({}, state, action.data);
		case groupsActions.MEMBERS_INVITATION_SENT:
			return Object.assign({}, state, {
				invites: [...state.invites, action.data]
			});
		case groupsActions.MEMBERS_USER_DELETED:
			return Object.assign({}, state, {
				users: state.users.filter(x => x.userId != action.data)
			});
		case groupsActions.MEMBERS_INVITE_REJECTED:
			return Object.assign({}, state, {
				invites: state.invites.filter(x => x.key != action.data)
			});
		case groupsActions.USER_ROLES_CHANGE:
			return Object.assign({}, state, {users : [...state.users.map(u => {
				if(u.userId == action.data.userId)
					return {...u, roles: action.data.roles}
				return u;
			})]});
		default:
			return state;
	}
}