import React from 'react'
import { connect } from 'react-redux'
import { login } from '../../../common/actions/accountActions';
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { Grid, Button, Icon, InputAdornment, FormHelperText, TextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import ValidateableComponent from "../../../common/components/ValidateableComponent";
import TextFieldControl from "../../../common/components/TextFieldControl/TextFieldControl";

const styles = theme => ({
	formInput: {
		width: 250
	},
	loginButton: {
		width: 250
	},
})
class FormLogin extends ValidateableComponent {
	constructor(props) {
		super(props);

		this.Email = null;
		this.Password = null;
		this.state = {
			invalidCreds: false
		}

		this.handleLogin = this.handleLogin.bind(this);
		this.onKeyPress = this.onKeyPress.bind(this);
	}

	onKeyPress(e) {
        if (e.which == 13) { //enter
			this.handleLogin(e);
			e.preventDefault();
			e.stopPropagation();
        }
	}

	handleLogin(e) {
		e.preventDefault();

		var email = this.Email.value();
		var password = this.Password.value();

		this.props.loginAction.login(email, password, this.props.history)
			.then(response => {
				if (response && response.raw.status === 400) {
					this.setState({ invalidCreds: true });
				}
			});
	}

	getFields() {
		return [
			{
				name: "email",
				component: this.Email !== null ? this.Email : null
			},
			{
				name: "password",
				component: this.Password !== null ? this.Password : null
			}
		];
	}

	render() {
		const classes = this.props.classes;
		return (
			<form onKeyPress={this.onKeyPress}>
				<Grid container>
					{ this.state.invalidCreds &&
						<Grid xs={12}>
							<FormHelperText error="true">*Username or passwort is invalid</FormHelperText>
						</Grid>
					}
					<Grid item xs={12} >
						<TextFieldControl
							className={classes.formInput}
							ref={(c) => this.Email = c}
							// label="Email"
							size="small"
							name="email"
							id="email"
							margin="normal"
							required
							variant="filled"
							error={this.state.invalidCreds}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<Icon>email</Icon>
									</InputAdornment>
								),
							}} />
					</Grid>
					<Grid item xs={12}>
						<TextFieldControl
							className={classes.formInput}
							ref={(c) => this.Password = c}
							variant="filled"
							size="small"
							// label="Password"
							name="password"
							id="password"
							type="password"
							margin="normal"
							required
							error={this.state.invalidCreds}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<Icon>lock</Icon>
									</InputAdornment>
								),
							}} />
						{/* <TextField
  className={classes.formInput}
  ref={(c) => this.Password = c}
  variant="filled"
  size="small"
  // label="Password"
  name="password"
  id="password"
  type="password"
  margin="normal"
  required
  error={this.state.invalidCreds}
  InputProps={{
	  startAdornment: (
		  <InputAdornment position="start">
			  <Icon>lock</Icon>
		  </InputAdornment>
	  ),
  }}
/> */}
					</Grid>

					<Grid item xs={12}>
						<Button className={classes.loginButton} variant="contained" color="primary" onClick={this.handleLogin}>Log in</Button>
					</Grid>

					{/* <Grid item>
						<a
							className="create-new-account"
							onClick={() => this.props.history.push("/register")}>
							Create new account
						</a>
					</Grid> */}
				</Grid>
			</form>
		)
	}
}

FormLogin.propTypes = {
	loginAction: PropTypes.object,
	history: PropTypes.object
}

const mapDispatchToProps = (dispatch) => {
	return {
		loginAction: bindActionCreators({ login: login }, dispatch)
	};
};

export default (withStyles(styles, { withTheme: true })(connect(null, mapDispatchToProps)(FormLogin)));