import React from 'react'

// Do not instanciate this component directly. Only extend it.
export default class ValidateableComponent extends React.PureComponent{
    constructor(props) {
        super(props);

        this.errors = [];

        this.state = {
            errors: [
                {

                }
            ],
            fields: []
        };

        this.resetValidation = this.resetValidation.bind(this);
        this.getFields = this.getFields.bind(this);
    }

    resetValidation() {
        this.getFields().forEach(field => {
            field.component.resetError();
        });
    }

    setErrors(errors) {
        this.getFields().forEach(field => {

            if (field.component !== null && errors[field.name]) {
                field.component.setError(errors[field.name]);
            }
        });
    }

    registerFields(fields) {
        this.setState({ fields });
    }
}