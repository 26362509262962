import * as InterviewsCompareActions from './actions'
import React, { Fragment } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import {Table, TableHead, TableCell, TableBody,TableRow, Typography, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import withStyles from '@mui/styles/withStyles';
import LinearProgress from '../../common/components/progress/LinearProgress'
import Moment from 'react-moment';
import ScoreValue from '../InterviewReview/Renderers/ScoreValue';
import ScoreChip from '../../common/components/Scores/ScoreChip'

const styles = theme => ({
	expertComment: {
		paddingTop: theme.spacing(1)
	},
	level_indent:{
		fontSize: "0.5rem",
		// color: theme.palette.primary.light,
		color: "#aaa",
		display: "inline-block",
		position: "relative",
		top: "-3px",
		paddingRight: "1.5rem"
	},
	areaCell: {
		width: "25%",
		padding: "5px 5px 5px 5px !important",
	},
	controlsRoot:{
		flexGrow: 1
	},
	controlsFiller: {
		flex: 1
	},
	infoTitle:{
		color: theme.palette.grey["600"]
	},


	scoreSection: {
		// marginBottom: theme.spacing(1)
	},
	scoresName: {
		paddingLeft: theme.spacing(1)
	},
	scoreCell: {
		padding: "5px 5px 5px 5px !important",
		margin: "0px"
	}
});

class InterviewsCompare extends React.Component{
	constructor(props) {
		super(props);
		this.state = { showComments : false, hideNotAsked: true }
		var summaryIds = this.props.list.filter((x)=> x.profileId == this.props.profileId).map(a => a.Id);
		
		this.props.actions.getInterviewsResults(summaryIds);
	}

	getScoreDifferenceInfo(score, responses){
		var maxScore = score;

		for (var summaryId in responses){
			var resp = responses[summaryId];
			if(resp.score > score){
				maxScore = resp.score;
			}
		}

		return {
			maxScoreDiff: maxScore - score
		}
	}

	getScoreName(score){
		const classes = this.props.classes;
		if(score != null){
			if(this.props.scoresInfo != null && this.props.scoresInfo.ranges != null){
				for(let i = 0; i< this.props.scoresInfo.ranges.length; i++){
					let r = this.props.scoresInfo.ranges[i];
					if(r.bottom<=score && r.top>=score){
						return <span className={classes.scoresName}>
							{r.title}
						</span>
					}
				}
			}
		}
		return "";
	}

	getCell(row, summaryid){
		const obj = row.responses[summaryid];
		const classes = this.props.classes;
		
		if (obj){
			const diff = this.getScoreDifferenceInfo(obj.score, row.responses);
			const progressColor = diff.maxScoreDiff > 0 ? this.props.theme.palette.error.main : this.props.theme.palette.primary.main;
			const expertComment = this.state.showComments && (obj.expertComment !== null &&  obj.expertComment !== '') ? 
				<Typography className={classes.expertComment} variant="body1"> {obj.expertComment} </Typography> : <span></span>;
			const scoreName = this.getScoreName(obj.score);
			return <TableCell key={row.areaId + summaryid} className={classes.scoreCell}>
				<div className={classes.scoreSection}>
					{obj.notAsked ? null : <ScoreChip title={obj.scoreRange} selectedColor={obj.hexColor}></ScoreChip> }
					
					{/* {scoreName} */}
				</div>
				{/* <LinearProgress className={classes.progressBar} value={obj.score} color={progressColor} /> */}
				{expertComment}
			</TableCell>
		} else {
			return <TableCell key={row.areaId + summaryid}></TableCell>
		}
	}

	getLevelPadding(level){
		const { classes } = this.props;
		var levels = []; 
		for(var i = 0; i<level;i++){
			levels.push(i);
		}
		// var levels =  new Array(level).map(function (x, i) { return i })
		var items = levels.map(elem=>{
			return (<span className={this.props.classes.level_indent} key={elem}>
					<FontAwesomeIcon 
						icon={faCircle} size="xs" />
					</span>);
		});
		return (<Fragment>{items}</Fragment>);
	}

	initRow(rowInfo, level) {
		const shouldShow = this.state.hideNotAsked && Object.values(rowInfo.responses) && Object.values(rowInfo.responses).length ? Object.values(rowInfo.responses).filter(r=>!r.notAsked).length > 0 : true;

		const resultRow = shouldShow ? <TableRow key={rowInfo.areaId}>
			<TableCell className={this.props.classes.areaCell}>
					<Fragment>
						{this.getLevelPadding(level)}
						{rowInfo.name}
					</Fragment>
			</TableCell>
			{this.props.infos.map(info =>{ 
				return this.getCell(rowInfo, info.id)
			})}
		</TableRow> : null;
		if(rowInfo.childRows && rowInfo.childRows.length > 0){
			return <Fragment key={rowInfo.areaId + "_fragment"}>
				{resultRow}
				{rowInfo.childRows.map(row => {
					return this.initRow(row, level+1);
				})}
			</Fragment>
		}
		return resultRow;
	}

	header(){
		const header = this.props.infos.map((info)=>{
			return (
				<TableCell key={info.id}>
					<Typography variant="subtitle1">
						{info.title}
					</Typography>
					<Typography>
						<span className={this.props.classes.infoTitle}>lvl:</span>{info.level}
					</Typography>
					<Typography>
						<span className={this.props.classes.infoTitle}>date:</span><Moment format="DD.MM.YYYY hh:mm" date={info.completedDate}></Moment>
					</Typography>
					<Typography variant="body2">
						{info.summary}
					</Typography>
				</TableCell>	
			);
		});
		return header;
	}

	handleShowComments = () => {
		this.setState({ showComments: !this.state.showComments});
	}

	toggleNotAsked = () => {
		this.setState({ showComments: !this.state.hideNotAsked});
	}

	getControls(){
		const classes = this.props.classes;
		return (
			<FormGroup row className={classes.controlsRoot}>
			<div className={classes.controlsFiller}>

			</div>
        <FormControlLabel
          control={
            <Switch
              checked={this.state.showComments}
              onChange={this.handleShowComments}
              color="primary"
            />
          }
          label="Show comments"
        />
		<FormControlLabel
          control={
            <Switch
              checked={this.state.hideNotAsked}
              onChange={this.toggleNotAsked}
              color="primary"
            />
          }
          label="Hide not asked"
        />
      </FormGroup>
		)
	}

	render() {
		var me = this;
		const rows = this.props.rows.map(row => {
			return (me.initRow(row, 0));
		});
		const controls = this.getControls();
		return (
			<Fragment>
				{controls}
				<Table>
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							{this.header()}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows}
					</TableBody>

				</Table>
			</Fragment>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
    return {
		list: state.interviewsCompareList.list,
		infos: state.InterviewsCompareComponent.infos,
		rows: state.InterviewsCompareComponent.rows,
		profileId: ownProps.match.params.id,
		scoresInfo: state.InterviewsCompareComponent.scoresInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(InterviewsCompareActions, dispatch)
    };
};

InterviewsCompare.propTypes = {
	classes: PropTypes.object,
    actions: PropTypes.object,
	infos: PropTypes.array,
	rows: PropTypes.array,
	list: PropTypes.array,
	theme: PropTypes.object,
	showComments: PropTypes.bool,
	profileId: PropTypes.string,
	scoresInfo: PropTypes.object
}

export default withRouter(withStyles(styles, {withTheme:true})(connect(mapStateToProps, mapDispatchToProps)(InterviewsCompare)))