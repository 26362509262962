import {preInteviewActions} from './actions'

export const initialState = {
    id:0,
    profileId: 0,
    versionId: 0,
    areas: [],
    profileName: "",
    interviewName: "",
    scoresGroup: null,
    focusRow: null,
    focusColumn: 0
};

export const preInterviewComponent = (state = initialState,action) => {
    switch(action.type){
        case preInteviewActions.PREINTERVIEW_DATA_SET:
            return Object.assign({},state,action.data);
            
        case preInteviewActions.PREINTERVIEW_EDIT_AREA:
            return Object.assign({}, state, {
                areas: state.areas.map(function (ar) {
                    if (ar.id == action.id) {
                        return Object.assign({},ar, {candidateResponse : Object.assign({},ar.candidateResponse || {},action.data)});
                    }
                    return ar;
                })
            });

        case preInteviewActions.INTERVIEW_SET_FOCUS_ROW:
                return Object.assign({}, state, {
                    focusRow: action.order
                });

        case preInteviewActions.INTERVIEW_SET_FOCUS_COLUMN:
                return Object.assign({}, state, {
                    focusColumn: action.column
                });
        default:
            return state;
    }
}