import * as resultsApi from '../../api/interview/Results';
import {
    setRequestError,
    setAppLoadingStarted,
    setAppLoadingFinished
} from '../App/actions'

export const interviewsListActions = {
    INTERVIEWS_SET_DATA: 'INTERVIEWS_SET_DATA',
    DELETE_INTERVIEW_SUCCEEDED: 'DELETE_INTERVIEW_SUCCEEDED'
}

export const setInreviewsData = (data) => ({
    type: interviewsListActions.INTERVIEWS_SET_DATA,
    data
});
export const deleteInterviewSucceeded = (id) => ({
    type: interviewsListActions.DELETE_INTERVIEW_SUCCEEDED,
    id
});


export const getInterviewsList = (filtering = false,pageNumber = 0,pageSize = 5) => (dispatch,getState) => {
    const {interviewsListComponent} = getState();
    if(filtering && interviewsListComponent.isFiltering) {
        return;
    }
    else if(filtering) {
        dispatch(setInreviewsData({isFiltering:true}));
    } else if(!filtering) {
        dispatch(setAppLoadingStarted());
    }

    const searchTerm = filtering ? interviewsListComponent.searchValue : '';
    
    resultsApi.GetPaged({searchTerm,pageNumber,pageSize})
        .then((response) => {
            if (!response.error) {
                const responseBody = response.data;
                const { data } = responseBody;
                dispatch(setAppLoadingFinished());
                if(interviewsListComponent.interviewStageFilter !== "") {
                    let filteredInterviewList = data.results.filter(function(item) {
                        return item.stage === interviewsListComponent.interviewStageFilter;
                    });

                    dispatch(setInreviewsData({
                        interviewsList: data.results, 
                        rowCount: filteredInterviewList.length, 
                        initialLoaded:  !searchTerm
                    }));
                }
                else {
                    dispatch(setInreviewsData({
                        interviewsList: data.results,
                        rowCount: data.rowCount, 
                        initialLoaded: !searchTerm
                    }));
                }
                
            } else {
                dispatch(setRequestError(response.data.body.error.generalErrorMessage,()=> getInterviewsList(pageNumber,pageSize)));
            }
            if(filtering) {
                dispatch(setInreviewsData({isFiltering:false}));
            }
        }).catch((error) => {
            if(filtering) {
                dispatch(setInreviewsData({isFiltering:false}));
            }
            dispatch(setRequestError("Error occured " + error, () => getInterviewsList()));
        });
}

export const deleteResult = (id) => (dispatch) => {
    dispatch(setAppLoadingStarted());
    resultsApi.Delete(id)
    .then(response => {
        if (!response.error) {
            dispatch(setAppLoadingFinished());
            dispatch(deleteInterviewSucceeded(id));
        } else {
            dispatch(setRequestError(response.data.body.error.generalErrorMessage,() => deleteResult(id)));
        }
    }).catch(error =>{
        dispatch(setRequestError("Error occured " + error, () => deleteResult(id)));
    });
}