import { setRequestError, setAppLoadingStarted, setAppLoadingFinished } from '../App/actions'
import * as interviewApi from '../../api/interview/Interview'
import history from '../../utils/history'
import {candidateSelectSetData} from '../../common/components/CandidateSelect/actions'
import getApiConfig from '../../utils/api/apiConfigurator';
import { ResultsApi, InterviewApi } from "@interviewapp/interview-app-rest-client"

export const interviewActions = {
    INTERVIEW_SET: 'INTERVIEW_SET',
    INTERVIEW_AREA_EXPERT_EDIT : 'INTERVIEW_AREA_EXPERT_EDIT',
    INTERVIEW_AREA_EDIT: "INTERVIEW_AREA_EDIT",
    INTERVIEW_SET_FOCUS_ROW : 'INTERVIEW_SET_FOCUS_ROW',
    INTERVIEW_SET_FOCUS_COLUMN : 'INTERVIEW_SET_FOCUS_COLUMN',
    INTERVEW_AREA_SELECTED: 'INTERVEW_AREA_SELECTED',
    INTERVIEW_SET_CANIDATELEVEL_SAVING: 'INTERVIEW_SET_CANIDATELEVEL_SAVING',
    INTERVIEW_SET_TITLE_SAVING: 'INTERVIEW_SET_TITLE_SAVING'
}

export const setSuggested = (parentArea, childAreas, scoresConfigs, dispatch) => {
    const responses = childAreas.filter(x=>x.expertResponse && x.expertResponse.score)
    .map(z=> Object.assign({}, { id : z.id, score: z.expertResponse.score, ranges: scoresConfigs.find(sc=>sc.id == z.scoreConfigId).ranges }))

    const values = responses.map(x=>{ 
        const scoreConfig = x.ranges.find(z=>z.bottom == x.score);
        return x.ranges.indexOf(scoreConfig)/x.ranges.length;
    });

    var sum = 0;
    for(var i =0; i<values.length;i++) sum+=values[i];
    const avg = sum/values.length;

    const ranges = scoresConfigs.find(x=>x.id == parentArea.scoreConfigId)?.ranges;

    if(ranges){
        var suggestedIndex = 0;
        var secondSuggested = null;
        var minDiff = avg - 0;
        for(var i=1;i<ranges.length-1;i++){
            var diff =  Math.abs(avg - i/ranges.length)
            if (diff < minDiff){
                suggestedIndex = i;
                minDiff = diff;
            }
        }
        
        dispatch(editInterviewArea(parentArea.id, { suggestions: [suggestedIndex] }))
    }
}

export const setScore = (interviewId,areaId,areaData)  => (dispatch, getState) => {
    dispatch(editInterviewArea(areaId, { isSaving: true }));
    return interviewApi.SetScore({
        model: {
            interviewId:interviewId,
            score: areaData.notAsked ? null : areaData.score,
            areaId: areaId,
            comment: areaData.comment,
            scoreTitle: areaData.scoreTitle,
            fromCandidate: false
        }
    }).then(response => {
        if (!response.error) {
            dispatch(editInterviewArea(areaId, { isSaving: false }));
            const areas = getState().interviewComponent.areas;
            const scoresConfigs = getState().interviewComponent.scoresConfigs;
            const parentId = areas.find(x=>x.id == areaId).parentId;
            if(parentId){
                const parent = areas.find(x=>x.id == parentId);
                const children = areas.filter(x=>x.parentId == parentId);
                setSuggested(parent, children, scoresConfigs, dispatch);
            }
            
            dispatch(editInterviwExpertArea(areaId, Object.assign({}, areaData)));
            dispatch(setAppLoadingFinished());
            return true;

        } else {
            dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => setScore(interviewId,areaId,areaData)));
            return false;
        }

    }).catch(error => {
        dispatch(editInterviewArea(areaId, { isSaving: false }));
        dispatch(setRequestError("Error occured:" + error, () => setScore(interviewId,areaId,areaData)));
        return false;
    });
}

export const setInterviewData = (data) => ({ type: interviewActions.INTERVIEW_SET, data });

export const editInterviewArea = (id, data) => ({ type: interviewActions.INTERVIEW_AREA_EDIT, id, data });
export const editInterviwExpertArea = (id,data) => ({ type: interviewActions.INTERVIEW_AREA_EXPERT_EDIT, id, data})

export const selectArea = (id) => ({ type: interviewActions.INTERVEW_AREA_SELECTED, payload: { id }})

export const setFocusRow = (rowOrder) => (dispatch,getState) => {
    if(rowOrder>=0 && rowOrder<getState().interviewComponent.areas.length) {
        dispatch({ type: interviewActions.INTERVIEW_SET_FOCUS_ROW, order: rowOrder });
    } else if(rowOrder == null) {
        dispatch({ type: interviewActions.INTERVIEW_SET_FOCUS_ROW, order: rowOrder });
    }
}

export const setFocusColumn = (column) => (dispatch) => {
    const columns = ['title','score','comment'];
    if(column>=0 && column<columns.length) {
        dispatch({ type: interviewActions.INTERVIEW_SET_FOCUS_COLUMN, column });
    } else if(column == null) {
        dispatch({ type: interviewActions.INTERVIEW_SET_FOCUS_COLUMN, column });
    }
}

export const getInterview = (id) => (dispatch) => {
    if (!id) {
        dispatch(setInterviewData({ profileName: "New Profile", profileId: 0, areas: [] }));
        return;
    }
    dispatch(setAppLoadingStarted());
    interviewApi.Get(id)
        .then(response => {
            if (!response.error) {
                const responseBody = response.data;
                const { data } = responseBody;
                dispatch(setInterviewData({
                    id: data.id,
                    versionId: data.versionId,
                    profileId: data.id,
                    selectedCandidate: data.candidateId,
                    profileName: data.profileName,
                    interviewName: data.name,
                    levelId: data.levelId,
                    levelName: data.levelName,
                    levelScore: data.levelScore,
                    levels : data.availableLevels,
                    areas: data.areas.map(x=>{ x.selected = false; x.isSaving = false; return x; }),
                    interviewSummary: data.summary,
                    scoresGroup: data.scoresGroup,
                    scoresConfigs: data.scoresConfigs,
                    completedDate: data.completedDate
                }));

                if(data.candidateId) {
                    dispatch(candidateSelectSetData({selected:{value:data.candidateId,label:data.candidateName}, options:[{value:data.candidateId,label:data.candidateName}]}));
                }
                

                dispatch(setAppLoadingFinished());
            } else {
                dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => getInterview(id)));
            }
        })
        .catch(error => {
            dispatch(setRequestError("Error occured:" + error, () => getInterview(id)));
        });
};

export const updateInterview = (complete) => (dispatch, getState) => {
    const state = getState().interviewComponent;

    dispatch(setAppLoadingStarted());
    interviewApi.Save({
        interviewModel: {
            id: state.id,
            levelId : state.levelId,
            summary: state.interviewSummary,
            name : state.interviewName,
            candidateId : state.selectedCandidate,
            complete: complete
        }
    }).then(response => {
        if(!response.error) {
            dispatch(setAppLoadingFinished());
            if(complete) {
                history.push(`/interviews/review/${state.id}`);
            }
            
        } else {
            dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => updateInterview(complete)));
        }
    }).catch(error => {
        dispatch(setRequestError("Error occured:" + error, () => updateInterview(complete)));
    })
}
export const setInterviewTitle = (interviewId, title) => (dispatch) => {
    dispatch(setInterviewData({ isTitleSaving: true }))
    new InterviewApi(getApiConfig()).setGeneral({ id: interviewId, name: title }).then((response)=> {
        if (!response.error) {
            dispatch(setInterviewData({ interviewName: title, isTitleSaving: false }))
        } else {
            dispatch(setInterviewData({ isTitleSaving: false }))
        }
    }).catch((error) => {
        dispatch(setInterviewData({ isTitleSaving: false }))
        dispatch(setRequestError("Error occured " + error, () => setInterviewTitle(interviewId, title)));
    })
}

export const setCandidateLevel = (interviewId, levelId) => (dispatch) => {
    dispatch(setInterviewData({ isLevelSaving: true }))
    new InterviewApi(getApiConfig()).setLevel({ interviewId, levelId }).then((response)=> {
        if (!response.error) {
            dispatch(setInterviewData({ levelId: levelId, isLevelSaving: false }))
        } else {
            dispatch(setInterviewData({ isLevelSaving: false })) 
        }
    }).catch((error) => {
        dispatch(setInterviewData({ isLevelSaving: false }))
        dispatch(setRequestError("Error occured " + error, () => setCandidateLevel(interviewId, levelId)));
    })
}