import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles';
import { Icon, Fab, Tooltip, FormLabel, Grid, FormControl, Select, InputLabel, Box } from '@mui/material'
import TreeGrid from '../../common/components/TreeGrid/TreeGrid'
import TreeGridTypography from '../../common/components/TreeGrid/Renderers/TreeGridCell'
import TreeGridCellEditable from '../../common/components/TreeGrid/Renderers/TreeGridCellEditable'
import TreeGridScoreTypeCell from '../../common/components/TreeGrid/Renderers/TreeGridScoreTypeCell'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as ProfileEditorActions from './actions'

const styles = () => ({
    cellWrapper: {
        overflow: "hidden"
    },
    treeDescription: {
        fontSize: "12px",
        maxHeight: "60px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        // whiteSpace: "pre",
        cursor: "pointer"
    },
    addRowTableIconBtn: {
        width: 35,
        height:35,
        display: "flex"
    },
    expandAllIcon: {
        width: 35,
        height:35,
        display: "flex",
        marginRight: "10px"
    },
    addRow: {
        height: 40
    },
    helpIcon: {
        fontSize: 10, 
        position:'relative', 
        left:5, 
        bottom:5
    },
    levelIndicator: {
        fontSize: "12px",
        fontWaight: 500
    },
    scoreIndicator: {
        fontSize: "12px"
    },
    required: {
        color: "red"
    }
})

class AreaEditor extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            expandAll: true
        }

        this.addAreaClickHandler = this.addAreaClickHandler.bind(this);
        this.getGridConfig = this.getGridConfig.bind(this);
    }
    getGridConfig() {
        const me = this;
        const { classes } = this.props;
        return {
            stateKey:'profileEditor',
            dataKey:'areas',
            rootId:'',
            paddingBase:16,
            dragDrop:true,
            expanded: this.state.expandAll,
            onRowClick: (event, target, rowData) => {
                me.props.actions.selectArea(rowData.id)
            },
            selectable: true,
            focus: {
                stateKey:'profileEditor',
                focusColumnProperty:'focusColumn',
                focusRowProperty:'focusRow',
                setFocusRow: order => {
                    this.props.actions.setFocusRow(order);
                },
                setFocusColumn: column => {
                    this.props.actions.setFocusColumn(column);
                }
            },
            propChangeHandler: (id,prop,value) => {
                const data = {};
                data[prop] = value;
                this.props.actions.editArea(id, data);
            },
            changeAreaPositionHandler: (id, newOrder, newParent, oldOrder, oldParent) => {
                this.props.actions.changeAreaPosition(id,newOrder,newParent,oldOrder,oldParent);
            },
            actions: {
                addChild:(id) => {
                    this.props.actions.addArea({ title: 'New Area', description: '' }, id);
                },
                remove:(id) => {
                    this.props.actions.removeArea(id);
                }
            },
            columnsConfigs: [{
                prop: 'title',
                text: 'Title',
                visible: true,
                width:4,
                renderer:(options) => {
                    return <TreeGridTypography value={options.value} variant={"subtitle1"} />
                }
            }, 
            {
                prop:'description',
                text:'Description',
                visible:true,
                width:3,
                additionalInputProps: {multiline:true, fullWidth: true},
                renderer:(options) => {
                    return <Box className={classes.cellWrapper}><TreeGridTypography className={classes.treeDescription} value={options.value} variant={"subtitle1"} /></Box>
                }
            }, 
            {
                text: "Target levels",
                prop:'targetConfigs',
                visible: true,
                width: 3,
                additionalInputProps: { scoresConfig: this.props.scoresConfig, areas: this.props.areas, actions: this.props.actions },
                renderer: (options, ...data) => {
                    const values = options?.value;
                    const area = me.props.areas.find(x=>x.id == options.id);
                    if(values) {
                        // quick fix to sort configs by level id
                        const datas = [...values].sort((a,b) => a.levelId >= b.levelId ? 1 : -1).map(x=> {
                            const level = me.props.levels.find(l=>l.id == x.levelId);
                            const scoreConfig = me.props.scoresConfig.find(a => area.scoreConfigId == a.id);
                            const minScore = scoreConfig.ranges.find(r=>r.id == x.minRangeId);
                            if(level) return <span className={x.isRequired ? classes.required : ""}>
                                        <span className={'levelIndicator'}>
                                            {level.name}: 
                                        </span>
                                        {minScore && 
                                            <span className={`scoresIndicator `}>{minScore.title},</span>
                                        }
                                    </span>
                            else return null;
                        })
                        return <Box className={"profile-editor-levelsgroup"}>{datas}</Box>;
                    } 
                    return <TreeGridTypography value={ ".." } variant={"subtitle1"} />
                },
            },
            {
                text: "Score config",
                prop:'scoreConfigId',
                visible: true,
                width: 1,
                additionalInputProps: { scoresConfig: this.props.scoresConfig, areas: this.props.areas, actions: this.props.actions },
                renderer: (options) => {
                    const config = (me.props.scoresConfig ?? []).find(x=>x.id == options.value);
                    return <TreeGridTypography value={ config ? config.name : "" } variant={"subtitle1"} />
                },
            },
            {
                text:'',
                visible:true,
                actionColumn:true,
                width:1,
            }]
        }
    }
    addAreaClickHandler() {
        this.props.actions.addArea({ title: 'New Area', description: '' });
    }
    render() {
        var treeConfig = this.getGridConfig();
        const { classes } = this.props;
        const expandCollapseText = this.state.expandAll ? 'Collapse All' : 'Expand All';
        return (
            <React.Fragment>
                <Box>
                    {/* <Box item xs={6}>
                        <FormLabel>Areas Tree</FormLabel>
                        <Tooltip title={
                            <span>
                                You can use keyboard to manipulate grid: <br />
                                Ctrl+Up: focus upper row <br />
                                Crtl+Down: focus lower row <br />
                                Ctrl+Left: focus left cell <br />
                                Ctrl+Right: focus right cell <br />
                                Crtl+Enter: save and close cell editor <br />
                                Crtl+Plus: append new area to parent of selected area <br />
                                Crtl+Shift+Plus: append new chidl area to selected one<br />
                                Crtl+Minus: remove area without confirmation<br />
                            </span>
                        }
                            placement="top">
                            <Icon className={`far fa-question-circle ${classes.helpIcon}`} />
                        </Tooltip>
                    </Box> */}
                    
                    <Box>
                        <TreeGrid {...treeConfig}/>
                    </Box>
                    <Box  className='action-row-container'>
                        <Tooltip title={expandCollapseText} placement="top">
                            <Fab
                                size="small"
                                className={classes.expandAllIcon}                          
                                onClick={() => this.setState({expandAll:!this.state.expandAll})}
                                color="primary">
                                <Icon>{this.state.expandAll ? 'layers' : 'layers_clear'}</Icon>
                            </Fab>
                        </Tooltip>
                        <Tooltip title="Add root area" placement="top">
                            <Fab
                                size="small"
                                color="primary"
                                aria-label="add"
                                className={classes.addRowTableIconBtn}
                                onClick={this.addAreaClickHandler}>
                                <Icon>add</Icon>
                            </Fab>
                        </Tooltip>
                        </Box>
                </Box>
            </React.Fragment>

        );
    }
}

AreaEditor.propTypes = {
    classes: PropTypes.object,
    clearGridPlugin: PropTypes.func,
    actions: PropTypes.object,
    areas: PropTypes.array,
    scoresConfig: PropTypes.array
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        scoresConfig: state.profileEditor.scoresConfigs,
        areas: state.profileEditor.areas,
        levels: state.profileEditor.targetLevel?.levels ?? []
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(ProfileEditorActions, dispatch),
    };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AreaEditor))