/** @module Roles */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * @param {number} groupId 
 * @return {Promise<module:types.RoleInfo[]>} Success
 */
export function Get(groupId) {
  const parameters = {
    path: {
      groupId
    }
  }
  return gateway.request(GetOperation, parameters)
}

/**
 */
export function GetPermissions() {
  return gateway.request(GetPermissionsOperation)
}

/**
 * @param {number} roleId 
 * @param {number} permissionId 
 * @return {Promise<module:types.OperationResult[Boolean]>} Success
 */
export function AddPermission(roleId, permissionId) {
  const parameters = {
    path: {
      roleId,
      permissionId
    }
  }
  return gateway.request(AddPermissionOperation, parameters)
}

/**
 * @param {number} roleId 
 * @param {number} permissionId 
 * @return {Promise<module:types.OperationResult[Boolean]>} Success
 */
export function RemovePermission(roleId, permissionId) {
  const parameters = {
    path: {
      roleId,
      permissionId
    }
  }
  return gateway.request(RemovePermissionOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {module:types.RoleInfo} [options.model] 
 * @return {Promise<module:types.RoleInfo>} Success
 */
export function Create(options) {
  if (!options) options = {}
  const parameters = {
    body: {
      model: options.model
    }
  }
  return gateway.request(CreateOperation, parameters)
}

/**
 * @param {number} id 
 * @param {object} options Optional options
 * @param {module:types.RoleUpdateModel} [options.role] 
 * @return {Promise<module:types.RoleInfo>} Success
 */
export function Update(id, options) {
  if (!options) options = {}
  const parameters = {
    body: {
      role: options.role
    },
    path: {
      id
    }
  }
  return gateway.request(UpdateOperation, parameters)
}

/**
 * @param {number} id 
 * @return {Promise<module:types.OperationResult[Boolean]>} Success
 */
export function DeleteRole(id) {
  const parameters = {
    path: {
      id
    }
  }
  return gateway.request(DeleteRoleOperation, parameters)
}

const GetOperation = {
  path: '/Roles/group/{groupId}',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const GetPermissionsOperation = {
  path: '/Roles/Permissions',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const AddPermissionOperation = {
  path: '/Roles/{roleId}/{permissionId}',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const RemovePermissionOperation = {
  path: '/Roles/{roleId}/{permissionId}',
  method: 'delete',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const CreateOperation = {
  path: '/Roles',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const UpdateOperation = {
  path: '/Roles/{id}',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'put',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const DeleteRoleOperation = {
  path: '/Roles/{id}',
  method: 'delete',
  security: [
    {
      id: 'oauth2'
    }
  ]
}
