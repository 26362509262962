import React, { Component, Fragment, useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Fab, TextField, Table, Button, TableHead, TableCell, TableBody,TableRow, Icon, FormControl, Box } from '@mui/material'

const useStyles = makeStyles(theme => ({
	controlsRoot:{
		flexGrow: 1
	},
	controlsFiller: {
		flex: 1,
		justifyContent: "space-between"
	},
	nameInput:{
		minWidth: "50%"
	},
	rangeRow:{
		border: "none"
	},
	rangeCell:{
		borderBottom: "none"
	},
	fab: (props) => {
        return  {
            // position: "absolute",
            // right: theme.spacing.unit * 2,
            margin: theme.spacing(1),
        }
    } ,
	textInput: {
		width: "50%",
		display: "block"
    },
    block: {
        display: "block"
    }
}));

const RangeList = (config) => {
    const classes = useStyles();
    const ranges = (config.ranges ?? []).slice().sort((a,b) => a.bottom > b.bottom ? 1 : -1 );
    const rangesHtml = ranges.map((range, index) => {
        const currentIndex = index;
        return (<TableRow key={"range_" + currentIndex} className={classes.rangeRow}>
            <TableCell className={classes.rangeCell}>
                <TextField 
                    variant="outlined"
                    onChange={ (event) => { config.changeRangeValue({ prop: "title", value: event.target.value, index: currentIndex }) }} 
                    value={range.title}
                    ></TextField>
            </TableCell>
            <TableCell className={classes.rangeCell}>
                <TextField
                    variant="outlined"
                    onChange={ (event) => { config.changeRangeValue({ prop: "bottom", value: event.target.value, index: currentIndex }) }} 
                    value={range.bottom}
                    ></TextField>
            </TableCell>
            <TableCell className={classes.rangeCell}>
                <Fab size="small" onClick={(event) => { config.deleteRange(currentIndex) }}>
                    <Icon>
                        delete_outline
                    </Icon>
                </Fab>
            </TableCell>
        </TableRow>)
    });
    return  (
                <>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.rangeCell}>Title</TableCell>
                                <TableCell className={classes.rangeCell}>Value</TableCell>
                                <TableCell className={classes.rangeCell}>Remove</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rangesHtml}
                        </TableBody>
                    </Table>
                </>
    )
}
export default RangeList;