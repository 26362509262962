import React from 'react'
import PropTypes from 'prop-types'
import TreeGridTypography from './TreeGridTypography'
import { Icon, Fab, Tooltip, FormLabel, Grid, FormControl, Select, InputLabel } from '@mui/material'



class TreeGridScoreTypeCell extends React.Component {
    constructor(props) {
        super(props);
    }
    shouldComponentUpdate() {
        return true;
    }
    
    render() {
            const area = this.props.additionalInputProps.areas.find(x=>x.id == this.props.id);
            // console.log("Cell rendered. Area: " + area.title + "config: " + area.scoreConfigId)
            const configId = area.scoreConfigId != 0 ? area.scoreConfigId : this.props.additionalInputProps.scoresConfig[0].id;
            return (<Tooltip title="Score type" key={`tree-grid-select-score-advanced-${this.props.id}`} placement="top-start">
            <FormControl variant="outlined" size="small">
                <Select
                native
                value={this.props.value}
                onChange={(event) => {
                    this.props.additionalInputProps.actions.setScoreConfig(this.props.id, event.target.value)
                }}
                >
                    {this.props.additionalInputProps.scoresConfig.map(x=> 
                        <option key={"list-item" + x.id} value={x.id}>{x.name}</option>
                        )}
                </Select>
            </FormControl>
            </Tooltip>);
    } 
}

TreeGridScoreTypeCell.propTypes = {
    id: PropTypes.any,
    actions: PropTypes.object,
    areas: PropTypes.array,
    scoresConfig: PropTypes.array,
    scoreConfigId: PropTypes.number
}

export default TreeGridScoreTypeCell;