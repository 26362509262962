import React from 'react';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { indigo } from '@mui/material/colors'

const PREFIX = 'SimpleWidget';

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
  expand: `${PREFIX}-expand`,
  expandOpen: `${PREFIX}-expandOpen`,
  avatar: `${PREFIX}-avatar`
};

const StyledCard = styled(Card)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    padding: "0px",
    '& .MuiCardContent-root:last-child':{
      paddingBottom: "10px"
    }
  },

  [`& .${classes.header}`]: {
    padding: "3px 0px 5px 0px",
    backgroundColor: indigo[50],
    cursor: "pointer",
    '& .MuiCardHeader-avatar' : {
      paddingLeft: "5px",
      marginRight: "10px"
    },
    '& .MuiCardHeader-action':{
      margin: "0px"
    },
  },

  [`& .${classes.content}`]: {
    padding: "5px 10px 10px 10px"
  },

  [`& .${classes.expand}`]: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },

  [`& .${classes.expandOpen}`]: {
    transform: 'rotate(180deg)',
  },

  [`& .${classes.avatar}`]: {
    backgroundColor: red[500],
  }
}));

const SimpleWidget = ({ icon, title, children, className, actions, isLoading }) => {

    const [expanded, setExpanded] = React.useState(true);
  
    const handleExpandClick = () => {
      setExpanded(!expanded);
    };
    return (
      <StyledCard className={`${className} ${classes.root} `}>
          <CardHeader
              onClick={handleExpandClick}
              className={classes.header}
              avatar={<IconButton
                size="small"
                className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
            >
               {expanded ? <RemoveIcon /> : <AddIcon />} 
            </IconButton>}
              title={<Typography variant="subtitle2">{title}</Typography>}
              action={
                actions
              }
          />
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent className={classes.content}>
                {children}
            </CardContent>
          </Collapse>
      </StyledCard>
    );
}

export default SimpleWidget