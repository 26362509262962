export const compareGlobalActions = {
	ADD_SUMMARY_TO_COMPARE_LIST: "ADD_SUMMARY_TO_COMPARE_LIST",
	REMOVE_SUMMARY_FROM_COMPARE_LIST: "REMOVE_SUMMARY_FROM_COMPARE_LIST",
	REMOVE_PROFILE_FROM_COMPARE_LIST: "REMOVE_PROFILE_FROM_COMPARE_LIST",
}

export const addSummaryToCompareList = (interviewId, summaryTitle, profileId, profileName) => (dispatch) =>{
	dispatch({ type: compareGlobalActions.ADD_SUMMARY_TO_COMPARE_LIST, data: { Id: interviewId, Title: summaryTitle, profileId: profileId, profileName: profileName } })
}
export const removeSummaryFromCompareList = (interviewId) => (dispatch) =>{
	dispatch({ type: compareGlobalActions.REMOVE_SUMMARY_FROM_COMPARE_LIST, Id: interviewId })
} 
export const removeProfileFromCompareList = (profileId) => (dispatch) =>{
	dispatch({ type: compareGlobalActions.REMOVE_PROFILE_FROM_COMPARE_LIST, Id: profileId })
} 