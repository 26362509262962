import React from 'react';
import PropTypes from 'prop-types';
import { Fab, CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Check from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';




class LoadingButton extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loaded:false,
            loading: false
        }
    } 
    
    render() {
        var {classes,loading, loaded,...other} = this.props;
        if (loaded) {
        
            return (
                <Fab  className={classes.button} {...other}>
                    <Check />
                </Fab>
            )
        } else {
            if (loading) {
                return (
                    <Fab className={classes.button} {...other}>
                        <CircularProgress />
                    </Fab>
                )
            }
            else {
                return (
                    <Fab className={classes.button} {...other} >
                        <SaveIcon />
                    </Fab>
                )
            }
        }
    }
}

LoadingButton.defaultProps = {
    
    loaded: false,
    loading: false,
};

LoadingButton.propTypes = {
    classes: PropTypes.object.isRequired,
    loaded: PropTypes.bool,
    loading: PropTypes.bool,
    other: PropTypes.object
};

export default (LoadingButton);