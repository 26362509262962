import {validateObject} from '../../utils/helpers'
import { setRequestError, setAppLoadingStarted, setAppLoadingFinished } from '../App/actions'
import {registerValidationRules} from './validate'
import history from '../../utils/history';

import * as accountApi from '../../api/interview/Account'

export const registerComponentActions = {
    REGISTER_SET_DATA:'REGISTER_SET_DATA',
    REGISTER_SET_ERRORS:'REGISTER_SET_ERRORS'
}

export const setErrors = (errors) => ({type:registerComponentActions.REGISTER_SET_ERRORS,errors});

export const setData = (data) => ({type:registerComponentActions.REGISTER_SET_DATA,data});

export const submitData = () => (dispatch,getState) => {
    let registerData = getState().registerComponent;
    let validationResult = validateObject(registerValidationRules,registerData);
    if(!validationResult.isValid) {
        dispatch(setErrors(validationResult.errors));
        return;
    }

    dispatch(setAppLoadingStarted());
    accountApi.Register(registerData.userName,registerData.email,registerData.password)
        .then((response) => {
            if (!response.error) {
                dispatch(setAppLoadingFinished());
                history.push("/login");
            } else {
                if(response.raw.status == 400) {
                    dispatch(setErrors(response.data.body.error.errors));
                    dispatch(setAppLoadingFinished());
                } else {
                    dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => submitData()));
                }
            }
        })
        .catch((error) => {
            dispatch(setRequestError("Error occured:" + error, () => submitData()));
        })
}