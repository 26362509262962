import {profileListActions} from './actions'

export const initialState = {
    profilesList: [],
    rowCount: 0,
    isFiltering: false,
    filterValue: '',
    initialLoaded: false
}

export const profilesListComponent = (state = initialState, action) => {
    switch (action.type) {
        case profileListActions.PROFILES_LIST_SET_DATA:
            return Object.assign({}, state, action.data);
        
        case profileListActions.DELETE_PROFILE_SUCCEEDED:
            return Object.assign({},state, { profilesList: state.profilesList.filter(({id}) => id != action.id), rowCount: state.rowCount - 1});

        default:
            return state;
    }
};