import React, { PureComponent } from 'react'
import withStyles from '@mui/styles/withStyles';
import { Typography,IconButton, Popover  } from '@mui/material/';
import HelpIcon from '@mui/icons-material/Help';
import PropTypes from 'prop-types';

const styles= (theme) => ({
	notAked: {
		color: theme.palette.grey[400]
	},
	helpButton: {
		marginLeft: theme.spacing(2),
		'&:hover $infoIcon' : {
			color: theme.palette.grey[700]
		},
		'&:active $infoIcon' : {
			color: theme.palette.grey[700]
		}
	},
	infoIcon: {
		color: theme.palette.grey[400],
	},
	
	descriptionPopUp: {
		whiteSpace: 'pre-line',
		padding: theme.spacing(2)
	}
})

class AreaTitle extends PureComponent {
	constructor(props){
		super(props);
		this.state = {
			anchorEl: null
		}
	}

	handleClick = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	}
	handleClose = () => {
		this.setState({ anchorEl: null });
	}

	render(){
		const classes = this.props.classes;
		var description = "";
		if(this.props.description && this.props.description != ""){
			description = <span className={classes.helpButton}>
				{/* <IconButton size="small" aria-label="help" onClick={this.handleClick}>
					<HelpIcon fontSize="small" className={classes.infoIcon} />
				</IconButton>
				<Popover
					anchorEl={this.state.anchorEl}
					onClose={this.handleClose}
					open={this.state.anchorEl != null }
					anchorOrigin={{
						vertical: 'center',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'right',
						horizontal: 'left',
					}}
				>
					<Typography className={classes.descriptionPopUp}>{this.props.description}</Typography>
				</Popover> */}
			</span>;
		}

		return <span><Typography display="inline" variant="body2">{this.props.title}{description}</Typography></span>;
	}
}

export default withStyles(styles, {withTheme:true})(AreaTitle)

AreaTitle.propTypes = {
	classes: PropTypes.object,
	areaId: PropTypes.string,
	// comment: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string
}

