import React, { PureComponent } from 'react'
import { TextField } from '@mui/material'

import "./style.scss";

export default class TextFieldControl extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
            error: false,
            errorMessage: ""
        };
    }

    setError(message) {
        this.setState({
            error: true,
            errorMessage: message
        });
    }

    value() {
        return this.state.value;
    }

    resetError() {
        this.setState({
            error: false,
            errorMessage: ""
        });
    }

    render() {
        return (
            <React.Fragment>
                <TextField
                    onChange={e => {
                        this.setState({ value: e.target.value });
                        this.resetError();
                    }}
                    {...this.props}
                    error={this.state.error} />
                {this.state.errorMessage !== "" &&
                    <div>
                        <label className="error-message" align="left">
                            {this.state.errorMessage}
                        </label>
                    </div>
                }
            </React.Fragment>
        );
    }
}
