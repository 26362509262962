import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
	// style rule
	scoreHighlight: props => ({
	  backgroundColor: props.selectedColor,
	//   color: "white"
	}),
	deselected:{
		backgroundColor: "#ccc"
	}
});

const ScoreValue = (props) => {
    const classes = useStyles(props);

    return <Chip 
                label={props.title}
                variant="outlined"
                className={classes.scoreHighlight }
                >
            </Chip>
}

export default ScoreValue;

ScoreValue.propTypes = {
	title: PropTypes.string,
	selectedColor: PropTypes.string
};