import {candidateEditorActions} from './actions'

export const initialState = {
    id:0,
    name:'',
    lastName:'',
    skype:'',
    email:'',
    phone:'',
    errors:{}
}

export const candidateEditor = (state = initialState, action) => {
    switch (action.type) {
        case candidateEditorActions.CANDIDATE_SET_DATA:
            return Object.assign({},state,action.data);
        case candidateEditorActions.CANDIDATE_SET_ERRORS:
            return Object.assign({},state,{errors:action.errors});
        default:
            return state;
    }
}