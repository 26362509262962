import React, { Component, Fragment } from 'react'
import * as ScoresEditActions from './actions'
import { Grid, Fab, TextField, Table, Button, TableHead, TableCell, TableBody,TableRow, Icon, FormControl } from '@mui/material'
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import WithErrorMessage from '../../common/components/WithErrorMessage'

const styles = theme => ({
	controlsRoot:{
		flexGrow: 1
	},
	controlsFiller: {
		flex: 1,
		justifyContent: "space-between"
	},
	nameInput:{
		minWidth: "50%"
	},
	rangeRow:{
		border: "none"
	},
	rangeCell:{
		borderBottom: "none"
	},
	fab: {
		// position: "absolute",
		// right: theme.spacing.unit * 2,
		margin: theme.spacing(1),
	},
	textInput: {
		width: "50%",
		display: "block"
	}
});

class GroupEditor extends Component {
	constructor(props){
		super(props);
		this.props.actions.getGroup(this.props.groupId);
		
		this.saveGroup = this.saveGroup.bind(this);
		this.deleteRange = this.deleteRange.bind(this);
		this.changeRangeValue = this.changeRangeValue.bind(this);
		this.addRangeClickHandler = this.addRangeClickHandler.bind(this);
	}

	addRangeClickHandler = () => {
		this.props.actions.addScoreRange("beginner");
	}

	initRanges(){
		const classes = this.props.classes;
		const errorFileds =  {
			BOTTOM:1,
			TOP:2
		};
		return this.props.group.ranges.map((range, index)=>{
			const currIndex = index;
			const errorProps = {
				error: true,
				id : "filled-error-helper-text",
				label:"Error",
				helperText: range.errorMessage,
				variant:"filled"
			}
			var bottomErrorProps ={}, topErrorProps = {};
			if(range.hasError){
				if(range.errorField == errorFileds.TOP)
					topErrorProps = {...errorProps};
				if(range.errorField == errorFileds.BOTTOM)
					bottomErrorProps = {...errorProps};
			}
			
			return (
				<TableRow key={"range_" + currIndex} className={classes.rangeRow}>
					<TableCell className={classes.rangeCell}>
						<TextField 
							onChange={this.changeRangeValue("title", currIndex)} 
							value={range.title}
							></TextField>
					</TableCell>
					<TableCell className={classes.rangeCell}>
						<TextField
							{...bottomErrorProps }
							onChange={this.changeRangeValue("bottom", currIndex)} 
							value={range.bottom}
							></TextField>
					</TableCell>
					<TableCell className={classes.rangeCell}>
						<Fab size="small" onClick={this.deleteRange(currIndex)}>
							<Icon>
								delete_outline
							</Icon>
						</Fab>
					</TableCell>
				</TableRow>
			)
		});
	}

	deleteRange(index){
		const me = this;
		return function(e){
			me.props.actions.deleteRange(index);
		}
	}

	changeRangeValue(propName, index){
		const me = this;
		return function(e){
			let data = {};
			data[propName] = e.target.value;
			if(propName == "bottom"){
				data.top = e.target.value;
			}
			me.props.actions.editRange(index, data);
		}
	}

	saveGroup(){
		this.props.actions.saveGroup(this.props.group, this.props.groupId );
	}

	render(){
		if (!this.props.group) return (<h2>Error creating group</h2>);
		const classes = this.props.classes;
		const isNameEmpty = this.props.group.name == "";
		const ranges = this.initRanges();
		return (
            <Fragment>
				<WithErrorMessage error={ isNameEmpty ? "Scores group name missing" : "" }>

					<TextField 
						className={classes.nameInput}
						id={this.props.groupId} 
						name="GroupName"
						label="Scores name"
						value={this.props.group.name}
						autoComplete="off"
						margin="normal"
						onChange={ (event) => this.props.actions.editProp("name", event.target.value) }
						error={ isNameEmpty }
					/>
				</WithErrorMessage>
				<FormControl className={classes.textInput}>
				<TextField 
						className={classes.nameInput}
						id="evaluationQuestion" 
						name="evaluationQuestion"
						label="Evaluation question"
						value={this.props.group.evaluationQuestion ?? ""}
						autoComplete="off"
						margin="normal"
						onChange={ (event) => this.props.actions.editProp("evaluationQuestion", event.target.value) }
						error={ isNameEmpty }
					/>
				</FormControl>
				<FormControl  className={classes.textInput}>
					<TextField 
							className={classes.nameInput}
							id="reportTemplate" 
							name="reportTemplate"
							label="Report text"
							value={this.props.group.reportTemplate ?? ""}
							autoComplete="off"
							margin="normal"
							onChange={ (event) => this.props.actions.editProp("reportTemplate", event.target.value) }
							error={ isNameEmpty }
						/>
				</FormControl>
					
				<Table>
					<TableHead>
						<TableRow>
							<TableCell className={classes.rangeCell}>Title</TableCell>
							<TableCell className={classes.rangeCell}>Value</TableCell>
							<TableCell className={classes.rangeCell}>Remove</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{ranges}
					</TableBody>
				</Table>	
				<Grid container direction="row" justifyContent="flex-end">
					<Fab size="small" color="primary" aria-label="Add" className={classes.fab} onClick={this.addRangeClickHandler}>
						<Icon>add</Icon>
					</Fab>
				</Grid>
				<Grid container spacing={2}>
					<Grid item>
						<Button variant='contained' color='primary' onClick={this.saveGroup}>Save</Button>
					</Grid>
					<Grid item>
						<Button variant="contained" color="secondary" onClick={this.props.history.goBack}>Cancel</Button>
					</Grid>
				</Grid>
			</Fragment>
        );
	}
}

const mapStateToProps = (state, ownProps) => {
    return {
		groupId: ownProps.match.params.id,
		group: state.scoreGroup
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(ScoresEditActions, dispatch)
    };
};

GroupEditor.propTypes = {
	classes: PropTypes.object,
	group: PropTypes.object,
	actions: PropTypes.object,
	history: PropTypes.object
}

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(GroupEditor)))