import { ScoresEditActions } from './actions'

const defaultState = {
	id: 0,
	name: "Software developer scores",
	ranges: []
};

export const scoreGroup = (state = defaultState, action) => {
	switch (action.type){
		case ScoresEditActions.NEW_GROUP:
			return Object.assign({}, state, action.data)
		case ScoresEditActions.LOAD_GROUP:
			return Object.assign({}, state, action.data)
		case ScoresEditActions.EDIT_FIELD:
			return Object.assign({}, state, action.data)
		case ScoresEditActions.ADD_RANGE:
			return  Object.assign({}, state, { ranges: state.ranges.concat(action.data) });
		case ScoresEditActions.DELETE_RANGE:
			return Object.assign({}, state, 
				{ 
					ranges: state.ranges.filter((val, index)=>{
						return index != action.data.index;
					}) 
				});
		case ScoresEditActions.EDIT_RANGE:
				var ranges = [...state.ranges];
				ranges[action.data.index] = Object.assign({}, ranges[action.data.index], action.data.propData);
			return Object.assign({}, state, { ranges: [...ranges] })
		default:
			return state;
	}
}