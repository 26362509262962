import { createSlice } from '@reduxjs/toolkit'
import { setRequestError, setAppLoadingStarted, setAppLoadingFinished } from '../App/actions'
import * as scoresApi from '../../api/interview/ScoreRange'
import { ScoreRangeApi } from "@interviewapp/interview-app-rest-client"
import getApiConfig from '../../utils/api/apiConfigurator';
import history from '../../utils/history'

const scoresGroupsSlice = createSlice({
    name: "scoresGroups",
    initialState: {
        groups: []
    },
    reducers: {
        groupsLoaded(state, action) {
            state.groups = action.payload;
        }
    }
});

export const { groupsLoaded } = scoresGroupsSlice.actions
export default scoresGroupsSlice.reducer

export const getScoresGroups = () => (dispatch) => {
    dispatch(setAppLoadingStarted());
    var client = new ScoreRangeApi(getApiConfig());
    client.get().then(response => {
        dispatch(setAppLoadingFinished());
        dispatch(groupsLoaded((response.data ?? []).filter(x=>x.isDefault)));

    }).catch(error => {
        dispatch(setRequestError("Error occured:" + error, () => getScoresGroups()));
    });
}