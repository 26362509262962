import React from 'react'
import ConnectedTreeRow from './TreeRow'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import TreeGridTypography from './Renderers/TreeGridTypography';

class TreeGrid extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    header(){
		const header = this.props.columnsConfigs.map((config)=>{
            {/*Th */}
			return (
				<Grid item xs={config.width} key={`column-header-${config.prop}`}>
                    <span className='tree-grid-header-column'>
                        <TreeGridTypography variant={'subtitle2'} value={config.text}/>
                    </span>
				</Grid>
			);
		});
		return header;
    }

    render() {
        return (
            <div className={`tree-grid`}>
                {/*Table */}
                <Grid container>
                    {/*Thead tr*/}
                    <Grid item xs={12}>
                        <Grid container>
                            <div className={`tree-grid-header`}>
                                {this.header()}
                            </div>
                            
                        </Grid>
                    </Grid>
                    {/*Tbody */}
                    <div className={'tree-grid-data-container'}>
                        <Grid item xs={12}>
                            <ConnectedTreeRow 
                                root={true} 
                                level={0} 
                                config={this.props}
                                expanded={this.props.expanded}
                                draggingParents={''} />
                        </Grid>
                    </div>
                </Grid>
            </div>
        )
    }
}

TreeGrid.propTypes = {
    gridClassName:PropTypes.string,
    headerRowClassName:PropTypes.string,
    columnsConfigs: PropTypes.array,
    rootId: PropTypes.any,
    expanded: PropTypes.bool
};


export default TreeGrid;