import {candidateInterviewsActions} from './actions'

export const initialState = {
    list:[],
    isLoading: false,
    rowCount:0
}

export const candidatesInterviewsComponent = (state = initialState, action) => {
    switch (action.type) {
        case candidateInterviewsActions.CANDIDATE_INTERVIEWS_SET_DATA:
            return Object.assign({},state, action.data);
        case candidateInterviewsActions.CANDIDATE_INTERVIEWS_SET_INVITATION_KEY:
            return Object.assign({},state,{list: state.list.map(el => {
                if(el.id == action.id) {
                    el.invitationKey = action.key;
                }
                return el;
            })});
        default:
            return state;
    }
};