import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as CandidatesListActions from './actions'
import { bindActionCreators } from 'redux'
import { SnackbarContent, TablePagination, Icon, List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, Divider, Grid, TextField } from '@mui/material'
import { amber } from '@mui/material/colors'
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router'
import CandidatesMenu from './CandidatesMenu'
import Loader from '../Loader'
import { initialState } from './reducer'
import Highlighter from "react-highlight-words";

const styles = theme => ({
    warning: {
        backgroundColor: amber[700],
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    details: {
        paddingLeft: 20
    },
    loader: {
        minHeight: 100
    }
});

class CandidatesList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 5
        }
        this.props.actions.getCandidates(false, this.state.page, this.state.rowsPerPage);
    }
    handleChangePage = (event, page) => {
        this.setState({ page });
        this.props.actions.getCandidates(true, page, this.state.rowsPerPage);
    }
    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
        this.props.actions.getCandidates(true, this.state.page, event.target.value);
    }
    handleFilterChange = (e) => {
        this.props.actions.setCandidatesData({ filterValue: e.target.value });
        this.setState({page:0});
        setTimeout(() => {
            this.props.actions.getCandidates(true, 0, this.state.rowsPerPage);
        }, 200);
    }
    preventBubbling(e) {
        e.stopPropagation();
        return false;
    }
    itemClickHandler = (id) => {
        this.props.history.push('/candidates/view/' + id)
    }
    deleteHandler = (id) => {
        this.props.actions.deleteCandidate(id);
    }
    componentWillUnmount() {
        this.props.actions.setCandidatesData(initialState);
    }
    render() {
        const { classes } = this.props;
        if (this.props.candidatesList.length == 0 && this.props.initialLoaded) {
            return (<SnackbarContent className={classes.warning} message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon>warning</Icon>
                    No candidates found...
                </span>
            } />)
        }

        const rows = this.props.candidatesList.map(item => {
            return (

                <ListItem
                    key={item.id}
                    button
                    onClick={() => { this.itemClickHandler(item.id) }}
                    alignItems="flex-start"
                >
                    <ListItemAvatar>
                        <Avatar>
                            <Icon className={classes.icon}>person</Icon>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Highlighter searchWords={[this.props.filterValue]} autoEscape={true} textToHighlight={`${item.name} ${item.lastName}`} />}
                        secondary={
                            <React.Fragment>
                                <Typography variant="caption" gutterBottom className={classes.details}>
                                    Email: <a href={`mailto:${item.email}`} onClick={this.preventBubbling}>
                                        <Highlighter searchWords={[this.props.filterValue]} autoEscape={true} textToHighlight={item.email} />
                                    </a>
                                </Typography>
                                <Typography variant="caption" gutterBottom className={classes.details}>
                                    Skype: <a href={`skype:${item.skype}?userinfo`} onClick={this.preventBubbling}>
                                        <Highlighter searchWords={[this.props.filterValue]} autoEscape={true} textToHighlight={item.skype} />
                                    </a>
                                </Typography>
                                <Typography variant="caption" gutterBottom className={classes.details}>
                                    Phone: <a href={`callto://+${item.phone}`} onClick={this.preventBubbling}>
                                        <Highlighter searchWords={[this.props.filterValue]} autoEscape={true} textToHighlight={item.phone} />
                                    </a>
                                </Typography>
                                <Divider />
                            </React.Fragment>
                        }
                    />
                    <CandidatesMenu id={item.id} onDelete={this.deleteHandler} />
                </ListItem>
            )
        });
        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField id="filter"
                            name="filter"
                            label="Search"
                            value={this.props.filterValue}
                            onChange={this.handleFilterChange}
                            autoComplete='off'
                            margin="normal" />
                        <Divider/>
                    </Grid>
                    <Grid item xs={12}>
                        <List className={classes.loader}>
                            <Loader show={this.props.isFiltering} className={classes.loader} />
                            {rows}
                        </List>
                        <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={this.props.rowCount}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

CandidatesList.propTypes = {
    actions: PropTypes.object,
    candidatesList: PropTypes.array.isRequired,
    filterValue: PropTypes.string,
    classes: PropTypes.object.isRequired,
    setCandidateDataAction: PropTypes.func,
    initialLoaded: PropTypes.bool,
    rowCount: PropTypes.number,
    isFiltering: PropTypes.bool,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    })
}

const mapStateToProps = (state) => {
    return {
        ...state.candidatesListComponent
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(CandidatesListActions, dispatch)
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CandidatesList)));