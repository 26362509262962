import React, { Fragment } from 'react';
import { Grid, Paper, Table, Button, TableCell, TableBody, TableRow, Typography, TextField, TableHead, Fab, Icon, Tooltip, SvgIcon  } from '@mui/material'
import PropTypes from 'prop-types';
import * as userGroupsActions from './actions'
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UsersInvitesComponent from '../Invite'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

const styles = theme => ({
	screenTitle: {
		paddingRight: 30,
		paddingBottom: theme.spacing(2),
        display: "inline-block"
	},
	invitesTitle: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2)
	},
	mainButton:{
		marginRigth: 10
	},
	actionButton: {
		marginLeft: theme.spacing(1)
	},
	tooltip: {
		fontSize: "1.1em"
	},
	groupRow:{
		cursor: 'pointer'
	}
});

class GroupsList extends React.Component {
    constructor(props) {
        super(props);
		
		this.addGroupName = "New group name";
		this.state = {
			addGroupTriggered: false
		};
		this.props.actions.loadGroups();
	}
	handleCreateBtnClick = (event, value) => {
		this.setState({ addGroupTriggered: true });
	}
	handleConfirmClick = (event, value) => {
		this.props.actions.createGroup(this.addGroupName);
		this.handleCancelClick();
	}
	handleGroupNameChange = (event, value) => {
		this.addGroupName = event.target.value;
	}
	handleCancelClick = (event, value) =>{
		this.setState({ addGroupTriggered: false });
		this.addGroupName = "New group name";
	}

	handleMembersClick = (event, groupId) =>
	{
		this.props.history.push('/groups/' + groupId + '/members')
	}

	
	handleEditRolesClick = (event, groupId, groupName) =>
	{
		this.props.actions.setRolesGroupName(groupName)
		this.props.history.push('/roles/' + groupId )
	}

	handleInvitesUpdated = () =>{
		this.props.actions.loadGroups();
	}
    
    render() {
        const classes = this.props.classes;
        const rows = this.props.groups.map(group => {
			return (<TableRow 
				key={group.id}
				onClick={(event)=>this.handleMembersClick(event, group.id)}
				className={classes.groupRow}
			>
				<TableCell>
					<Typography variant="body1">
						{group.name}
					</Typography>
				</TableCell>
				<TableCell>
					{group.userRoles.map(r=>{
						return (<span key={r.id}>{r.name}</span>)
					})}
				</TableCell>
				<TableCell>
					{/* <Button 
						variant="contained" 
						color="primary" 
						onClick={(event)=>this.handleMembersClick(event, group.id)}>
						members
					</Button>
					<Button 
						className={classes.actionButton} 
						variant="contained" 
						color="primary"
						onClick={(event)=>  this.handleEditRolesClick(event,group.id, group.name)}
						>
						roles
					</Button> */}
					<Tooltip 
						title="Users"
						classes={{ tooltip: classes.tooltip }}
						placement="top" >
						<Fab
							size="small"
							color="primary"
							onClick={(event)=>this.handleMembersClick(event, group.id)}
						>
							<Icon>people</Icon>
						</Fab>
					</Tooltip>
					<Tooltip 
						title="Roles"
						placement="top"
						classes={{ tooltip: classes.tooltip }}
						className={classes.actionButton}
					>
						<Fab
							
							size="small"
							color="primary"
						className={classes.actionButton}
							onClick={(event)=>  this.handleEditRolesClick(event,group.id, group.name)}
						>
						{/* <AssignmentIndIcon/> */}
						<SvgIcon viewBox="0 0 200 200">
						<g transform="translate(0,200) scale(0.1,-0.1)" fill="#000000" stroke="none">
								<path fill="white" d="M805 1915 c-5 -2 -26 -6 -46 -9 -51 -9 -125 -61 -152 -107 -16 -26
								-41 -48 -77 -67 -43 -22 -60 -38 -84 -82 -30 -54 -31 -58 -31 -195 -1 -84 -6
								-158 -14 -184 -7 -25 -10 -61 -6 -85 10 -62 44 -162 65 -189 10 -13 27 -44 36
								-68 10 -24 30 -65 46 -90 15 -25 28 -59 28 -75 0 -28 -3 -30 -92 -57 -261 -79
								-413 -203 -454 -370 -20 -78 -11 -111 32 -127 16 -6 212 -9 522 -8 l497 3 6
								38 c5 33 26 59 136 170 l131 132 -7 75 -6 75 -67 19 -68 20 0 37 c0 21 5 41
								11 44 6 4 30 46 54 94 23 47 52 104 64 127 35 66 54 175 39 226 -7 25 -11 89
								-9 151 4 177 -31 289 -113 365 -40 37 -43 43 -30 59 7 10 14 30 14 45 l0 26
								-182 -1 c-101 -1 -194 1 -208 4 -14 4 -29 6 -35 4z m71 -432 c94 -31 123 -37
								208 -38 97 -1 101 -2 120 -29 16 -20 21 -43 21 -85 0 -49 3 -60 20 -67 17 -8
								20 -18 18 -63 -3 -53 -43 -161 -61 -161 -5 0 -15 -19 -22 -42 -31 -105 -93
								-183 -182 -227 -42 -21 -76 -31 -108 -31 -129 0 -247 96 -296 240 -11 30 -27
								61 -36 68 -22 16 -61 140 -56 179 2 17 11 32 25 37 14 7 23 22 27 50 8 48 40
								122 64 150 29 33 76 56 113 56 20 0 85 -16 145 -37z m-24 -850 c68 -6 159 13
								207 44 22 14 25 13 52 -19 15 -18 28 -36 29 -40 0 -3 -19 -23 -42 -42 -124
								-107 -334 -100 -443 14 l-27 28 27 35 28 35 53 -25 c28 -13 81 -26 116 -30z"/>
								<path fill="white" d="M1640 901 c-157 -52 -243 -202 -200 -351 l10 -35 -146 -146 -145
								-145 3 -70 3 -69 69 -3 c63 -3 71 -1 97 24 22 21 29 37 29 66 0 35 2 38 29 38
								52 0 61 8 61 56 l0 44 42 0 c34 0 49 6 74 31 30 29 35 31 65 20 82 -29 190 -4
								264 61 59 52 86 107 93 184 12 158 -104 291 -263 300 -33 2 -71 0 -85 -5z
								m202 -138 c54 -49 -16 -139 -77 -99 -54 36 -35 116 27 116 17 0 40 -7 50 -17z"/>
							</g>
						</SvgIcon>
						</Fab>
					</Tooltip>

					{/* <Fab
						size="small"
						color="primary"
						className={classes.actionButton}
						
					>
						<Icon>person_add</Icon>
					</Fab> */}
				</TableCell>
			</TableRow>)
        });
		
		const titlePart = this.state.addGroupTriggered ? 
			<Fragment>
				<TextField id="standard-bare"
						defaultValue={this.addGroupName}
						margin="normal"
						onChange={this.handleGroupNameChange}
					/>
				<Button className={classes.mainButton} variant='contained' color='primary' onClick={this.handleConfirmClick}>Create</Button>
				<Button className={classes.mainButton} variant='contained' color='primary' onClick={this.handleCancelClick}>Cancel</Button>
			</Fragment>
			:
			<Button variant='contained' color='primary' onClick={this.handleCreateBtnClick}>Create</Button>
			;
		
        return (
            <Fragment>
				<Grid>
                    <Typography variant="h5" className={classes.screenTitle}>
                        My groups
                    </Typography>
					{titlePart}
				</Grid>
				<Paper>
					<Table>
					<TableHead>
						<TableRow>
						<TableCell>Name</TableCell>
						<TableCell>Roles</TableCell>
						<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
						<TableBody>
							{rows}
						</TableBody>
					</Table>
				</Paper>
				<Grid>
					<Typography variant="h5" className={classes.invitesTitle}>
						Groups Active Invites
					</Typography>
				</Grid>
				<Paper>
					<UsersInvitesComponent onInvitationAccepted={()=> this.handleInvitesUpdated() }/>
				</Paper>
			</Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
	return {
		groups: state.userGroupsList.groups
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(userGroupsActions, dispatch)
	};
};

GroupsList.propTypes = {
    groups: PropTypes.array,
    actions: PropTypes.object,
	classes: PropTypes.object,
	history: PropTypes.object
}

export default (withStyles(styles,{ withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupsList))))
