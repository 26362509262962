import React from 'react'
import PropTypes from 'prop-types'
import TreeGridTypography from './TreeGridTypography'


class TreeGridCell extends React.Component {
    constructor(props) {
        super(props);

        
    }
    shouldComponentUpdate(nextState) {
        return this.props.value != nextState.value;
    }
    
    render() {
        const { value } = this.props;
        return (
            <TreeGridTypography className={this.props.className ? this.props.className : ""} value={value} variant={"subtitle1"} />
        );
    } 
}

TreeGridCell.propTypes = {
    value: PropTypes.any,
    onClick: PropTypes.func
}

export default TreeGridCell;