import './style.scss'
import React from 'react';
import { connect } from 'react-redux';
import * as ProfileEditorActions from './actions';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { TextField, Grid, Button, Tabs, Tab, Typography, Box, Paper, IconButton, MenuItem, Select, ListItemIcon, ListItemText, FormControl } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import {ProfileVersionStates} from '../../utils/globalConstants';
import AreaEditor from './AreaEditor';
import withStyles from '@mui/styles/withStyles';
import ScoreGroupsSelector from './ScoresSelect/ScoreGroupsSelector'
import AreaEditPanel from './AreaEditPanel'
import SimpleWidget from '../../common/components/Cards/SimpleWIdget';
import CloseIcon from '@mui/icons-material/Close';

const styles = theme => ({
    container: {
        flexGrow: "1",
        maxWidth: "70%"
    },
    scoresGroup: {
        top: theme.spacing(1),
        position: "relative"
    },
    profileName: {
    },
    general: {
        display: "flex",
        flexWrap: "wrap",
        gap: "10px"
    },
    generalWidget: {
        flexGrow: 1,
        minWidth: "500px"
    },
    generalData: {
        display: "flex",
        flexDirection: "column"
    },
    sidePanel: {
        width: "30%",
        margin: "0px 0px 0px 10px",
        padding: "10px",
        display: "flex",
        flexDirection: "column"
    },
    editorContainer:{
        display: "flex"
    },
    generalSection: {
        paddingBottom: "10px"
    },
    actionButtons: {
        justifyContent: "space-between",
        display: "flex"
    },
    actionButton: {
    },
    sidePanel: {
        width: "30%",
        margin: "0px 0px 0px 10px",
        padding: "10px",
        display: "flex",
        flexDirection: "column"
    },
    sideWidget: {
        marginBottom: "10px"
    },
    toggleButton: {
        marginBottom: "10px",
        "& button": {
            padding: "5px",
            textTransform: "capitalize",
            display: "flex",
            flexGrow: 1
        },
        "& .Mui-selected": {
            color: theme.palette.grey[800]
        }
    },
    hidden: {
        display: "none"
    }
  });

class ProfileEditor extends React.PureComponent {
    state  = {
        tabIndex : 0,
        activeMenu: "Structure"
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.actions.getProfile(this.props.profileId, this.props.isClone);
    }
    handleChange = (propName) => {
        const me = this;
        return function (e) {
            let profileData = {};
            profileData[propName] = e.target.value;
            me.props.actions.setProfileData(profileData);
        }
    }

    handleMenuChange = (event, value) => {
        if(!!value){
            this.setState({ activeMenu: value })
        }
    }

    componentWillUnmount() {
        this.props.actions.setProfileData({ profileId: null, versionId: null, profileName: '', areas: [], versionState : ProfileVersionStates.Draft })
    }

    render() {

        const { classes, theme  } = this.props;
        const releaseBtnText = this.props.versionState == ProfileVersionStates.Draft ? 'Release' : 'Release New Version';
        const draftBtnText = this.props.versionState == ProfileVersionStates.Draft ? 'Save' : 'Save New Version';
        const area = this.props.selectedArea;
        const selectedAreaPanel = this.props.selectedArea && <SimpleWidget title={area.title} className={`${classes.sideWidget}`} actions={
            <>
                <IconButton
                    size="small"
                    // className={clsx(classes.expand, {
                    //     [classes.expandOpen]: expanded,
                    // })}
                    onClick={()=> this.props.actions.selectArea(null)}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </>
        }>
                <AreaEditPanel 
                    id={area.id}
                    title={area.title} 
                    description={area.description} 
                    onDescriptionChange={(value) => { this.props.actions.editArea(area.id, { description: value }) }} 
                    onTitleChange={(value) => { this.props.actions.editArea(area.id, { title: value }) }} 
                    scoreConfigId={area.scoreConfigId}
                    scoreConfigs={this.props.scoresConfigs}
                    onScoreConfigChange={(scoreId) => this.props.actions.setScoreConfig(area.id, scoreId)}
                    targetLevel={this.props.targetLevel}
                    targetConfigs={area.targetConfigs}
                    onAddTarget={() => this.props.actions.addTarget(area.id)}
                    onTargetChange={this.props.actions.changeTarget}
                    onDeleteTarget={this.props.actions.deleteTarget}
                    />
            </SimpleWidget>;

        const generalSection = <SimpleWidget title={"General"} className={`${classes.generalSection} ${classes.sideWidget}`}>
                    <Box className={classes.actionButtons}>
                        <Button className={classes.actionButton} size="small" variant="contained" color="primary" onClick={() => this.props.actions.postProfile(false)}>
                            {this.props.isClone ? "Save cloned profile" : draftBtnText}
                        </Button>
                        <Button className={classes.actionButton} disabled={this.props.isClone || this.props.versionState != ProfileVersionStates.Draft} size="small" variant="contained" color="primary" onClick={() => this.props.actions.postProfile(true)}>
                            {releaseBtnText}
                        </Button>
                        <Button className={classes.actionButton} size="small" variant="contained" color="secondary" onClick={ () => this.props.history.push("/profiles")}>Cancel</Button>
                    </Box>
                </SimpleWidget>
        return (
                <Box>
                    <ToggleButtonGroup
                            className={classes.toggleButton}
                            value={this.state.activeMenu}
                            exclusive
                            onChange={(event, value) => this.handleMenuChange(event, value)}
                            aria-label="text alignment"
                            >
                            <ToggleButton value="Structure" aria-label="left aligned">
                                <AccountTreeIcon />
                                <Typography>Structure</Typography>
                            </ToggleButton>
                            <ToggleButton value="General" aria-label="centered">
                                <SettingsIcon />
                                <Typography>General</Typography>
                            </ToggleButton>
                    </ToggleButtonGroup>
                    <Box className={`${classes.editorContainer} ${this.state.activeMenu != "Structure" ? classes.hidden : ''}`}>
                        <Box className={classes.container}>
                            <AreaEditor />
                        </Box>
                        <Box className={classes.sidePanel}>
                            {generalSection}
                            {selectedAreaPanel}
                        </Box>
                    </Box>
                    <Box className={`${classes.general} ${this.state.activeMenu != "General" ? classes.hidden : ''}`}>
                        <SimpleWidget title={"General"}  className={`${classes.sideWidget} ${classes.generalWidget}`}>
                            <Box className={classes.generalData}> 
                                <FormControl>
                                    <TextField className={classes.profileName} 
                                        variant="outlined"
                                        id='profileName' 
                                        label="Profile Name" 
                                        value={this.props.profileName} 
                                        onChange={this.handleChange("profileName")} 
                                        autoComplete='off' 
                                        margin="normal"
                                        helperText="Try to name profile in the way that it represent what kind of interview will be performed." />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Description"
                                        multiline
                                        minRows={4}
                                        value={this.props.description}
                                        variant="outlined"
                                        helperText="In case profile will be used by several people or especially available in public, it's important to provide proper description. 
                                        People should be able to undestand if they can use this profile to perform interview"
                                    />
                                </FormControl>
                            </Box>
                        </SimpleWidget>

                        <SimpleWidget title={"Access"} className={`${classes.sideWidget}  ${classes.generalWidget}`}>
                            Access settings
                        </SimpleWidget>
                    </Box>
            </Box>
            
        );
    }
}

ProfileEditor.propTypes = {
    profileName: PropTypes.string,
    profileId: PropTypes.number,
    history: PropTypes.object,
    versionState: PropTypes.number,
    classes:PropTypes.object,
    theme: PropTypes.object.isRequired,
    actions: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
    return {
        profileName: state.profileEditor.profileName,
        versionState: state.profileEditor.versionState,
        profileId: parseInt(ownProps.match.params.id || state.profileEditor.profileId),
        isClone: ownProps.location.pathname.indexOf("clone") > -1,
        selectedArea: state.profileEditor.areas.filter(x=>x.selected)[0],
        scoresConfigs: state.profileEditor.scoresConfigs,
        targetLevel: state.profileEditor.targetLevel,
        clonedFromId: state.profileEditor.clonedFromId
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(ProfileEditorActions, dispatch),
    };
};

export default withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ProfileEditor)));