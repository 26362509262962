import React from 'react'
import { Tooltip, Button, Icon, FormControl, Select, InputLabel, IconButton  } from '@mui/material'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles';
import AppDialog from '../../AppDialog'
import {compose,pure} from 'recompose'

const styles = () => ({
    rowActionsBtn: {
        width: 'initial',
        minWidth: 'initial',
        height: 'initial',
        padding: '5px 5px',
        marginRight: '10px',
        borderRadius: 25
    },
    rowActionsBtnIcon: {
        fontSize: 16
    }
})

class TreeGridActionCell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmationDialog:false
        }

        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleDialogOpen = this.handleDialogOpen.bind(this);
        this.handleChildAddClick = this.handleChildAddClick.bind(this);
        this.handleRemoveClick = this.handleRemoveClick.bind(this);
    }
    shouldComponentUpdate(nextProp,nextState) {
        return this.state.showConfirmationDialog != nextState.showConfirmationDialog;
    }
    handleDialogClose() {
        this.setState({showConfirmationDialog:false})
    }
    handleDialogOpen() {
        this.setState({showConfirmationDialog:true})
    }
    handleChildAddClick() {
        this.props.actions.addChild(this.props.id);
    }
    handleRemoveClick() {
        this.props.actions.remove(this.props.id);
    }
    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <AppDialog
                    open={this.state.showConfirmationDialog}
                    onClose={this.handleDialogClose}
                    title={'Are you sure?'}
                    dialogType='warning'
                    message={'Are you sure you want to delete this area?'}
                    closeText='No'
                    actionText='Yes'
                    handleCloseClick={this.handleDialogClose}
                    handleActionClick={this.handleRemoveClick}
                />


                <div key={`tree-grid-action-wrapper-${this.props.id}`}>
                    <Tooltip title="Add area as a child" key={`tree-grid-add-sub-area-${this.props.id}`} id='add-sub-area' placement="top-start">
                        <IconButton
                            size="small"
                            variant="outlined"
                            color="primary"
                            aria-label="add"
                            className={classes.rowActionsBtn}
                            onClick={this.handleChildAddClick}>
                            <Icon className={classes.rowActionsBtnIcon}>add</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove area and children" key={`tree-grid-remove-area-${this.props.id}`} id='remove-area' placement="top-start">
                        <IconButton 
                            size="small"
                            variant="outlined"
                            color="secondary"
                            aria-label="remove"
                            className={classes.rowActionsBtn}
                            onClick={this.handleDialogOpen}>
                            <Icon className={classes.rowActionsBtnIcon}>delete-outline</Icon>
                        </IconButton >
                    </Tooltip>
                </div>
            </React.Fragment>
        )
    }

}

TreeGridActionCell.propTypes = {
    classes: PropTypes.any,
    id: PropTypes.any,
    actions: PropTypes.object
}

export default compose(pure,withStyles(styles))(TreeGridActionCell);