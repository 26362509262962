import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    SnackbarContent,
    Icon,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types'
import { amber } from '@mui/material/colors'
import classNames from 'classnames';

const styles = theme => ({
    error: {
        backgroundColor: theme.palette.error.dark,
        padding: '0'
    },
    warning: {
        backgroundColor: amber[700],
        padding: '0'
    },
    dialog: {
        padding: '0 0 10px 0'
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        width:'100%',
        maxWidth:'100%'
    }
})

class AppDialog extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        const { classes, title, message, dialogType, open, onClose } = this.props;
        const { closeText, actionText, handleCloseClick, handleActionClick } = this.props;
        return (
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
    
                <DialogTitle id="alert-dialog-title" className={classes.dialog}>
                    <SnackbarContent className={classNames(classes.title,classes[dialogType])}
                    message={
                        <span className={classes.message}>
                            <Icon>{dialogType}</Icon>
                            {title}
                        </span>
                    } />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseClick} color="primary">
                        {closeText ? closeText : "Close"}
                    </Button>
                    {
                        actionText ?
                            <Button onClick={handleActionClick} color="primary" autoFocus>
                                {actionText}
                            </Button>
                            :
                            <React.Fragment></React.Fragment>
                    }
                </DialogActions>
            </Dialog>
        )
    }

    
}

AppDialog.propTypes = {
    classes: PropTypes.any,
    title: PropTypes.string,
    message: PropTypes.string,
    dialogType: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    closeText: PropTypes.string,
    actionText: PropTypes.string,
    handleCloseClick: PropTypes.func,
    handleActionClick: PropTypes.func
}

export default withStyles(styles)(AppDialog)