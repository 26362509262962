import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { Icon, Typography, TextField, Paper, Chip, MenuItem, FormControl } from '@mui/material';
import { emphasize } from '@mui/material/styles';

import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: 'flex',
        padding: 0,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: `${theme.spacing(0.5)} ${theme.spacing(0.25)}`,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing}px ${theme.spacing(2)}`,
    },
    singleValue: {
        fontSize: 16,
        marginTop: -3
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
});

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

NoOptionsMessage.propTypes = {
    children: PropTypes.any,
    selectProps: PropTypes.any,
    innerProps: PropTypes.any,
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
    inputRef: PropTypes.any,
}

function Control(props) {
    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

Control.propTypes = {
    children: PropTypes.any,
    selectProps: PropTypes.any,
    innerProps: PropTypes.any,
    innerRef: PropTypes.any,
}

function Option(props) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

Option.propTypes = {
    children: PropTypes.any,
    selectProps: PropTypes.any,
    innerProps: PropTypes.any,
    innerRef: PropTypes.any,
    isSelected: PropTypes.any,
    isFocused: PropTypes.any
}


function Placeholder(props) {
    let color = "textSecondary";
    if(props.selectProps && props.selectProps.textFieldProps && props.selectProps.textFieldProps.error) {
        color="error";
    }
    return (
        <Typography
            color={color}
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

Placeholder.propTypes = {
    children: PropTypes.any,
    selectProps: PropTypes.any,
    innerProps: PropTypes.any
}

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

SingleValue.propTypes = {
    children: PropTypes.any,
    selectProps: PropTypes.any,
    innerProps: PropTypes.any
}


function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
    children: PropTypes.any,
    selectProps: PropTypes.any,
}

function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={classNames(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<Icon {...props.removeProps}>cancel</Icon>}
        />
    );
}

MultiValue.propTypes = {
    removeProps: PropTypes.any,
    children: PropTypes.any,
    selectProps: PropTypes.any,
    isFocused: PropTypes.any
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

Menu.propTypes = {
    children: PropTypes.any,
    selectProps: PropTypes.any,
    innerProps: PropTypes.any
}

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};

class MaterialUiSelect extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        const { classes, theme } = this.props;

        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
        };

        return (
            <div className={classes.root}>
                 {/* <FormControl variant="outlined" className={classes.root}> */}
                    <Select
                        components={components}
                        classes={classes}
                        styles={selectStyles}
                        textFieldProps={{
                            label: 'Label',
                            InputLabelProps: {
                                shrink: true,
                            },
                        }}
                        {...this.props}

                    />
                {/* </FormControl> */}
            </div>
        );
    }
}

MaterialUiSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(MaterialUiSelect);