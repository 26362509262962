import React, { Component, Fragment, useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';

import { Grid, Fab, TextField, Table, Button, TableHead, TableCell, TableBody,TableRow, Icon, FormControl, Box } from '@mui/material'
import { getGroup, editProps, editRange, addRange, deleteRange, saveGroup } from './scoresEditorSlice'
import WithErrorMessage from '../../common/components/WithErrorMessage'
import RangeList from './RangeList'
const useStyles = makeStyles(theme => ({
	controlsRoot:{
		flexGrow: 1
	},
	controlsFiller: {
		flex: 1,
		justifyContent: "space-between"
	},
	nameInput:{
		minWidth: "50%"
	},
	rangeRow:{
		border: "none"
	},
	rangeCell:{
		borderBottom: "none"
	},
	fab: (props) => {
        return  {
            // position: "absolute",
            // right: theme.spacing.unit * 2,
            margin: theme.spacing(1),
        }
    } ,
	textInput: {
		width: "50%",
		display: "block"
    },
    block: {
        display: "block"
    }
}));

const ScoresEditor = (config) => {
    const dispatch = useDispatch();
    const classes = useStyles()

    const group = useSelector(state => state.scoresEditor)
    const name = useSelector( (state => state.scoresEditor.name ))
    const evaluationQuestion =  useSelector( (state => state.scoresEditor.evaluationQuestion ))
    const reportTemplate =  useSelector( (state => state.scoresEditor.reportTemplate ))
    const ranges = useSelector(state => state.scoresEditor.ranges)
    const isNameEmpty = !name;
    const history = useHistory();
    useEffect(()=>{
        dispatch(getGroup(config.id));
    }, [dispatch])

    window.z = editProps;
    return <>
            <Box className={classes.block}>
                <WithErrorMessage error={ isNameEmpty ? "Scores group name missing" : "" }>
                    <TextField 
                        className={classes.nameInput}
                        id={"groupName"} 
                        name="GroupName"
                        label="Scores name"
                        value={name}
                        autoComplete="off"
                        margin="normal"
                        onChange={ (event) => config.editProps( { name : event.target.value }) }
                        error={ isNameEmpty }
                    />
                </WithErrorMessage>
            </Box>
            <Box className={classes.block}>
                <FormControl className={classes.textInput}>
                    <TextField 
                        className={classes.nameInput}
                        id="evaluationQuestion" 
                        name="evaluationQuestion"
                        label="Evaluation question"
                        value={evaluationQuestion ?? ""}
                        autoComplete="off"
                        margin="normal"
                        onChange={ (event) => config.editProps( { evaluationQuestion: event.target.value }) }
                        error={ isNameEmpty }
                    />
                </FormControl>
            </Box>
            <Box className={classes.block}>
                <FormControl  className={classes.textInput}>
                    <TextField 
                        className={classes.nameInput}
                        id="reportTemplate" 
                        name="reportTemplate"
                        label="Report text"
                        value={ reportTemplate ?? ""}
                        autoComplete="off"
                        margin="normal"
                        onChange={ (event) => config.editProps( { reportTemplate: event.target.value }) }
                        error={ isNameEmpty }
                    />
                </FormControl>
            </Box>
            <RangeList ranges={ranges} changeRangeValue={config.editRange} deleteRange={config.deleteRange} />
            <Grid container direction="row" justifyContent="flex-end">
                <Fab size="small" color="primary" aria-label="Add" className={classes.fab} onClick={()=> { config.addRange("New Range") }}>
                    <Icon>add</Icon>
                </Fab>
            </Grid>
            <Grid container spacing={2}>
                <Grid item>
                    <Button variant='contained' color='primary' onClick={() => config.saveGroup(group, config.id)}>Save</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={()=> history.goBack() }>Cancel</Button>
                </Grid>
            </Grid>
        </>;
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.scoreGroup,
        id: ownProps.match.params.id
    };
};
const mapDispatch = { editProps, editRange, addRange, deleteRange, saveGroup }

export default connect(
    mapStateToProps,
    mapDispatch
  )(ScoresEditor)