import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { Grid as MaterialGrid, Button as MaterialButton, Icon as MaterialIcon, InputAdornment as MaterialInputAdornment,TextField as MaterialTextField } from '@mui/material'
import WithErrorMessage from "../../common/components/WithErrorMessage";
import * as RegisterCmponentActions from './actions'
import { bindActionCreators } from 'redux'
import {pure} from 'recompose'

const TextField = pure(MaterialTextField);
const Button = pure(MaterialButton);
const InputAdornment = pure(MaterialInputAdornment);
const Icon = pure(MaterialIcon);
const Grid = pure(MaterialGrid);

class Register extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    onPasswordKeyDown = (e) => {
        if (e.which == 13) { //enter
			this.onSubmit();
			e.preventDefault();
			e.stopPropagation();
        }
    }
    onSubmit = () => {
        this.props.actions.submitData();
    }
    handleChange = (propName) => {
        const me = this;
        return function (e) {
            let data = {};
            data[propName] = e.target.value;
            me.props.actions.setData(data);
        }
    }

    formFields = () => {
        const fields = [{
            prop:'userName',
            input: {
                label:'Display name',
                name:'UserName',
                InputProps: {
                    startAdornment: (
                        <InputAdornment position="start">
                            <Icon>account_circle</Icon>
                        </InputAdornment>
                    ),
                }
            }
        },{
            prop:'email',
            input: {
                label:'Email',
                name:'Email',
                InputProps: {
                    startAdornment: (
                        <InputAdornment position="start">
                            <Icon>email</Icon>
                        </InputAdornment>
                    ),
                }
            }

        },{
            prop:'password',
            input: {
                label:'Password',
                name:'Password',
                onKeyDown: this.onPasswordKeyDown,
                type:'password',
                InputProps: {
                    startAdornment: (
                        <InputAdornment position="start">
                            <Icon>lock</Icon>
                        </InputAdornment>
                    ),
                }
            }

        }];
        const components = fields.map(f => {
            return (
                <Grid item xs={12} key={`candidate-form-field-${f.prop}`}>
                    <WithErrorMessage error={this.props.errors[f.prop]}>
                        <TextField id={f.prop}
                                    value={this.props[f.prop]} 
                                    onChange={this.handleChange(f.prop)}
                                    autoComplete='off' 
                                    margin="normal"
                                    required
                                    error={this.props.errors[f.prop] && true}
                                    
                                    {...f.input}/>
                    </WithErrorMessage>
                </Grid>
            )
        });
        return components;
    }

    render() {
        return (
            <React.Fragment>
                <Grid container justifyContent="center" direction="column" alignContent="center" alignItems="center">
                    {this.formFields()}

                    <Button variant="contained" color="primary" onClick={this.onSubmit}>Register</Button>
                </Grid>
            </React.Fragment>
        );
    }
}

Register.propTypes = {
    actions: PropTypes.object,
    userName: PropTypes.string,
    password: PropTypes.string,
    email: PropTypes.string,
    errors: PropTypes.object
}

const mapStateToProps = (state) => {
    return {
        ...state.registerComponent
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(RegisterCmponentActions, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
