import { invitesActions } from './actions'

export const initialState = {
		invites: [],
		ivitesUpdated: false
}

export const usersInviteComponent = (state = initialState, action) => {
	switch (action.type){
		case invitesActions.INVITES_UPDATE_HANDLED:
			return  Object.assign({}, state, { invitesUpdated: false });
		case invitesActions.INVITES_LOADED:
			return  Object.assign({}, state, { invites: action.data.map( x=> Object.assign({}, x, { loading: false })) });
		case invitesActions.INVITES_LOADING:
			return Object.assign({}, state, { 
				invites: state.invites.map( (x) =>{ 
				if(x.invitationKey == action.data.key){
					return Object.assign({}, x, { loading: action.data.loading });
				} else {
					return x;
				}
			} )})
		case invitesActions.INVITES_REMOVE_INVITE:
			return Object.assign({}, state, { 
				invitesUpdated: true,
				invites: state.invites.filter(x=>x.invitationKey != action.data.key ) 
			})
		default:
			return state;
	}
}