// Example custom build usage: 
// var engine = require('store/src/store-engine')
// var storages = [
// 	require('store/storages/localStorage')
// ]
// var plugins = [
// 	require('store/plugins/defaults'),
// 	require('store/plugins/expire')
// ];

var store = window.localStorage;
export default class authService {

	constructor(prop) {
		this.prop = prop;
	}

	static getJwt() {
		return store.getItem("jwt");
	}

	static setJwt(jwtToken) {
		store.setItem("jwt", jwtToken);
	}

	static hasJwt() {
		let jwt = store.getItem("jwt");
		return jwt && jwt != undefined && jwt != '';
	}

	static removeJwt() {
		store.removeItem("jwt");
	}
}