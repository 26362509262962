import { setRequestError, setAppLoadingStarted, setAppLoadingFinished } from '../App/actions'
import * as scoresApi from '../../api/interview/ScoreRange'
import history from '../../utils/history'

export const ScoresEditActions = {
	ADD_RANGE: "SCORES_ADD_RANGE",
	LOAD_GROUP: "SCORES_LOADED_GROUP",
	NEW_GROUP: "SCORES_NEW_GROUP",
	EDIT_FIELD: "SCORES_GROUP_EDIT_FIELD",
	DELETE_RANGE: "SCORES_RANGE_DELETE",
	EDIT_RANGE: "SCORES_RANGE_EDIT"
}
export const ErrorFields = {
	BOTTOM : 1,
	TOP : 2
}

const newGroup = (name) => ({ type: ScoresEditActions.NEW_GROUP, data: { name: name, ranges: [{ id: 0, title: "beginner", bottom: 0, top: 25 }] } });
const groupLoaded = (data) => ({ type: ScoresEditActions.LOAD_GROUP, data: data });

export const deleteRange = (index) => (dispatch) =>{
	dispatch({ type: ScoresEditActions.DELETE_RANGE, data: { index: index }})
}
export const editProp = (propName, value) => (dispatch) => {
	var data = {};
	data[propName] = value;
	dispatch({ type: ScoresEditActions.EDIT_FIELD, data})
}
export const saveGroup = (group, id) => (dispatch) => {
	if(dispatch(hasError(group)))
		return
	dispatch(setAppLoadingStarted());
	if (!id || id == 0){
		scoresApi.Create({ model: group 
		}).then((response) => {
			if(!response.error){
				dispatch(setAppLoadingFinished());
				history.push('/scores');
			} else {
				dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => saveGroup(group, id)));
			}
		}).catch(error=>{
			dispatch(setRequestError("Error occured:" + error, () => saveGroup(group, id)));
		});
	} else {
		scoresApi.Update(id, { model: group 
		}).then((response) => {
			if(!response.error){
				dispatch(setAppLoadingFinished());
				history.push('/scores');
			} else {
				dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => saveGroup(group, id)));
			}
		}).catch(error=>{
			dispatch(setRequestError("Error occured:" + error, () => saveGroup(group, id)));
		});
	}
}


export const hasError = (group) => (dispatch) => {
	var hasError = false;
	group.ranges.forEach(range => {
		switch (true){
			// case group.ranges.some(t => range.bottom == top):
			// 	hasError = true;
			// 	dispatch(editRange(group.ranges.indexOf(range), { ...range, hasError: true, errorField: ErrorFields.BOTTOM, errorMessage: "Value intersect other range" }));
			// 	break;
			case (isNaN(range.bottom) || range.bottom > 100):
				hasError = true;
				dispatch(editRange(group.ranges.indexOf(range), { ...range, hasError: true, errorField: ErrorFields.BOTTOM, errorMessage: "Value must be numeric and less than 100" }));
				break;
			case (isNaN(range.top) || range.top > 100):
				hasError = true;
				dispatch(editRange(group.ranges.indexOf(range), { ...range, hasError: true, errorField: ErrorFields.TOP, errorMessage: "Value must be numeric and less than 100" }));
				break;
			default:
				dispatch(editRange(group.ranges.indexOf(range), { ...range, hasError: false, errorMessage: "" }));
		}
	});
	return hasError;
}

export const editRange = (index, data) => (dispatch) =>{
	dispatch({ type: ScoresEditActions.EDIT_RANGE, data: { propData: data, index: index }})
}

export const addScoreRange = (title) => (dispatch) => dispatch({type: ScoresEditActions.ADD_RANGE, data: { id: 0, title: title, bottom: 0, top: 0 } }) 
export const getGroup = (id) => (dispatch) =>{
	if(id){
		dispatch(setAppLoadingStarted());
		scoresApi.GetById(id).then(response=>{
			if(!response.error){
				dispatch(groupLoaded(response.data));
				dispatch(setAppLoadingFinished());
			} else {
				dispatch(setRequestError(response.data.body.error.generalErrorMessage, () => getGroup(id)));
			}
		}).catch(error => {
			dispatch(setRequestError("Error occured:" + error, () => getGroup(id)));
		});
	} else {
		dispatch(newGroup("Software developer skills"));
	}
}

