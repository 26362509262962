/** @module Profile */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * @param {object} options Optional options
 * @param {number} [options.ownerId] 
 * @param {string} [options.searchTerm] 
 * @return {Promise<module:types.ApiResponseViewModel[IEnumerable[InterviewProfileBaseInfo]]>} Success
 */
export function SearchReleasedProfiles(options) {
  if (!options) options = {}
  const parameters = {
    query: {
      ownerId: options.ownerId,
      searchTerm: options.searchTerm
    }
  }
  return gateway.request(SearchReleasedProfilesOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {module:types.InterviewProfileBusinessModel} [options.profile] 
 * @return {Promise<module:types.ApiResponseViewModel[InterviewProfileBusinessModel]>} Success
 */
export function Upsert(options) {
  if (!options) options = {}
  const parameters = {
    body: {
      profile: options.profile
    }
  }
  return gateway.request(UpsertOperation, parameters)
}

/**
 * @param {number} id 
 * @return {Promise<module:types.ApiResponseViewModel[OperationResult[Boolean]]>} Success
 */
export function Delete(id) {
  const parameters = {
    path: {
      id
    }
  }
  return gateway.request(DeleteOperation, parameters)
}

/**
 * @param {number} id 
 * @return {Promise<module:types.ApiResponseViewModel[InterviewProfileBusinessModel]>} Success
 */
export function Get(id) {
  const parameters = {
    path: {
      id
    }
  }
  return gateway.request(GetOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {number} [options.ownerId] 
 * @return {Promise<module:types.ApiResponseViewModel[IEnumerable[InterviewProfileBusinessModel]]>} Success
 */
export function GetProfiles(options) {
  if (!options) options = {}
  const parameters = {
    query: {
      ownerId: options.ownerId
    }
  }
  return gateway.request(GetProfilesOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {number} [options.ownerId] 
 * @param {number} [options.pageNumber] 
 * @param {number} [options.pageSize] 
 * @param {string} [options.searchTerm] 
 * @return {Promise<module:types.ApiResponseViewModel[PagedResult[InterviewProfileBusinessModel]]>} Success
 */
export function GetPaged(options) {
  if (!options) options = {}
  const parameters = {
    query: {
      ownerId: options.ownerId,
      pageNumber: options.pageNumber,
      pageSize: options.pageSize,
      searchTerm: options.searchTerm
    }
  }
  return gateway.request(GetPagedOperation, parameters)
}

const SearchReleasedProfilesOperation = {
  path: '/api/Profile',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const UpsertOperation = {
  path: '/api/Profile',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const DeleteOperation = {
  path: '/api/Profile/Delete/{id}',
  method: 'delete',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const GetOperation = {
  path: '/api/Profile/Get/{id}',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const GetProfilesOperation = {
  path: '/api/Profile/GetProfiles',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const GetPagedOperation = {
  path: '/api/Profile/GetPaged',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}
