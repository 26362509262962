import React, { Component, Fragment, useState, useEffect } from 'react'

import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton, Popover, Box, Select, MenuItem, FormControlLabel, Checkbox, RadioGroup, Radio } from '@mui/material';

const useStyles = makeStyles({
    wrapper: {
        flexGrow: 1
    },
    controlWrapper:{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexGrow: "1"
    },
    levelName: {
        display: "inline-flex",
        fontSize: "14px",
        fontWeight: "bold",
        flexGrow: 1
    },
    label: {
        width: "120px",
        display: "inline-flex",
        fontSize: "14px"
    },
    checkbox: {
        fontSize: "14px",
        marginLeft: "0px",
        "& .MuiFormControlLabel-label": {
            fontSize: "14px",
            width: "110px"
        },
        alignSelf: "end"
    },
    radioWrapper: {
        marginLeft: "0px",
        marginRight: "0px",
        "& .MuiFormControlLabel-label" : {
            fontSize: "12px"
        }
    },
    select: {
        fontSize: "14px"
    }
})

const TargetLevel = ({ levelName, minRangeId, isRequired, scores, onChange }) =>{
    const classes = useStyles();
    const minRangeIdValue = minRangeId >= 0 ? minRangeId : "none";
    return <Box className={classes.wrapper}>
            <Box className={classes.controlWrapper}>
                <Box className={classes.levelName}>
                    <label>{levelName}</label>
                </Box>
                <FormControlLabel
                    labelPlacement="start"
                    className={classes.checkbox}
                    control={
                    <Checkbox
                        
                        checked={isRequired == true}
                        onChange={(event)=> {
                            onChange(minRangeId, event.target.checked)
                        }}
                    />
                    }
                    label="Is required"
                />
            </Box>
            <Box className={classes.controlWrapper}>
                <Box className={classes.label}>
                    <label>Min score required:</label>
                </Box>
                
                <RadioGroup row aria-label="position" name="position" defaultValue="none"
                    onChange={(event)=> {
                        var value = event.target.value == "none" ? null :  event.target.value;
                        onChange(value, isRequired)
                    }}
                    value={minRangeIdValue}
                >
                    <FormControlLabel
                    className={classes.radioWrapper}
                    value="none"
                    control={<Radio color="primary" />}
                    label="N/A"
                    labelPlacement="top"
                    />
                    {scores.map(x=> 
                        <FormControlLabel
                        className={classes.radioWrapper}
                        key={x.id}
                        value={x.id}
                        control={<Radio color="primary" />}
                        label={x.title}
                        labelPlacement="top"
                        />
                    )}
                </RadioGroup>

                {/* <Select
                    className={classes.select}
                    value={minRangeId}
                    onChange={(event)=> {
                        onChange(levelId, event.target.value, isRequired)
                    }}
                    >
                        {scores.map(x=> <MenuItem key={x.id} value={x.id}>{x.title}</MenuItem>)}
                </Select> */}
            </Box>
            {/* <Box className={classes.controlWrapper}>
                <FormControlLabel
                    labelPlacement="start"
                    className={classes.checkbox}
                    control={
                    <Checkbox
                        
                        checked={isRequired == true}
                        onChange={(event)=> {
                            onChange(levelId, minRangeId, event.target.checked)
                        }}
                    />
                    }
                    label="Is required"
                />
            </Box> */}
        </Box>
}

export default TargetLevel;