import './style.scss'
import React from 'react'
import PropTypes from 'prop-types'

const Loader = (props) =>{
    return (
        <div className={`spinner-wrapper ${props.className}`} hidden={!props.show}><div className="spinner"></div></div>
    );
};

Loader.propTypes ={
    show: PropTypes.bool,
    className: PropTypes.any
};

export default Loader;