import { candidateViewActions } from './actions'

export const initialState = {
    id: 0,
    name: '',
    lastName: '',
    skype: '',
    email: '',
    phone: ''
}

export const candidateView = (state = initialState, action) => {
    switch (action.type) {
        case candidateViewActions.CANDIDATE_VIEW_SET_DATA:
            return Object.assign({}, state, action.data);
        default:
            return state;
    }
}