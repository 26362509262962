
import React, { Component, Fragment } from 'react'
import { Box } from '@mui/material'
import { getAreasToLevelMap } from '../../utils/levelPercentsCalculator'


const LiveLevelPercents = ({ levels, scoresConfigs, areas }) =>{
    const calculateData = getAreasToLevelMap(levels, scoresConfigs, areas);
    return <Box>
        { calculateData.map(x=> {
            return <Box>
                    <Box>{x.title}</Box>
                </Box>
        })}
    </Box>
}

export default LiveLevelPercents;